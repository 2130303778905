import { useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { IAlertMessage } from "../../../../../../libs/types/messages";
import AlertMessage from "../../../components/alertMessage";
import Loading from "../../../components/loading";
import UsersAPI from "../../../services/api/users";
import GenerateError from "../../../validations/errors";

const ActivePage = () => {
  const navigate = useNavigate();
  let { confirmation } = useParams();

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<IAlertMessage[]>([]);

  useEffect(() => {
    if (confirmation) {
      const [name, user, code] = confirmation.split(";");
      checkConfirmation(name, user, code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkConfirmation = async (
    name: string,
    user: string,
    code: string
  ) => {
    setLoading(true);

    try {
      const result = await new UsersAPI().active(user, code);

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      navigate(
        `/?header=${encodeURIComponent(
          `Usuário ativo`
        )}&variant=${encodeURIComponent(
          `success`
        )}&message=${encodeURIComponent(
          `${name.toUpperCase()}, sua conta está ativa! Para acessar, basta entrar com seu usuário e senha.`
        )}`
      );
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      } else {
        alert(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1 className="text-center">Confirmação de Conta</h1>

      {loading ? (
        <Loading />
      ) : (
        <div className="center__box">
          {errors && (
            <AlertMessage
              messages={errors}
              setMessages={(e) => setErrors(e)}
              dismissible={false}
            />
          )}

          <Row className="mt-5">
            <Button
              variant="outline-primary"
              onClick={(e) => {
                navigate("/");
              }}
            >
              Voltar
            </Button>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ActivePage;
