import hexRgb from "hex-rgb";
import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillWarning,
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import rgbHex from "rgb-hex";
import { IVariables } from "../../../../../../../libs/types/variables";
import "../../styles.css";

interface IProps {
  item: IVariables;
  update: (e: IVariables) => void;
}

const InputTable: React.FC<IProps> = ({ item, update }) => {
  const [variable, setVariable] = useState<IVariables>();
  const [showAlert, setShowAlert] = useState(false);
  const [showActionIcons, setShowActionIcons] = useState(false);

  const [iconConfirm, setIconConfirm] = useState(<AiOutlineCheckCircle />);
  const [iconCancel, setIconCancel] = useState(<AiOutlineCloseCircle />);

  useEffect(() => {
    if (item) setVariable(item);
  }, [item]);

  const checkValues = (val?: string): boolean => {
    const current_value = val
      ? val.toString().trim().toUpperCase()
      : item.value.toString().trim().toUpperCase();
    const original_value = variable
      ? variable.value.toString().trim().toUpperCase()
      : "";

    return current_value !== original_value;
  };

  const getAlert = () => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            O valor foi <strong>modificado</strong>.
          </Tooltip>
        }
      >
        <div>
          <AiFillWarning
            color="yellow"
            style={{ marginRight: "5px", cursor: "help" }}
          />
        </div>
      </OverlayTrigger>
    );
  };

  const getColor = () => {
    var result = "";

    if (variable) {
      result =
        variable.value === ""
          ? "#000"
          : `#${rgbHex(
              variable.value[0],
              variable.value[1],
              variable.value[2]
            )}`;
    }

    return result;
  };

  const getControls = () => {
    return (
      <>
        {/* CANCEL */}
        <div
          className="value__button"
          onClick={() => {
            if (variable) {
              setVariable({
                ...variable,
                value: item.value,
              });
              setShowAlert(false);
              setShowActionIcons(false);
            }
          }}
          onMouseOver={() => {
            setIconCancel(<AiFillCloseCircle color="red" />);
          }}
          onMouseLeave={() => setIconCancel(<AiOutlineCloseCircle />)}
        >
          {iconCancel}
        </div>

        {/* UPDATE */}
        <div
          className="value__button"
          onClick={() => {
            if (variable) {
              update(variable);
              setShowAlert(false);
              setShowActionIcons(false);
            }
          }}
          onMouseOver={() => {
            setIconConfirm(<AiFillCheckCircle color="green" />);
          }}
          onMouseLeave={() => setIconConfirm(<AiOutlineCheckCircle />)}
        >
          {iconConfirm}
        </div>
      </>
    );
  };

  return (
    <div>
      {!variable ? null : variable.type === "boolean" ? (
        <div className={"values__container"}>
          <Form.Check
            type="switch"
            checked={Boolean(variable.value)}
            onChange={() => {
              update({
                ...variable,
                value: !variable.value,
              });
            }}
          />

          <span
            className={`
      value__bool
        ${Boolean(variable.value) ? "value__on" : "value__off"} `}
          >
            {Boolean(variable.value) ? <strong>Ligado</strong> : "Desligado"}
          </span>
        </div>
      ) : variable.type === "rgb" ? (
        <div className={"values__container"} style={{ cursor: "default" }}>
          <Form.Control
            style={{
              backgroundColor:
                variable.value === ""
                  ? "#000"
                  : `#${rgbHex(
                      variable.value[0],
                      variable.value[1],
                      variable.value[2]
                    )}`,
            }}
            type="color"
            value={getColor()}
            onChange={(e) => {
              var value: number[] = variable.value;
              value[0] = hexRgb(e.target.value).red;
              value[1] = hexRgb(e.target.value).green;
              value[2] = hexRgb(e.target.value).blue;

              update({
                ...variable,
                value,
              });
            }}
          />

          <div>
            <div className={"rgb__container"}>
              <span
                className={"rgb__color"}
                style={{
                  background: `rgb(255,0,0, ${variable.value[0] / 255})`,
                }}
              >
                R: {variable.value[0]}
              </span>

              <span
                className={"rgb__color"}
                style={{
                  background: `rgb(0,255,0, ${variable.value[1] / 255})`,
                }}
              >
                G: {variable.value[1]}
              </span>

              <span
                className={"rgb__color"}
                style={{
                  background: `rgb(0,0,255, ${variable.value[2] / 255})`,
                }}
              >
                B: {variable.value[2]}
              </span>
            </div>

            <div className={"rgb__brightness__container"}>
              <div>Brilho ({variable.value[3]}%):</div>
              <Form.Range
                value={variable.value === "" ? 100 : variable.value[3]}
                step={1}
                min={1}
                max={100}
                onChange={(e) => {
                  const value: number[] = variable.value;
                  var bright = parseInt(e.target.value);
                  if (bright > 100) bright = 100;
                  if (bright < 1) bright = 1;
                  value[3] = bright;

                  update({
                    ...variable,
                    value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      ) : variable.type === "string" ? (
        <div className={"values__container"}>
          {showAlert && getAlert()}

          <Form.Control
            onFocus={() => {}}
            onBlur={() => {
              if (checkValues()) {
                setShowAlert(true);
              } else {
                setShowAlert(false);
              }
            }}
            className="value__input shadow-none"
            type="text"
            value={variable.value.toString()}
            onChange={(e) => {
              if (checkValues(e.target.value)) {
                setShowActionIcons(true);
              } else {
                setShowActionIcons(false);
              }

              setVariable({
                ...variable,
                value: e.target.value,
              });
            }}
          />
          {showActionIcons && getControls()}
        </div>
      ) : (
        <div className={"values__container"}>
          {showAlert && getAlert()}

          <Form.Control
            onFocus={() => {}}
            onBlur={() => {
              if (checkValues()) {
                setShowAlert(true);
              } else {
                setShowAlert(false);
              }
            }}
            className="value__input shadow-none"
            type={"number"}
            value={parseFloat(variable.value.toString())}
            onChange={(e) => {
              if (checkValues(e.target.value)) {
                setShowActionIcons(true);
              } else {
                setShowActionIcons(false);
              }

              setVariable({
                ...variable,
                value: e.target.value,
              });
            }}
          />
          {showActionIcons && getControls()}
        </div>
      )}
    </div>
  );
};

export default InputTable;
