import { IReturnHTTP } from "../../../../../libs/types/returns";

export type TMethods = "POST" | "PUT" | "DELETE";

const url =
  (process.env.REACT_APP_URL === undefined
    ? "http://localhost"
    : process.env.REACT_APP_URL) +
  ":" +
  (process.env.REACT_APP_PRT === undefined ? "80" : process.env.REACT_APP_PRT);

export default class API {
  result: IReturnHTTP = { data: null, message: "", success: false };
  base_url = url;
  token;
  constructor() {
    const user: any = localStorage.getItem("user");
    this.token = user === null ? "" : JSON.parse(user).authToken;
  }

  auth = async (): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/auth`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };
}
