import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { MdDelete, MdDeleteOutline } from "react-icons/md";
import { RiPencilFill, RiPencilLine } from "react-icons/ri";
import { IDataView } from "../../../../../../libs/types/dataView";
import SortFunctions from "../../../functions/sorts";

type TypeSort = "String" | "Number" | "Date";

interface IProps {
  dataView: IDataView[];
  title: string;
  edit?: boolean;
  remove?: boolean;
  responsive?: boolean;
  striped?: boolean;
  bordered?: boolean;
  hover?: boolean;
  onAdd: () => void;
  onEdit: (e: IDataView) => void;
  onRemove: (e: IDataView) => void;
  columnOne?: string;
  columnTwo?: string;
  hiddenColumnOne?: boolean;
  typeSort: TypeSort;
  nameSort?: boolean;
  maskDate?: string;
  showAdd: boolean;
  showSort?: boolean;
}

const TableItem: React.FC<IProps> = ({
  dataView,
  title,
  edit,
  remove,
  responsive,
  striped,
  bordered,
  hover,
  onAdd,
  onEdit,
  onRemove,
  columnOne = "ID",
  columnTwo = "Nome",
  hiddenColumnOne,
  typeSort,
  nameSort = true,
  maskDate = "DD/MM/YYYY",
  showAdd = false,
  showSort = true,
}) => {
  const [sort, setSort] = useState(true);
  const [data, setData] = useState(dataView);

  useEffect(() => {
    var current_data = dataView;

    setData(
      current_data.sort((a, b) =>
        sortData(nameSort ? a.name : a.key, nameSort ? b.name : b.key)
      )
    );
  }, [dataView, data, sort]);

  const sortData = (a: any, b: any) => {
    switch (typeSort) {
      case "String":
        if (sort) {
          return new SortFunctions(a, b).sortStrAsc();
        } else {
          return new SortFunctions(a, b).sortStrDes();
        }

      case "Date":
        if (sort) {
          return new SortFunctions(a, b).sortDateAsc(maskDate);
        } else {
          return new SortFunctions(a, b).sortDateDes(maskDate);
        }

      case "Number":
        if (sort) {
          return new SortFunctions(a, b).sortNumberAsc();
        } else {
          return new SortFunctions(a, b).sortNumberDes();
        }
    }
  };

  return (
    <div>
      <Row>
        <Col className="text-start">
          <h4>{title}</h4>

          {showSort && (
            <Col className="mb-3" lg={6}>
              <Form.Group controlId="formGridSort">
                <InputGroup>
                  <span className="me-2">Ordernar:</span>
                  <Form.Check
                    label={sort ? "Crescente" : "Decrescente"}
                    type="switch"
                    placeholder="formGridSort"
                    checked={sort}
                    onChange={() => setSort(!sort)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          )}
        </Col>

        {showAdd && (
          <Col className="text-end">
            <Button onClick={() => onAdd()}>Adicionar {title}</Button>
          </Col>
        )}
      </Row>
      <Table
        className="mt-3"
        responsive={responsive}
        striped={striped}
        bordered={bordered}
        hover={hover}
        size="sm"
        variant="dark"
      >
        <thead>
          <tr>
            <th className="text-center">#</th>
            {!hiddenColumnOne && <th className="text-center">{columnOne}</th>}
            <th className="text-center">{columnTwo}</th>
            {(edit || remove) && <th className="text-center">Ação</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr key={item.key}>
                <td className="text-center">{index + 1}</td>
                {!hiddenColumnOne && (
                  <td className="text-center">{item.key}</td>
                )}
                <td className="text-center">{item.name}</td>

                {(edit || remove) && (
                  <td className="text-center col-md-2">
                    <div className="icons__container ">
                      {edit && (
                        <div className="icons cursor__pointer">
                          <div className="icons__out">
                            <RiPencilLine size={25} color="white" />
                          </div>
                          <div
                            className="icons__fill"
                            onClick={() => onEdit(item)}
                          >
                            <RiPencilFill size={25} color="white" />
                          </div>
                        </div>
                      )}

                      {remove && (
                        <div className="icons cursor__pointer">
                          <div className="icons__out">
                            <MdDeleteOutline size={25} color="white" />
                          </div>
                          <div
                            className="icons__fill"
                            onClick={() => onRemove(item)}
                          >
                            <MdDelete size={25} color="white" />
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default TableItem;
