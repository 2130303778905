import { AiOutlineControl } from "react-icons/ai";
import { CgLogOut } from "react-icons/cg";
import { RiDashboardLine } from "react-icons/ri";
import { Menu, MenuItem, Sidebar, menuClasses } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useLoginContext } from "../../../context";
import Profile from "../profile";
import ProjectsMenu from "../submenus/projects";
import SettingsMenu from "../submenus/settings";
import ToolsValuesGeneratorMenu from "../submenus/tools";
import UsersMenu from "../submenus/users";

interface IProps {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  sidebarRef: React.Ref<HTMLHtmlElement>;
}

const MenuBar: React.FC<IProps> = ({ collapsed, setCollapsed, sidebarRef }) => {
  const auth = useLoginContext();

  return (
    <Sidebar
      ref={sidebarRef}
      collapsed={collapsed}
      width="250"
      style={{
        position: "fixed",
        height: "100%",
      }}
    >
      <Profile collapsed={collapsed} setCollapsed={setCollapsed} />

      <Menu
        rootStyles={{
          [`.${menuClasses.icon}`]: {
            backgroundColor: "#e1e1e1",
            color: "#344cff",
          },
        }}
      >
        <Link to={`/auth`}>
          <MenuItem icon={<RiDashboardLine />}>Dashboard</MenuItem>
        </Link>

        <Link to={`/auth/controls`}>
          <MenuItem icon={<AiOutlineControl />}>Controles</MenuItem>
        </Link>

        <ProjectsMenu />

        <UsersMenu />

        {auth.loginDataContext.master && (
          <>
            <SettingsMenu />
            <ToolsValuesGeneratorMenu />
          </>
        )}

        <Link to={`/logout`}>
          <MenuItem icon={<CgLogOut />}>Sair</MenuItem>
        </Link>
      </Menu>
    </Sidebar>
  );
};

export default MenuBar;
