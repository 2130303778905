import { Socket, io } from "socket.io-client";

const URL_SERVER =
  process.env.REACT_APP_URL === undefined
    ? "localhost"
    : process.env.REACT_APP_URL;
const PRT_SERVER =
  process.env.REACT_APP_PRT === undefined ? "3000" : process.env.REACT_APP_PRT;

export let socket: Socket;
const URL = `${URL_SERVER}:${PRT_SERVER}`;

export const initiateSocketConnection = (token: string) => {
  socket = io(URL, {
    auth: { token },
    path: "/socket.io",
  });
  console.log(`Connecting socket...`);
};

export const disconnectSocket = () => {
  console.log("Disconnecting socket...");
  if (socket) socket.disconnect();
};

//TODO criar um hook para ficar verificando se está conectado
