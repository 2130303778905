import { Badge } from "react-bootstrap";
import { FaRegEye, FaUserEdit, FaUserPlus, FaUsers } from "react-icons/fa";
import { MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useLoginContext } from "../../../../context";
import DataFunctions from "../../../../functions/database";
import StringFunctions from "../../../../functions/strings";
import useUsersSocket from "../../../../hooks/useUsersSocket";

const UsersMenu = () => {
  const auth = useLoginContext();
  const users = useUsersSocket();

  return (
    <div>
      <SubMenu label={"Usuários"} icon={<FaUsers />}>
        {auth.loginDataContext.master && (
          <Link to={`/auth/user/|new|`}>
            <MenuItem icon={<FaUserPlus />}>Adicionar Usuário</MenuItem>
          </Link>
        )}

        {users.map((item) => {
          return (
            <Link
              key={new DataFunctions().getId(item)}
              to={`/auth/user/${new DataFunctions().getId(item)}`}
            >
              <MenuItem
                icon={
                  auth.loginDataContext.master ? <FaUserEdit /> : <FaRegEye />
                }
                suffix={
                  !item.active ? (
                    <Badge pill bg="danger" style={{ fontSize: "10px" }}>
                      Inativo
                    </Badge>
                  ) : (
                    ""
                  )
                }
              >
                {new StringFunctions(item.name).cammelCase()}
              </MenuItem>
            </Link>
          );
        })}
      </SubMenu>
    </div>
  );
};

export default UsersMenu;
