import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { MdDelete, MdDeleteOutline } from "react-icons/md";
import { RiPencilFill, RiPencilLine } from "react-icons/ri";
import { v4 as uuidv4 } from "uuid";
import { IAlertMessage } from "../../../../../../../../libs/types/messages";
import { IReturnHTTP } from "../../../../../../../../libs/types/returns";
import {
  ISettingsSensorReturns,
  ISettingsSensorTypes,
} from "../../../../../../../../libs/types/settings";
import AlertMessage from "../../../../../components/alertMessage";
import SelectItem from "../../../../../components/dataView/selectItem";
import Loading from "../../../../../components/loading";
import ModalConfirm from "../../../../../components/modalConfirm";
import DataFunctions from "../../../../../functions/database";
import SortFunctions from "../../../../../functions/sorts";
import SettingsSensorsReturnsAPI from "../../../../../services/api/settings/sensors/returns";
import SettingsSensorsTypesAPI from "../../../../../services/api/settings/sensors/types";
import GenerateError from "../../../../../validations/errors";

const DEFAULT_SENSOR = {
  createdAt: new Date(),
  digitalSignal: true,
  name: "",
  returns: [],
  descr: "",
};

const SettingsSensorsTypesPage = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [errors, setErrors] = useState<IAlertMessage[]>([]);
  const [data, setData] = useState<ISettingsSensorTypes[]>();
  const [selectedData, setSelectedData] =
    useState<ISettingsSensorTypes>(DEFAULT_SENSOR);

  const [sensorReturns, setSensorReturns] = useState<ISettingsSensorReturns[]>(
    []
  );

  useEffect(() => {
    setErrors([]);
    loadData();
  }, []);

  const loadData = async () => {
    const result = await new SettingsSensorsTypesAPI().list();

    if (result.success) {
      const returns = await new SettingsSensorsReturnsAPI().list();
      if (returns.success) setSensorReturns(returns.data);
      setData(result.data);
      setSelectedData(DEFAULT_SENSOR);
      setShowModal(false);
      setLoading(false);
      setShowModalDelete(false);
    }
  };

  const save = async () => {
    setLoading(true);

    try {
      if (!selectedData) {
        throw new GenerateError("Preencha os dados.");
      }

      var result: IReturnHTTP = { data: null, message: "", success: false };

      const id = new DataFunctions().getId(selectedData);

      if (id === "undefined") {
        result = await new SettingsSensorsTypesAPI().create([selectedData]);
      } else {
        result = await new SettingsSensorsTypesAPI().update(id, selectedData);
      }

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          variant: "success",
          header: "Concluído",
          message: !selectedData.id
            ? "Sensor criado com sucesso!"
            : "Aletarações realizadas com sucesso!",
        },
      ]);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      loadData();
    }
  };

  const del = async () => {
    setLoading(true);

    try {
      const result = await new SettingsSensorsTypesAPI().delete(
        new DataFunctions().getId(selectedData)
      );

      if (!result.success) {
        throw new GenerateError(result.message);
      }
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      loadData();
    }
  };

  return (
    <div>
      <Modal
        size="lg"
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={() => setShowModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedData.id
              ? `Alterar Tipo de Sensor - ${selectedData.name}`
              : "Adicionar Tipo de Sensor"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Nome + Modelo */}
            <Row>
              <Col className="mb-3" lg={3}>
                <Form.Group controlId="formGridSignal">
                  <Form.Label>Tipo de Sinal:</Form.Label>
                  <Form.Check
                    label={selectedData.digitalSignal ? "Digital" : "Analógico"}
                    type="switch"
                    checked={selectedData.digitalSignal}
                    onChange={() =>
                      setSelectedData({
                        ...selectedData,
                        digitalSignal: !selectedData.digitalSignal,
                      })
                    }
                  />
                </Form.Group>
              </Col>

              <Col className="mb-3" lg={4}>
                <Form.Group controlId="formGridName">
                  <Form.Label>Nome do Sensor:</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedData.name}
                    onChange={(e) => {
                      setSelectedData({
                        ...selectedData,
                        name: e.target.value.toUpperCase(),
                      });
                    }}
                  />
                </Form.Group>
              </Col>

              <Col className="mb-3" lg={5}>
                <Form.Group controlId="formGridDescr">
                  <Form.Label>Descrição:</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedData.descr}
                    onChange={(e) => {
                      setSelectedData({
                        ...selectedData,
                        descr: e.target.value.toUpperCase(),
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Retornos */}
            <div className="mt-2">
              <h3>Retornos</h3>
              <Row>
                <Col className="mb-3">
                  <Form.Group controlId="formGridType">
                    <Form.Label>Retorno de Sensor:</Form.Label>
                    <SelectItem
                      onChange={(e) => {
                        const current_returns: ISettingsSensorReturns[] = [];

                        e.map((item) => {
                          const returns = sensorReturns.find(
                            (rtn) => new DataFunctions().getId(rtn) === item.key
                          );
                          if (returns) current_returns.push(returns);
                        });

                        setSelectedData({
                          ...selectedData,
                          returns: current_returns,
                        });
                      }}
                      options={new DataFunctions().getResume(sensorReturns)}
                      type="possíveis retorno(s)"
                      values={new DataFunctions().getResume(
                        selectedData.returns
                      )}
                      isMulti={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Fechar
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              save();
            }}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalConfirm
        result={(e) => {
          if (!e.result) {
            setShowModalDelete(false);
          } else {
            del();
          }
        }}
        showModal={showModalDelete}
        type="Delete"
        continueQuestion={`do sensor ${selectedData.name}`}
        centered={true}
        size="lg"
      />

      {!data ? (
        errors.length > 0 ? (
          <div className="center__box">
            <AlertMessage
              messages={errors}
              setMessages={(e) => setErrors(e)}
              dismissible={false}
            />
          </div>
        ) : (
          <Loading />
        )
      ) : loading ? (
        <Loading />
      ) : (
        <Container fluid>
          <div className="mx-4 mt-5">
            <Row>
              <Col className="text-start">
                <h4>Tipo de Sensores</h4>
              </Col>

              <Col className="text-end">
                <Button
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Adicionar Tipo de Sensor
                </Button>
              </Col>
            </Row>

            <Table
              className="mt-3"
              responsive
              striped
              bordered
              hover
              size="sm"
              variant="dark"
            >
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center">Nome</th>
                  <th className="text-center">Tipo de Sinal</th>
                  <th className="text-center">Descrição</th>
                  <th className="text-center">Retornos</th>
                  <th className="text-center">Ação</th>
                </tr>
              </thead>
              <tbody>
                {data
                  .sort((a, b) =>
                    new SortFunctions(a.name, b.name).sortStrAsc()
                  )
                  .map((item, index) => {
                    return (
                      <tr key={`tb-data-${index}`}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{item.name}</td>
                        <td className="text-center">
                          {item.digitalSignal ? "Digital" : "Analógico"}
                        </td>
                        <td className="text-center">{item.descr}</td>
                        <td className="text-center">
                          {item.returns.map((r, index) => {
                            if (index === 0) {
                              return `${r.name.trim().toLowerCase()} [${
                                r.shortUnitMeasure
                              }]`;
                            } else {
                              return `, ${r.name.trim().toLowerCase()} [${
                                r.shortUnitMeasure
                              }]`;
                            }
                          })}
                        </td>

                        <td className="text-center col-md-2">
                          <div className="icons__container ">
                            <div className="icons cursor__pointer">
                              <div className="icons__out">
                                <RiPencilLine size={25} color="white" />
                              </div>
                              <div
                                className="icons__fill"
                                onClick={() => {
                                  setSelectedData(item);
                                  setShowModal(true);
                                }}
                              >
                                <RiPencilFill size={25} color="white" />
                              </div>
                            </div>
                            <div className="icons cursor__pointer">
                              <div className="icons__out">
                                <MdDeleteOutline size={25} color="white" />
                              </div>
                              <div
                                className="icons__fill"
                                onClick={() => {
                                  setSelectedData(item);
                                  setShowModalDelete(true);
                                }}
                              >
                                <MdDelete size={25} color="white" />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          {errors && (
            <AlertMessage messages={errors} setMessages={(e) => setErrors(e)} />
          )}
        </Container>
      )}
    </div>
  );
};

export default SettingsSensorsTypesPage;
