import React from "react";
import { IBoard } from "../../../../../../libs/types/boards";
import { IDashboardView } from "../../../../../../libs/types/dashboards";
import { IProject } from "../../../../../../libs/types/projects";
import { ISensor, ISensorValues } from "../../../../../../libs/types/sensors";
import DashItem from "../dashitem";
import "../styles.css";

interface IProps {
  dashview: IDashboardView[];
  viewList: boolean;
  viewMaster: boolean;
  idUser: string;
  onRemoveItem: (e: IDashboardView) => void;
  onUpdateItem: (e: IDashboardView) => void;
  useProjects: IProject[];
  useBoards: IBoard[];
  useSensors: ISensor[];
  useSensorValues: ISensorValues[];
}

const DashView: React.FC<IProps> = ({
  dashview,
  viewList,
  viewMaster,
  idUser,
  onRemoveItem,
  onUpdateItem,
  useProjects,
  useBoards,
  useSensors,
  useSensorValues,
}) => {
  return (
    <div>
      {dashview.map((item, index) => {
        return (
          <DashItem
            key={`dashitem-${item.type}-${index.toString()}`}
            item={item}
            viewList={viewList}
            viewMaster={viewMaster}
            idUser={idUser}
            onRemoveItem={onRemoveItem}
            onUpdateItem={onUpdateItem}
            useProjects={useProjects}
            useBoards={useBoards}
            useSensors={useSensors}
            useSensorValues={useSensorValues}
          />
        );
      })}
    </div>
  );
};

export default DashView;
