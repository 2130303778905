import React from "react";
import Avatar from "./avatar";
import CollapsibleButton from "./collapsibleButton";

interface IProps {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const Profile: React.FC<IProps> = ({ collapsed, setCollapsed }) => {
  return (
    <div>
      <CollapsibleButton setCollapsed={setCollapsed} collapsed={collapsed} />
      <Avatar collapsed={collapsed} />
    </div>
  );
};

export default Profile;
