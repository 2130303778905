import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { IAlertMessage } from "../../../../../../libs/types/messages";
import { IReturnHTTP } from "../../../../../../libs/types/returns";
import { IUser } from "../../../../../../libs/types/users";
import AlertMessage from "../../../components/alertMessage";
import SelectItem from "../../../components/dataView/selectItem";
import Loading from "../../../components/loading";
import ModalConfirm from "../../../components/modalConfirm";
import { useLoginContext } from "../../../context";
import DataFunctions from "../../../functions/database";
import MaskFunctions from "../../../functions/masks";
import StringFunctions from "../../../functions/strings";
import useUsersSocket from "../../../hooks/useUsersSocket";
import UsersAPI from "../../../services/api/users";
import moment from "../../../utils/moment";
import GenerateError from "../../../validations/errors";

const UserPage = () => {
  const navigate = useNavigate();
  let auth = useLoginContext();
  let { idUser } = useParams();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IAlertMessage[]>([]);
  const [data, setData] = useState<IUser>();

  const users = useUsersSocket();

  const [showModalDelete, setShowModalDelete] = useState(false);

  useEffect(() => {
    setErrors([]);

    const loadData = async () => {
      if (idUser === "|new|") {
        setData({
          master: false,
          active: false,
          owner: false,
          name: "",
          email: "",
          user: "",
          password: "",
          documentId: "",
          birthdate: new Date(),
          phone: "",
          createdAt: new Date(),
        });
      } else {
        if (idUser) {
          const result = await new UsersAPI().get(idUser);
          if (result.success) setData(result.data);
        }
      }
    };

    loadData();
  }, [idUser]);

  const save = async () => {
    setLoading(true);

    try {
      if (!data) {
        throw new GenerateError("Preencha os dados.");
      }

      var result: IReturnHTTP = { data: null, message: "", success: false };

      if (idUser === "|new|") {
        result = await new UsersAPI().create(data);
      } else {
        result = await new UsersAPI().update(idUser ? idUser : "", data);
      }

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          variant: "success",
          header: "Concluído",
          message:
            idUser === "|new|"
              ? "Usuário criado com sucesso!"
              : "Aletarações realizadas com sucesso!",
        },
      ]);

      setData({
        master: false,
        active: false,
        owner: false,
        name: "",
        email: "",
        user: "",
        password: "",
        documentId: "",
        birthdate: new Date(),
        phone: "",
        createdAt: new Date(),
      });
      navigate(`/auth/user/|new|`);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      setLoading(false);
    }
  };

  const del = async () => {
    setLoading(true);

    try {
      const result = await new UsersAPI().delete(idUser ? idUser : "");

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      navigate(`/auth`);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {showModalDelete && (
        <ModalConfirm
          result={(e) => {
            if (!e.result) {
              setShowModalDelete(false);
            } else {
              del();
            }
          }}
          showModal={showModalDelete}
          type="Delete"
          continueQuestion="deste usuário"
          secondInformation="Ao excluir este usuário, não haverá volta."
          centered={true}
          size="lg"
        />
      )}

      <h1>
        {idUser === "|new|"
          ? "Novo Usuário"
          : `Usuário${
              !data
                ? ""
                : ` - ${new StringFunctions(data.name).capitalLetter()}`
            }`}
      </h1>

      {idUser !== "|new|" && data && (
        <div className="date">
          <span>
            <b>Criação:</b>{" "}
            {moment(data.createdAt).format("DD/MM/YYYY [às] HH:mm:ss")}
          </span>
          {data.modifiedAt && (
            <>
              <br />
              <span>
                <b>Modificação:</b>{" "}
                {moment(data.modifiedAt).format("DD/MM/YYYY [às] HH:mm:ss")}
              </span>
            </>
          )}
        </div>
      )}

      {!data ? (
        errors.length > 0 ? (
          <div className="center__box">
            <AlertMessage
              messages={errors}
              setMessages={(e) => setErrors(e)}
              dismissible={false}
            />
          </div>
        ) : (
          <Loading />
        )
      ) : loading ? (
        <Loading />
      ) : (
        <Container fluid>
          <div className="mx-4 mt-5">
            <Form>
              {/* Ativo + ID + Master + Owner */}
              <Row>
                <Col className="mb-3" lg={2} sm={6}>
                  <Form.Group controlId="formGridActive">
                    <Form.Label>Ativo:</Form.Label>
                    <Form.Check
                      label={data.active ? "Ativo" : "Inativo"}
                      type="switch"
                      checked={data.active}
                      disabled={!auth.loginDataContext.master}
                      onChange={() =>
                        setData({ ...data, active: !data.active })
                      }
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={2} sm={6}>
                  <Form.Group controlId="formGridMaster">
                    <Form.Label>Master:</Form.Label>
                    <Form.Check
                      label={data.master ? "Sim" : "Não"}
                      type="switch"
                      checked={data.master}
                      disabled={!auth.loginDataContext.master}
                      onChange={() =>
                        setData({ ...data, master: !data.master })
                      }
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={2} sm={6}>
                  <Form.Group controlId="formGridOwner">
                    <Form.Label>Proprietário:</Form.Label>
                    <Form.Check
                      label={data.owner ? "Sim" : "Não"}
                      type="switch"
                      checked={data.owner}
                      disabled={!auth.loginDataContext.master}
                      onChange={() => setData({ ...data, owner: !data.owner })}
                    />
                  </Form.Group>
                </Col>

                {idUser !== "|new|" && (
                  <Col className="mb-3" lg={6}>
                    <Form.Group controlId="formGridId">
                      <Form.Label>ID:</Form.Label>
                      <Form.Control
                        type="text"
                        value={new DataFunctions().getId(data)}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>

              {/* Nome + Email */}
              <Row>
                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridName">
                    <Form.Label>Nome:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.name}
                      onChange={(e) => {
                        setData({
                          ...data,
                          name: e.target.value.toUpperCase(),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>E-mail:</Form.Label>
                    <Form.Control
                      type="email"
                      value={data.email}
                      onChange={(e) => {
                        setData({
                          ...data,
                          email: e.target.value.toUpperCase(),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* User + Senha */}
              <Row>
                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridUser">
                    <Form.Label>Usuário:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.user}
                      onChange={(e) => {
                        setData({
                          ...data,
                          user: e.target.value.toUpperCase(),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                {idUser === "|new|" && (
                  <Col className="mb-3" lg={6}>
                    <Form.Group controlId="formGridPassword">
                      <Form.Label>Senha:</Form.Label>
                      <Form.Control
                        type="password"
                        value={data.password}
                        onChange={(e) => {
                          setData({
                            ...data,
                            password: e.target.value.toUpperCase(),
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>

              {/* documentId + birthdate */}
              <Row>
                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridDocument">
                    <Form.Label>CPF:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="CPF"
                      maxLength={14}
                      value={new MaskFunctions().cpfMask(data.documentId)}
                      onChange={(e) => {
                        setData({
                          ...data,
                          documentId: e.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridBirtdate">
                    <Form.Label>Data de Nascimento:</Form.Label>
                    <Form.Control
                      type="date"
                      value={moment(data.birthdate).format("yy-MM-DD")}
                      onChange={(e) => {
                        setData({
                          ...data,
                          birthdate: moment(e.target.value).toDate(),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* phone + ownerId */}
              <Row>
                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridPhone">
                    <Form.Label>Telefone:</Form.Label>
                    <Form.Control
                      type="tel"
                      value={new MaskFunctions().mobileMask(data.phone)}
                      onChange={(e) => {
                        setData({ ...data, phone: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridBirtdate">
                    <Form.Label>Usuário Proprietário:</Form.Label>
                    <SelectItem
                      options={new DataFunctions().getResume(users)}
                      values={
                        data.userOwnerId
                          ? [
                              {
                                key: new DataFunctions().getId(
                                  data.userOwnerId
                                ),
                                name: data.userOwnerId.name,
                              },
                            ]
                          : [{ key: "", name: "" }]
                      }
                      onChange={(e) => {
                        setData({
                          ...data,
                          userOwnerId: users.find(
                            (usr) => new DataFunctions().getId(usr) === e[0].key
                          ),
                        });
                      }}
                      type="usuários"
                      isMulti={false}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                {idUser !== "|new|" && auth.loginDataContext.master && (
                  <Col className="text-start">
                    <Button
                      variant="danger"
                      onClick={(e) => {
                        setShowModalDelete(true);
                      }}
                    >
                      Excluir
                    </Button>
                  </Col>
                )}
                <Col className="text-end">
                  <Button
                    variant="success"
                    onClick={(e) => {
                      save();
                    }}
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>

          {errors && (
            <AlertMessage messages={errors} setMessages={(e) => setErrors(e)} />
          )}
        </Container>
      )}
    </div>
  );
};

export default UserPage;
