import pt from "apexcharts/dist/locales/pt-br.json";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  Button,
  Col,
  Container,
  Form,
  FormCheck,
  Modal,
  OverlayTrigger,
  Pagination,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { BsFillBarChartFill } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";
import { useParams } from "react-router-dom";
import { IBoard } from "../../../../../../../libs/types/boards";
import { IProject } from "../../../../../../../libs/types/projects";
import {
  ISensor,
  ISensorValues,
} from "../../../../../../../libs/types/sensors";
import NoData from "../../../../components/noData";
import DataFunctions from "../../../../functions/database";
import SortFunctions from "../../../../functions/sorts";
import useSensorValuesSocket from "../../../../hooks/useSensorValuesSocket";
import BoardsAPI from "../../../../services/api/boards";
import ProjectsAPI from "../../../../services/api/projects";
import SensorsAPI from "../../../../services/api/sensors";
import moment from "../../../../utils/moment";

const SensorListPage = () => {
  let { idBoard, idProject } = useParams();

  const useSensorValues = useSensorValuesSocket();

  const [project, setProject] = useState<IProject>();
  const [board, setBoard] = useState<IBoard>();
  const [data, setData] = useState<ISensor[]>([]);

  const [showModal, setShowModal] = useState(false);
  const [selectedSensor, setSelectedSensor] = useState<ISensor>();
  const [selectedReturnType, setSelectedReturnType] = useState<string>();
  const [values, setValues] = useState<ISensorValues[]>([]);
  const [page, setPage] = useState(1);
  const [sortAsc, setSortAsc] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    const loadProject = async () => {
      if (idProject) {
        const result = await new ProjectsAPI().get(idProject);
        if (result.success) setProject(result.data);
      }
    };

    loadProject();
  }, []);

  useEffect(() => {
    const loadBoard = async () => {
      if (idBoard) {
        const result = await new BoardsAPI().get(idBoard);
        if (result.success) setBoard(result.data);
      }
    };

    loadBoard();
  }, []);

  useEffect(() => {
    const loadSensor = async () => {
      if (idBoard) {
        const result = await new SensorsAPI().listByBoard(idBoard);
        if (result.success) setData(result.data);
      }
    };

    loadSensor();
  }, []);

  useEffect(() => {
    if (selectedSensor && selectedReturnType !== "") {
      const newValues = useSensorValues.filter(
        (item) =>
          item.unit === selectedReturnType &&
          new DataFunctions().getId(item.sensor) ===
            new DataFunctions().getId(selectedSensor)
      );
      sortValues(sortAsc, newValues);
    }
  }, [useSensorValues]);

  const pagination = () => {
    let pages = [];
    let totalPages = Math.ceil(values.length / rowsPerPage);

    if (totalPages % 1 !== 0) {
      totalPages = Math.ceil(totalPages);
    }

    if (totalPages <= 10) {
      for (let index = 0; index < totalPages; index++) {
        pages.push(
          <Pagination.Item
            key={`page_${index + 1}`}
            active={page === index + 1}
            onClick={(e) => {
              setPage(
                e.currentTarget.textContent !== null
                  ? parseInt(e.currentTarget.textContent)
                  : 1
              );
            }}
          >
            {index + 1}
          </Pagination.Item>
        );
      }
    } else {
      let index = 0;
      let pagesLimit = 0;

      if (page <= 5) {
        pagesLimit = 10;
      } else if (page >= totalPages - 5) {
        index = totalPages - 10;
        pagesLimit = totalPages;
      } else {
        index = page - 5;
        pagesLimit = page + 5;
      }

      for (index; index < pagesLimit; index++) {
        pages.push(
          <Pagination.Item
            key={`page_${index + 1}`}
            active={page === index + 1}
            onClick={(e) => {
              setPage(
                e.currentTarget.textContent !== null
                  ? parseInt(e.currentTarget.textContent)
                  : 1
              );
            }}
          >
            {index + 1}
          </Pagination.Item>
        );
      }
    }

    return (
      <Pagination>
        {totalPages > 10 && (
          <>
            <Pagination.First
              onClick={() => {
                if (page !== 1) setPage(1);
              }}
            />
            <Pagination.Prev
              onClick={() => {
                if (page > 1) setPage(page - 1);
              }}
            />
          </>
        )}
        {pages}
        {totalPages > 10 && (
          <>
            <Pagination.Next
              onClick={() => {
                if (page < totalPages) setPage(page + 1);
              }}
            />
            <Pagination.Last
              onClick={() => {
                if (page !== totalPages) setPage(totalPages);
              }}
            />
          </>
        )}
      </Pagination>
    );
  };

  const renderItems = () => {
    let items: any = [];
    const startPage = (page - 1) * rowsPerPage;
    let index = startPage;

    values.slice(startPage, rowsPerPage * page).map((item) => {
      items.push(
        <tr
          key={new DataFunctions().getId(item)}
          className="text-center align-middle"
        >
          <td>{index + 1}</td>

          <td>{item.val}</td>

          <td>
            {moment(item.createdAt).format(
              "dddd, DD [de] MMMM [de] YYYY, [às] HH:mm:ss"
            )}
          </td>
        </tr>
      );

      index++;
    });

    return items;
  };

  const sortValues = (asc: boolean, newData?: ISensorValues[]) => {
    const newValues = newData ? newData : values;

    newValues.sort((a, b) => {
      const dateA = moment(a.createdAt);
      const dateB = moment(b.createdAt);

      if (asc) {
        return dateA.isBefore(dateB) ? -1 : 1;
      } else {
        return dateA.isBefore(dateB) ? 1 : -1;
      }
    });

    setValues(newValues);
  };

  const filterValues = (filter: string) => {
    if (selectedSensor) {
      var newValues: ISensorValues[] = [];

      if (filter !== "") {
        newValues = useSensorValues.filter(
          (item) =>
            item.unit === filter &&
            new DataFunctions().getId(item.sensor) ===
              new DataFunctions().getId(selectedSensor)
        );
      } else {
        newValues = useSensorValues.filter(
          (item) =>
            (item.unit === filter || item.unit === undefined) &&
            new DataFunctions().getId(item.sensor) ===
              new DataFunctions().getId(selectedSensor)
        );
      }

      setValues(newValues);
    }
  };

  return (
    <div>
      {selectedSensor && (
        <Modal
          fullscreen
          show={showModal}
          backdrop="static"
          keyboard={false}
          onHide={() => setShowModal(false)}
          centered
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Dados Histórios - {selectedSensor.type.name} -{" "}
              {selectedSensor.name} ({selectedSensor.place.toUpperCase()}):
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row className="my-2">
              <Col>
                <span>Total de Registros:</span>
                <div>
                  <strong>{values.length}</strong>
                </div>
              </Col>

              <Col>
                <span>Ordenar Valores (Tabela):</span>
                <FormCheck
                  type="switch"
                  label={sortAsc ? "Crescente" : "Decrescente"}
                  checked={sortAsc}
                  onChange={() => {
                    const order = !sortAsc;
                    sortValues(order);
                    setPage(1);
                    setSortAsc(order);
                  }}
                />
              </Col>

              <Col>
                <span>Resultados por página:</span>
                <Form.Select
                  size="sm"
                  onChange={(e) => {
                    setPage(1);
                    setRowsPerPage(parseInt(e.target.value));
                  }}
                >
                  <option defaultChecked value={50}>
                    50
                  </option>
                  <option value={100}>100</option>
                  <option value={200}>200</option>
                  <option value={500}>500</option>
                  <option value={1000}>1000</option>
                  <option value={2000}>2000</option>
                  <option value={5000}>5000</option>
                  <option value={10000}>10000</option>
                  <option value={20000}>20000</option>
                </Form.Select>
              </Col>

              <Col>
                <span>Tipo de Retorno:</span>
                <Form.Select
                  defaultValue="default_return_type"
                  size="sm"
                  onChange={(e) => {
                    setPage(1);
                    sortValues(sortAsc);

                    const returnType = selectedSensor.type.returns.find(
                      (item) =>
                        new DataFunctions().getId(item) === e.target.value
                    );

                    if (returnType) {
                      setSelectedReturnType(returnType.shortUnitMeasure);
                      filterValues(returnType.shortUnitMeasure);
                    } else {
                      setSelectedReturnType("");
                    }
                  }}
                >
                  <option value="default_return_type" disabled>
                    Selecione o tipo de retorno...
                  </option>
                  {selectedSensor.type.returns.map((item) => {
                    return (
                      <option
                        key={`return_${item.name}`}
                        value={new DataFunctions().getId(item)}
                      >
                        {`${item.name} - ${item.fullUnitMeasure} [${item.shortUnitMeasure}] `}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>

            {selectedReturnType === undefined ? null : values.length === 0 ? (
              <NoData centeredOnComponent={true} size={50} />
            ) : (
              <div className="mx-5">
                <Chart
                  height={250}
                  options={{
                    chart: {
                      //height: 30,
                      type: "line",
                      locales: [pt],
                      defaultLocale: "pt-br",
                    },
                    stroke: {
                      curve: selectedReturnType === "" ? "stepline" : "smooth",
                      width: 2,
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    tooltip: {
                      x: {
                        format: "dd/MMM HH:mm:ss",
                      },
                    },
                    xaxis: {
                      type: "datetime",
                      labels: {
                        datetimeUTC: false,
                        datetimeFormatter: {
                          year: "yyyy",
                          month: "MMM/yy",
                          day: "dd MMM",
                          hour: "HH:mm",
                        },
                      },
                    },
                    yaxis: {
                      labels: {
                        formatter: (value) => {
                          return `${value} ${selectedReturnType}`;
                        },
                      },
                    },
                  }}
                  series={[
                    {
                      name: selectedSensor.name,
                      data: values
                        .slice((page - 1) * rowsPerPage, rowsPerPage * page)
                        .map((val) => {
                          return {
                            x: moment(val.createdAt).toISOString(),
                            y: val.val,
                          };
                        }),
                    },
                  ]}
                />

                <div style={{ height: "300px", overflowY: "scroll" }}>
                  <Table
                    className="mt-3 "
                    striped
                    bordered
                    hover
                    size="sm"
                    variant="dark"
                  >
                    <thead className="sticky-top">
                      <tr className="text-center align-middle">
                        <th>#</th>
                        <th>Valor</th>
                        <th>Data de Criação</th>
                      </tr>
                    </thead>
                    <tbody>{renderItems()}</tbody>
                  </Table>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: "15px",
                  }}
                >
                  {pagination()}
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {project && board && (
        <>
          <h1>
            Listagem de Sensores - {project.name} / {board.name}
          </h1>

          <Container fluid>
            <div className="mx-4 mt-5">
              <Table
                className="mt-3"
                striped
                bordered
                hover
                size="sm"
                variant="dark"
              >
                <thead>
                  <tr className="text-center align-middle">
                    <th>Nome</th>
                    <th>Valores</th>
                    <th>Pino</th>
                    <th>Tipo</th>
                    <th>Sinal</th>
                    <th>Retornos</th>
                    <th>Local</th>
                    <th>Descrição</th>
                    <th>Última Atualização</th>
                  </tr>
                </thead>
                <tbody>
                  {data
                    .sort((a, b) =>
                      new SortFunctions(a.name, b.name).sortStrAsc()
                    )
                    .map((item) => {
                      return (
                        <tr
                          key={new DataFunctions().getId(item)}
                          className="text-center align-middle"
                        >
                          <td>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  Sensor{" "}
                                  <strong>
                                    {item.active ? "Ativo" : "Inativo"}
                                  </strong>
                                </Tooltip>
                              }
                            >
                              <div>
                                {item.name.toUpperCase()}{" "}
                                <GoDotFill
                                  size={20}
                                  color={item.active ? "green" : "red"}
                                />
                              </div>
                            </OverlayTrigger>
                          </td>

                          <td>
                            <BsFillBarChartFill
                              className="cursor__pointer"
                              onClick={async () => {
                                const newValues = useSensorValues.filter(
                                  (val) =>
                                    new DataFunctions().getId(val.sensor) ===
                                    new DataFunctions().getId(item)
                                );
                                setSelectedSensor(item);
                                setSelectedReturnType(undefined);
                                setPage(1);
                                setSortAsc(true);
                                setRowsPerPage(50);
                                setValues(newValues);
                                setShowModal(true);
                              }}
                            />
                          </td>

                          <td>{item.pin}</td>

                          <td>{item.type.name}</td>

                          <td>
                            {item.type.digitalSignal ? "Digital" : "Analógico"}
                          </td>

                          <td>
                            {item.type.returns
                              .map((e) => {
                                return `${e.name} [${e.shortUnitMeasure}]`;
                              })
                              .join(",")}
                          </td>

                          <td>{item.place}</td>

                          <td>{item.descr}</td>

                          <td>
                            {moment(item.modifiedAt).format(
                              "DD/MM/YYYY [às] HH:mm:ss"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </Container>
        </>
      )}
    </div>
  );
};

export default SensorListPage;
