import { IReturnHTTP } from "../../../../../libs/types/returns";

type TMethods = "POST" | "PUT" | "DELETE";

class ExternalAPI {
  private result: IReturnHTTP;
  constructor() {
    this.result = { data: null, message: "", success: false };
  }

  get = async (
    base_url: string,
    path: string,
    token?: string
  ): Promise<IReturnHTTP> => {
    const url = `${base_url}/${path}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          Authorization: !token ? "" : `Bearer ${token}`,
        },
      })
        .then(
          async (res) => {
            this.result.success = true;
            this.result.data = await res.json();
            return this.result;
          },
          (fail) => {
            this.result.message = fail.response;
            return this.result;
          }
        )
        .catch((error) => {
          this.result.message = error.message;
          return this.result;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  send = async (
    base_url: string,
    path: string,
    data: object,
    method: TMethods,
    token?: string
  ): Promise<IReturnHTTP> => {
    const url = `${base_url}/${path}`;

    try {
      this.result = await fetch(url, {
        method,
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          Authorization: !token ? "" : `Bearer ${token}`,
        },
        body: JSON.stringify({ ...data }),
      })
        .then(
          async (res) => {
            this.result.success = true;
            this.result.data = await res.json();
            return this.result;
          },
          (fail) => {
            this.result.message = fail.response;
            return this.result;
          }
        )
        .catch((error) => {
          this.result.message = error.message;
          return this.result;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };
}

export default ExternalAPI;
