import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { IVariables } from "../../../../../../libs/types/variables";
import DataFunctions from "../../../functions/database";
import StringFunctions from "../../../functions/strings";
import moment from "../../../utils/moment";
import "../styles.css";
import InputTable from "./input";

interface IProps {
  data: IVariables[];
  title: string;
  update: (e: IVariables) => void;
}

const ControlsTable: React.FC<IProps> = ({ data, title, update }) => {
  const [variables, setVariables] = useState<IVariables[]>([]);

  useEffect(() => {
    if (data.length > 0) setVariables(data);
  }, [data]);

  return (
    <div className="my-5">
      {variables.length > 0 && (
        <>
          <h3>{title}</h3>
          <Table className="mt-3" striped hover size="sm" variant="dark">
            <thead>
              <tr className="text-center align-middle">
                <th>#</th>
                <th>Nome</th>
                <th>Valor</th>
                <th>Sensor</th>
                <th>Local</th>
                <th>Descrição</th>
                <th>Última Atualização</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr
                    className="text-center align-middle"
                    key={new DataFunctions().getId(item)}
                  >
                    <td>{index + 1}</td>

                    <td>{item.name}</td>

                    <td>
                      <InputTable item={item} update={update} />
                    </td>

                    <td>
                      {item.sensor &&
                        `${item.sensor.type.name} [${item.sensor.pin}]`}
                    </td>

                    <td>
                      {item.sensor
                        ? new StringFunctions(item.sensor.place).cammelCase()
                        : ""}
                    </td>

                    <td className="cell_justified">
                      {item.descr
                        ? new StringFunctions(item.descr).cammelCase()
                        : item.descr}
                    </td>

                    <td>
                      {moment(item.modifiedAt).format(
                        "DD/MM/YYYY [às] HH:mm:ss"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

export default ControlsTable;
