import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import default_image from "../../../../assets/user.png";
import { useLoginContext } from "../../../../context";
import DataFunctions from "../../../../functions/database";
import StringFunctions from "../../../../functions/strings";
import UsersAPI from "../../../../services/api/users";
import "./styles.css";

interface IProps {
  collapsed: boolean;
}

const Avatar: React.FC<IProps> = ({ collapsed }) => {
  const auth = useLoginContext();

  const inputFile = useRef<HTMLInputElement | null>(null);
  const [avatar, setAvatar] = useState<any>(null);

  useEffect(() => {
    new UsersAPI()
      .get(auth.loginDataContext.id ? auth.loginDataContext.id : "")
      .then((res) => {
        if (res.success && res.data.avatar) {
          new UsersAPI()
            .downloadFile(new DataFunctions().getId(res.data))
            .then((res) => {
              if (res.success) setAvatar(res.data);
            });
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setAvatar(file);
      uploadAvatar(file);
    }
  };

  const uploadAvatar = async (newAvatar: Blob) => {
    try {
      const formData = new FormData();
      formData.append("avatar", newAvatar);

      await new UsersAPI().uploadFile(
        auth.loginDataContext.id ? auth.loginDataContext.id : "",
        formData
      );
    } catch (error: any) {
      alert(error.message);
    }
  };

  return (
    <div>
      <div className="avatar__div" onClick={() => inputFile.current?.click()}>
        <img
          className={
            !collapsed
              ? "avatar__pic__full cursor__pointer"
              : "avatar__pic__half cursor__pointer"
          }
          style={{
            border: !collapsed
              ? "2px black solid"
              : auth.loginDataContext.owner
              ? "2px red solid"
              : "2px black solid",
          }}
          src={avatar === null ? default_image : URL.createObjectURL(avatar)}
          alt="avatar"
        />
        <input
          style={{ display: "none" }}
          type="file"
          multiple={false}
          name="files"
          id="files"
          ref={inputFile}
          onChange={onFileChange}
        />
      </div>
      {!collapsed ? (
        <>
          <Link to={`/auth/user/${auth.loginDataContext.id}`}>
            <div className="profile__name">
              {new StringFunctions(auth.loginDataContext.name).cammelCase()}
            </div>

            {auth.loginDataContext.owner ? (
              <div className="profile__owner">Proprietário</div>
            ) : null}
          </Link>
        </>
      ) : null}
    </div>
  );
};

export default Avatar;
