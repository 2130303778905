import { useEffect, useState } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { GoDotFill } from "react-icons/go";
import { ISensor } from "../../../../../../../libs/types/sensors";
import DataFunctions from "../../../../functions/database";
import StringFunctions from "../../../../functions/strings";
import useSensorValuesSocket from "../../../../hooks/useSensorValuesSocket";
import useSensorsSocket from "../../../../hooks/useSensorsSocket";
import moment from "../../../../utils/moment";
import NoData from "../../../noData";
import "../../styles.css";

interface IProps {
  viewMaster: boolean;
  idUser: string;
}

const DashSensor: React.FC<IProps> = ({ viewMaster, idUser }) => {
  const useSensors = useSensorsSocket();
  const useValues = useSensorValuesSocket();

  const [sensors, setSensors] = useState<ISensor[]>([]);

  useEffect(() => {
    if (viewMaster) {
      setSensors(useSensors);
    } else {
      setSensors(
        useSensors.filter(
          (val) => new DataFunctions().getId(val.board.project.users) === idUser
        )
      );
    }
  }, [useSensors, viewMaster]);

  return (
    <div>
      {sensors.length > 0 ? (
        <Table className="mt-3" striped bordered hover size="sm" variant="dark">
          <thead>
            <tr>
              <th className="text-center ">Nome</th>
              <th className="text-center">Projeto / Placa</th>
              <th className="text-center">Sinal / Pino</th>
              <th className="text-center">Local</th>
              <th className="text-center">Dados Coletados</th>
              <th className="text-center">Usuários Vinculados</th>
              <th className="text-center">Última Atualização</th>
            </tr>
          </thead>
          <tbody>
            {sensors.map((item) => {
              return (
                <tr key={new DataFunctions().getId(item)}>
                  <td className="text-center col-md-1 align-middle">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Sensor{" "}
                          <strong>{item.active ? "Ativo" : "Inativo"}</strong>
                        </Tooltip>
                      }
                    >
                      <div>
                        {item.name.toUpperCase()}{" "}
                        <GoDotFill
                          size={20}
                          color={item.active ? "green" : "red"}
                        />
                      </div>
                    </OverlayTrigger>
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {`${new StringFunctions(item.board.project.name)
                      .firstLetters()
                      .toUpperCase()} / ${item.board.name.toUpperCase()}`}
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {`${item.type.digitalSignal ? "Digital" : "Analógico"} / ${
                      item.pin
                    }`}
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {new StringFunctions(item.place).cammelCase()}
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {
                      useValues.filter(
                        (val) =>
                          new DataFunctions().getId(val.sensor.board) ===
                          new DataFunctions().getId(item)
                      ).length
                    }
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {item.users.length}
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {moment(item.modifiedAt).format("DD/MM/YYYY [às] HH:mm:ss")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <NoData size={50} />
      )}
    </div>
  );
};

export default DashSensor;
