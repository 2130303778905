import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IAlertMessage } from "../../../../../../libs/types/messages";
import { IUser } from "../../../../../../libs/types/users";
import AlertMessage from "../../../components/alertMessage";
import Loading from "../../../components/loading";
import MaskFunctions from "../../../functions/masks";
import UsersAPI from "../../../services/api/users";
import moment from "../../../utils/moment";
import GenerateError from "../../../validations/errors";

const RegisterUserPage = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IAlertMessage[]>([]);
  const [data, setData] = useState<IUser>({
    master: false,
    active: false,
    owner: false,
    user: "",
    password: "",
    name: "",
    documentId: "",
    phone: "",
    email: "",
    birthdate: new Date(),
    createdAt: new Date(),
  });
  const [confirmPwd, setConfirmPwd] = useState("");

  const save = async () => {
    setLoading(true);

    try {
      if (confirmPwd === "") {
        throw new GenerateError("Confirme a senha informada.");
      }

      if (confirmPwd !== data.password) {
        throw new GenerateError("Senhas não são iguais.");
      }

      const result = await new UsersAPI().create(data);

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      navigate(
        `/?header=${encodeURIComponent(
          `Usuário criado`
        )}&variant=${encodeURIComponent(
          `success`
        )}&message=${encodeURIComponent(
          `E-mail de verificação enviado para ${data.email}. Favor entrar no e-mail cadastrado para fazer a ativação da sua conta.`
        )}`
      );
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      } else {
        alert(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Cadastro de Usuário</h1>

      {loading ? (
        <Loading />
      ) : (
        <>
          <Container>
            <div className="mx-4 mt-5">
              <Form>
                {/* Proprietário + Nome + Email */}
                <Row>
                  <Col className="mb-3" lg={4}>
                    <Form.Group controlId="formGridOwner">
                      <Form.Label>Proprietário:</Form.Label>
                      <Form.Check
                        label="Declaro ser proprietário de um projeto"
                        type="checkbox"
                        checked={data.owner}
                        onChange={() =>
                          setData({ ...data, owner: !data.owner })
                        }
                      />
                    </Form.Group>
                  </Col>

                  <Col className="mb-3" lg={4}>
                    <Form.Group controlId="formGridName">
                      <Form.Label>Nome:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Nome"
                        value={data.name}
                        onChange={(e) => {
                          setData({
                            ...data,
                            name: e.target.value.toUpperCase(),
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="mb-3" lg={4}>
                    <Form.Group controlId="formGridEmail">
                      <Form.Label>E-mail:</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="E-mail"
                        value={data.email.toLowerCase()}
                        onChange={(e) => {
                          setData({
                            ...data,
                            email: e.target.value.toLowerCase(),
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* Usuário + Senha + Confirma Senha*/}
                <Row>
                  <Col className="mb-3" lg={4}>
                    <Form.Group controlId="formGridUser">
                      <Form.Label>Usuário:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Usuário"
                        value={data.user.trim()}
                        onChange={(e) => {
                          setData({
                            ...data,
                            user: e.currentTarget.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="mb-3" lg={4}>
                    <Form.Group controlId="formGridPassword">
                      <Form.Label>Senha:</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Senha"
                        value={data.password}
                        onChange={(e) => {
                          setData({
                            ...data,
                            password: e.currentTarget.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="mb-3" lg={4}>
                    <Form.Group controlId="formGridPasswordConfirm">
                      <Form.Label>Confirmar Senha:</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirmação de senha"
                        value={confirmPwd}
                        onChange={(e) => {
                          setConfirmPwd(e.currentTarget.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* Nascimento + CPF + Telefone */}
                <Row>
                  <Col className="mb-3" lg={4}>
                    <Form.Group controlId="formGridBirthdate">
                      <Form.Label>Data de Nascimento:</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Data de Nascimento"
                        value={moment(data.birthdate).format("yyyy-MM-DD")}
                        onChange={(e) => {
                          setData({
                            ...data,
                            birthdate: moment(e.target.value).toDate(),
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="mb-3" lg={4}>
                    <Form.Group controlId="formGridDocument">
                      <Form.Label>CPF:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="CPF"
                        maxLength={14}
                        value={new MaskFunctions().cpfMask(data.documentId)}
                        onChange={(e) => {
                          setData({
                            ...data,
                            documentId: e.currentTarget.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="mb-3" lg={4}>
                    <Form.Group controlId="formGridPhone">
                      <Form.Label>Telefone:</Form.Label>
                      <Form.Control
                        type="tel"
                        placeholder="Telefone"
                        value={new MaskFunctions().mobileMask(data.phone)}
                        onChange={(e) => {
                          setData({ ...data, phone: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* Buttons */}
                <Row className="mb-3">
                  <Col className="text-start">
                    <Button
                      variant="outline-primary"
                      onClick={(e) => {
                        // e.preventDefault();
                        navigate("/");
                      }}
                    >
                      Voltar
                    </Button>
                  </Col>

                  <Col className="text-end">
                    <Button
                      variant="outline-success"
                      onClick={(e) => {
                        save();
                      }}
                    >
                      Registrar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Container>

          {errors && (
            <AlertMessage messages={errors} setMessages={(e) => setErrors(e)} />
          )}
        </>
      )}
    </div>
  );
};

export default RegisterUserPage;
