import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import SideBar from "../components/sidebar";
import { DEFAULT_VALUE_LOGIN, useLoginContext } from "../context";
import BoardPage from "../pages/private/board";
import ControlsPage from "../pages/private/controls";
import DashboardPage from "../pages/private/dashboard";
import ProjectPage from "../pages/private/project";
import SensorPage from "../pages/private/sensor";
import SensorListPage from "../pages/private/sensor/list";
import SettingsSensorsReturnsPage from "../pages/private/settings/sensor/returns";
import SettingsSensorsTypesPage from "../pages/private/settings/sensor/types";
import CreateDashboardPage from "../pages/private/tools/dashboard";
import ValuesGeneratorPage from "../pages/private/tools/valuesGenerator";
import UserPage from "../pages/private/user";
import VarialePage from "../pages/private/variable";
import VariableListPage from "../pages/private/variable/list";
import ActivePage from "../pages/public/active";
import LoginPage from "../pages/public/login";
import RecoverPage from "../pages/public/recover";
import RegisterUserPage from "../pages/public/registerUser";
import API from "../services/api";
import { disconnectSocket, initiateSocketConnection } from "../services/socket";
import Storage from "../storage";

const Router = () => {
  return (
    <Routes>
      <Route index path="/*" element={<LoginPage />} />
      <Route path="/register-user" element={<RegisterUserPage />} />
      <Route path="/confirm/:confirmation" element={<ActivePage />} />
      <Route path="/recover/:confirmation" element={<RecoverPage />} />
      <Route path="/logout" element={<LogoutAuth />} />

      <Route
        path="/auth/"
        element={
          <RequireAuth>
            <SideBar />
          </RequireAuth>
        }
      >
        <Route path="/auth/" element={<DashboardPage />} />

        <Route path="/auth/controls" element={<ControlsPage />} />

        <Route path="/auth/project/:idProject" element={<ProjectPage />} />

        <Route
          path="/auth/project/:idProject/board/:idBoard"
          element={<BoardPage />}
        />

        <Route
          path="/auth/project/:idProject/board/:idBoard/sensor/list"
          element={<SensorListPage />}
        />

        <Route
          path="/auth/project/:idProject/board/:idBoard/sensor/:idSensor"
          element={<SensorPage />}
        />

        <Route
          path="/auth/project/:idProject/board/:idBoard/variable/list"
          element={<VariableListPage />}
        />

        <Route
          path="/auth/project/:idProject/board/:idBoard/variable/:idVariable"
          element={<VarialePage />}
        />

        <Route
          path="/auth/settings/sensors/types"
          element={<SettingsSensorsTypesPage />}
        />

        <Route
          path="/auth/settings/sensors/returns"
          element={<SettingsSensorsReturnsPage />}
        />

        <Route
          path="/auth/tools/values-generator/"
          element={<ValuesGeneratorPage />}
        />

        <Route
          path="/auth/tools/create-dashboard/"
          element={<CreateDashboardPage />}
        />

        <Route path="/auth/user/:idUser" element={<UserPage />} />
      </Route>

      {/*
      
        <Route path="/auth/:message" element={<HomePage />} />
        <Route path="/auth/controls/" element={<ControlPage />} />

        <Route path="/auth/settings/sensor/" element={<SettingsSensorPage />} />

        
      </Route> */}
    </Routes>
  );
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  let auth = useLoginContext();
  let location = useLocation();

  useEffect(() => {
    initiateSocketConnection(auth.loginDataContext.authToken);

    return () => {
      disconnectSocket();
    };
  }, []);

  const validateLogin = async () => {
    const result = await new API().auth();

    if (!result.success) {
      if (
        result.data !== undefined &&
        result.data !== null &&
        result.data.name !== undefined &&
        result.data.name !== null &&
        // eslint-disable-next-line eqeqeq
        result.data.name == "TokenExpiredError"
      ) {
        return (
          <Navigate
            to="/Token expirado;Por favor enter novamente com seu login e senha."
            state={{ from: location }}
            replace
          />
        );
      }
    }
  };

  if (!auth.loginDataContext.logged) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    validateLogin();
  }

  return <div> {children}</div>;
};

const LogoutAuth = () => {
  let auth = useLoginContext();
  let location = useLocation();

  new Storage().clear();
  auth.setLoginDataContext(DEFAULT_VALUE_LOGIN.loginDataContext);

  return <Navigate to="/" state={{ from: location }} replace />;
};

export default Router;
