import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { IAlertMessage } from "../../../../../../libs/types/messages";
import { IProject } from "../../../../../../libs/types/projects";
import { IReturnHTTP } from "../../../../../../libs/types/returns";
import { IUser } from "../../../../../../libs/types/users";
import AlertMessage from "../../../components/alertMessage";
import SelectItem from "../../../components/dataView/selectItem";
import TableItem from "../../../components/dataView/table";
import Loading from "../../../components/loading";
import ModalConfirm from "../../../components/modalConfirm";
import { useLoginContext } from "../../../context";
import DataFunctions from "../../../functions/database";
import StringFunctions from "../../../functions/strings";
import useUsersSocket from "../../../hooks/useUsersSocket";
import ProjectsAPI from "../../../services/api/projects";
import ExternalAPI from "../../../services/external";
import moment from "../../../utils/moment";
import GenerateError from "../../../validations/errors";

const URL_CEP = "https://viacep.com.br/ws/";

const ProjectPage = () => {
  const navigate = useNavigate();
  let auth = useLoginContext();
  let { idProject } = useParams();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IAlertMessage[]>([]);
  const [data, setData] = useState<IProject>();
  const [place, setPlace] = useState<{
    key: string;
    new: boolean;
    name: string;
  }>({
    key: "",
    name: "",
    new: true,
  });

  const users = useUsersSocket();

  const [showModal, setShowModal] = useState(false);
  const [showModalPlaces, setShowModalPlaces] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  useEffect(() => {
    setErrors([]);

    const loadData = async () => {
      if (idProject === "|new|") {
        setData({
          name: "",
          descr: "",
          users: [],
          places: [],
          address: {
            city: "",
            country: "",
            district: "",
            number: "",
            state: "",
            street: "",
            zipcode: "",
            unit: "",
          },
          active: true,
          createdAt: new Date(),
        });
      } else {
        if (idProject) {
          const result = await new ProjectsAPI().get(idProject);
          if (result.success) setData(result.data);
        }
      }
    };

    loadData();
  }, [idProject]);

  const save = async () => {
    setLoading(true);

    try {
      if (!data) {
        throw new GenerateError("Preencha os dados.");
      }

      var result: IReturnHTTP = { data: null, message: "", success: false };

      if (idProject === "|new|") {
        result = await new ProjectsAPI().create([data]);
      } else {
        result = await new ProjectsAPI().update(
          idProject ? idProject : "",
          data
        );
      }

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          variant: "success",
          header: "Concluído",
          message:
            idProject === "|new|"
              ? "Projeto criado com sucesso!"
              : "Aletarações realizadas com sucesso!",
        },
      ]);

      setData({
        name: "",
        descr: "",
        users: [],
        places: [],
        address: {
          city: "",
          country: "",
          district: "",
          number: "",
          state: "",
          street: "",
          zipcode: "",
          unit: "",
        },
        active: true,
        createdAt: new Date(),
      });
      navigate(`/auth/project/|new|`);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      setLoading(false);
    }
  };

  const del = async () => {
    setLoading(true);

    try {
      const result = await new ProjectsAPI().delete(idProject ? idProject : "");

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      navigate(`/auth`);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      setLoading(false);
    }
  };

  const updatePlaces = () => {
    if (data) {
      if (place.new) {
        if (!data.places?.find((s) => s === place.name)) {
          setData({
            ...data,
            places: data.places ? [...data.places, place.name] : [place.name],
          });
        } else {
          setErrors([
            {
              key: uuidv4().toString(),
              header: "Shield já existente",
              message:
                "O shield que você está tentando adicionar já existe para esta placa.",
              variant: "danger",
            },
          ]);
        }
      } else {
        if (data.places) {
          var current_shield = data.places;
          current_shield[Number(place.key)] = place.name;
          setData({
            ...data,
            places: current_shield,
          });
        }
      }
    }
    setShowModalPlaces(false);
  };
  return (
    <div>
      {data && (
        <>
          <Modal
            size="lg"
            show={showModal}
            backdrop="static"
            keyboard={false}
            onHide={() => setShowModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Selecione o(s) usuário(s) para adicionar à este projeto:
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SelectItem
                options={new DataFunctions().getResume(users)}
                values={data.users.map((item) => {
                  return {
                    key: new DataFunctions().getId(item),
                    name: item.user,
                  };
                })}
                onChange={(e) => {
                  const current_users: IUser[] = [];

                  e.map((item) => {
                    const user = users.find(
                      (usr) => new DataFunctions().getId(usr) === item.key
                    );
                    if (user) current_users.push(user);
                  });

                  setData({
                    ...data,
                    users: current_users,
                  });
                }}
                type="usuários"
                isMulti={true}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            size="lg"
            show={showModalPlaces}
            backdrop="static"
            keyboard={false}
            onHide={() => setShowModalPlaces(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {place.new
                  ? "Adicione um local de identificação para este projeto (ex.: banheiro, sala, cozinha, etc.)"
                  : "Altere o local de identificação"}
                :
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col className="mb-3">
                  <Form.Group controlId="formGridPlace">
                    <Form.Label>Nome do Local:</Form.Label>
                    <Form.Control
                      type="text"
                      value={place.name}
                      onChange={(e) => {
                        setPlace({
                          ...place,
                          name: e.target.value.toUpperCase(),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setShowModalPlaces(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  updatePlaces();
                }}
              >
                {place.new ? "Adicionar" : "Alterar"}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}

      {showModalDelete && (
        <ModalConfirm
          result={(e) => {
            if (!e.result) {
              setShowModalDelete(false);
            } else {
              del();
            }
          }}
          showModal={showModalDelete}
          type="Delete"
          continueQuestion="deste projeto"
          secondInformation="Ao excluir este projeto, demais dados como placas, sensores e valores também serão excluídos."
          centered={true}
          size="lg"
        />
      )}

      <h1>
        {idProject === "|new|"
          ? "Novo Projeto"
          : `Projeto${
              !data
                ? ""
                : ` - ${new StringFunctions(data.name).capitalLetter()}`
            }`}
      </h1>

      {idProject !== "|new|" && data && (
        <div className="date">
          <span>
            <b>Criação:</b>{" "}
            {moment(data.createdAt).format("DD/MM/YYYY [às] HH:mm:ss")}
          </span>
          {data.modifiedAt && (
            <>
              <br />
              <span>
                <b>Modificação:</b>{" "}
                {moment(data.modifiedAt).format("DD/MM/YYYY [às] HH:mm:ss")}
              </span>
            </>
          )}
        </div>
      )}

      {!data ? (
        errors.length > 0 ? (
          <div className="center__box">
            <AlertMessage
              messages={errors}
              setMessages={(e) => setErrors(e)}
              dismissible={false}
            />
          </div>
        ) : (
          <Loading />
        )
      ) : loading ? (
        <Loading />
      ) : (
        <Container fluid>
          <div className="mx-4 mt-5">
            <Form>
              {/* Ativo + ID + Key */}
              <Row>
                <Col className="mb-3" lg={2} sm={6}>
                  <Form.Group controlId="formGridActive">
                    <Form.Label>Ativo:</Form.Label>
                    <Form.Check
                      label={data.active ? "Ativo" : "Inativo"}
                      type="switch"
                      checked={data.active}
                      disabled={!auth.loginDataContext.master}
                      onChange={() =>
                        setData({ ...data, active: !data.active })
                      }
                    />
                  </Form.Group>
                </Col>

                {idProject !== "|new|" && (
                  <Col className="mb-3" lg={10}>
                    <Form.Group controlId="formGridId">
                      <Form.Label>ID:</Form.Label>
                      <Form.Control
                        type="text"
                        value={new DataFunctions().getId(data)}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>

              {/* Nome + Desc */}
              <Row>
                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridName">
                    <Form.Label>Nome do Projeto:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.name}
                      onChange={(e) => {
                        setData({
                          ...data,
                          name: e.target.value.toUpperCase(),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridDescr">
                    <Form.Label>Descrição:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.descr}
                      onChange={(e) => {
                        setData({ ...data, descr: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* CEP + Rua + Número + Complemento */}
              <Row>
                <Col className="mb-3" lg={2}>
                  <Form.Group controlId="formGridZipcode">
                    <Form.Label>CEP:</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={8}
                      onBlur={async (e) => {
                        if (e.target.value.trim() === "") return;

                        const result = await new ExternalAPI().get(
                          URL_CEP,
                          e.target.value + "/json"
                        );

                        if (result.success) {
                          setData({
                            ...data,
                            address: {
                              ...data.address,
                              city: result.data.localidade,
                              state: result.data.uf,
                              country: "Brasil",
                              district: result.data.bairro,
                              street: result.data.logradouro,
                              zipcode: result.data.cep,
                            },
                          });
                        }
                      }}
                      value={data.address.zipcode}
                      onChange={(e) => {
                        setData({
                          ...data,
                          address: {
                            ...data.address,
                            zipcode: e.currentTarget.value.replace(/\D/g, ""),
                          },
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridStreet">
                    <Form.Label>Logradouro:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.address.street}
                      onChange={(e) => {
                        setData({
                          ...data,
                          address: {
                            ...data.address,
                            street: e.currentTarget.value,
                          },
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={2}>
                  <Form.Group controlId="formGridNumber">
                    <Form.Label>Número:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.address.number}
                      onChange={(e) => {
                        setData({
                          ...data,
                          address: {
                            ...data.address,
                            number: e.currentTarget.value,
                          },
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={2}>
                  <Form.Group controlId="formGridUnit">
                    <Form.Label>Complemento:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.address.unit}
                      onChange={(e) => {
                        setData({
                          ...data,
                          address: {
                            ...data.address,
                            unit: e.currentTarget.value,
                          },
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Bairro + Cidade + Estado + País */}
              <Row>
                <Col className="mb-3" lg={4}>
                  <Form.Group controlId="formGridDistrict">
                    <Form.Label>Bairro:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.address.district}
                      onChange={(e) => {
                        setData({
                          ...data,
                          address: {
                            ...data.address,
                            district: e.currentTarget.value,
                          },
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={4}>
                  <Form.Group controlId="formGridCity">
                    <Form.Label>Cidade:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.address.city}
                      onChange={(e) => {
                        setData({
                          ...data,
                          address: {
                            ...data.address,
                            city: e.currentTarget.value,
                          },
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={2}>
                  <Form.Group controlId="formGridState">
                    <Form.Label>Estado:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.address.state}
                      onChange={(e) => {
                        setData({
                          ...data,
                          address: {
                            ...data.address,
                            state: e.currentTarget.value,
                          },
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={2}>
                  <Form.Group controlId="formGridCountry">
                    <Form.Label>País:</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={2}
                      value={data.address.country}
                      onChange={(e) => {
                        setData({
                          ...data,
                          address: {
                            ...data.address,
                            country: e.currentTarget.value,
                          },
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Usuários */}
              <Row className="mt-3 mb-5">
                <TableItem
                  typeSort="String"
                  bordered={true}
                  hover={true}
                  responsive={true}
                  striped={true}
                  dataView={data.users.map((item) => {
                    return {
                      key: new DataFunctions().getId(item),
                      name: item.name,
                    };
                  })}
                  onEdit={() => {}}
                  onRemove={() => {}}
                  title="Usuário(s)"
                  onAdd={() => setShowModal(true)}
                  showAdd={auth.loginDataContext.master}
                />
              </Row>

              {/* Locais */}
              <Row className="mt-3 mb-5">
                <TableItem
                  typeSort="String"
                  bordered={true}
                  hover={true}
                  responsive={true}
                  striped={true}
                  dataView={
                    data.places
                      ? data.places.map((item, index) => {
                          return { key: index.toString(), name: item };
                        })
                      : []
                  }
                  onEdit={(item) => {
                    setPlace({ new: false, name: item.name, key: item.key });
                    setShowModalPlaces(true);
                  }}
                  onRemove={(item) => {
                    if (data.places) {
                      var current_shield = data.places;
                      current_shield = data.places.filter(
                        (s) => s !== item.name
                      );
                      setData({ ...data, places: current_shield });
                    }
                  }}
                  title="Locais de Identificação"
                  hiddenColumnOne={true}
                  edit={true}
                  remove={true}
                  onAdd={() => {
                    setPlace({ name: "", new: true, key: "" });
                    setShowModalPlaces(true);
                  }}
                  showAdd={auth.loginDataContext.master}
                />
              </Row>

              {auth.loginDataContext.master && (
                <Row className="mb-3">
                  {idProject !== "|new|" && (
                    <Col className="text-start">
                      <Button
                        variant="danger"
                        onClick={(e) => {
                          setShowModalDelete(true);
                        }}
                      >
                        Excluir
                      </Button>
                    </Col>
                  )}
                  <Col className="text-end">
                    <Button
                      variant="success"
                      onClick={(e) => {
                        save();
                      }}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </div>

          {errors && (
            <AlertMessage messages={errors} setMessages={(e) => setErrors(e)} />
          )}
        </Container>
      )}
    </div>
  );
};

export default ProjectPage;
