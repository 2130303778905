import { CgOptions } from "react-icons/cg";
import { TbComponents } from "react-icons/tb";
import { VscGroupByRefType } from "react-icons/vsc";
import { MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";

const SettingsSensorsMenu = () => {
  return (
    <SubMenu label="Sensores" icon={<TbComponents />}>
      <Link to={`/auth/settings/sensors/types`}>
        <MenuItem icon={<VscGroupByRefType />}>Tipos</MenuItem>
      </Link>

      <Link to={`/auth/settings/sensors/returns`}>
        <MenuItem icon={<CgOptions />}>Retornos</MenuItem>
      </Link>
    </SubMenu>
  );
};

export default SettingsSensorsMenu;
