import moment from "../utils/moment";

export default class SortFunctions {
  a: any;
  b: any;
  constructor(a: any, b: any) {
    this.a = a;
    this.b = b;
  }

  sortStrAsc = () => {
    if (this.a > this.b) return 1;
    if (this.a < this.b) return -1;
    return 0;
  };

  sortStrDes = () => {
    if (this.a > this.b) return -1;
    if (this.a < this.b) return 1;
    return 0;
  };

  sortNumberAsc = () => {
    return this.a - this.b;
  };

  sortNumberDes = () => {
    return this.b - this.a;
  };

  sortDateAsc = (mask?: string) => {
    return (
      +new Date(moment(this.a, mask).format()) -
      +new Date(moment(this.b, mask).format())
    );
  };

  sortDateDes = (mask?: string) => {
    return (
      +new Date(moment(this.b, mask).format()) -
      +new Date(moment(this.a, mask).format())
    );
  };
}
