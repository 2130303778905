import { createRef, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import MenuBar from "./menu";

const SideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const menuBarRef = createRef<HTMLHtmlElement>();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [marginSize, setMarginSize] = useState(250);

  useEffect(() => {
    if (menuBarRef !== null && menuBarRef.current) {
      setMarginSize(
        menuBarRef.current.clientWidth ? menuBarRef.current.clientWidth : 250
      );
    }
  }, [menuBarRef]);

  useEffect(() => {
    setPageWidth(window.innerWidth);
  }, []);

  return (
    <div id="sidenav">
      <MenuBar
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        sidebarRef={menuBarRef}
      />

      <div
        style={{
          height: "100vh",
          overflow: "auto",
          width: pageWidth - marginSize + 10,
          padding: "10px",
          marginLeft: marginSize + 10,
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default SideBar;
