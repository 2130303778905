import { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { IAlertMessage } from "../../../../../../libs/types/messages";
import { IMQTTPublish } from "../../../../../../libs/types/mqtt";
import { IProject } from "../../../../../../libs/types/projects";
import { IVariables } from "../../../../../../libs/types/variables";
import AlertMessage from "../../../components/alertMessage";
import ControlsTable from "../../../components/controls/table";
import SelectItem from "../../../components/dataView/selectItem";
import { useLoginContext } from "../../../context";
import DataFunctions from "../../../functions/database";
import SortFunctions from "../../../functions/sorts";
import useProjectsSocket from "../../../hooks/useProjectsSocket";
import useVariablesSocket from "../../../hooks/useVariablesSocket";
import MqttAPI from "../../../services/api/mqtt";
import GenerateError from "../../../validations/errors";

const SORT = [
  {
    _id: "0",
    name: "Nome",
  },
  {
    _id: "1",
    name: "Tipo de Sensor",
  },
  {
    _id: "2",
    name: "Local de Instalação",
  },
];

const VIEW = [
  {
    _id: "0",
    name: "Cards",
  },
  {
    _id: "1",
    name: "Lista",
  },
];

const ControlsPage = () => {
  let auth = useLoginContext();
  const projects = useProjectsSocket();
  const variables = useVariablesSocket();

  const [options, setOptions] = useState({ sort: SORT[0], view: VIEW[0] });

  const [selectedProject, setSelectedProject] = useState<IProject>();

  const [variablesSensors, setVariablesSensors] = useState<IVariables[]>([]);
  const [variablesCogifs, setVariablesCogifs] = useState<IVariables[]>([]);
  const [errors, setErrors] = useState<IAlertMessage[]>([]);

  useEffect(() => {
    if (!selectedProject) return;

    var var_sensors: IVariables[] = [];
    var config: IVariables[] = [];

    if (auth.loginDataContext.master) {
      var_sensors = variables.filter(
        (item) =>
          item.sensorStateModifier &&
          new DataFunctions().getId(item.board.project) ===
            new DataFunctions().getId(selectedProject)
      );
      config = variables.filter(
        (item) =>
          !item.sensorStateModifier &&
          new DataFunctions().getId(item.board.project) ===
            new DataFunctions().getId(selectedProject)
      );
    } else {
      var_sensors = variables.filter(
        (item) =>
          item.sensorStateModifier &&
          item.userEditable &&
          new DataFunctions().getId(item.board.project) ===
            new DataFunctions().getId(selectedProject)
      );
      config = variables.filter(
        (item) =>
          !item.sensorStateModifier &&
          item.userEditable &&
          new DataFunctions().getId(item.board.project) ===
            new DataFunctions().getId(selectedProject)
      );
    }

    setVariablesSensors(var_sensors);
    setVariablesCogifs(config);
  }, [variables, selectedProject]);

  useEffect(() => {
    setVariablesSensors(variablesSensors);
    setVariablesCogifs(variablesCogifs);
  }, [options]);

  useEffect(() => {}, [variablesSensors]);

  const sortData = (a: IVariables, b: IVariables) => {
    switch (options.sort._id) {
      case "0":
        return new SortFunctions(a.name, b.name).sortStrAsc();

      case "1":
        return new SortFunctions(
          a.sensor?.type.name,
          b.sensor?.type.name
        ).sortStrAsc();

      default:
        return new SortFunctions(a.sensor?.place, b.sensor?.place).sortStrAsc();
    }
  };

  const update = async (item: IVariables) => {
    try {
      if (item) {
        const data: IMQTTPublish = {
          pin: item.sensor ? item.sensor.pin.toString() : "",
          token: item.board.token ? item.board.token : "",
          value: item.value,
          variable: item.name,
          variableType: item.type,
        };

        const result = await new MqttAPI().publish(
          new DataFunctions().getId(item),
          data
        );

        if (!result.success) {
          throw new GenerateError(result.message);
        }
      }
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    }
  };

  return (
    <Container fluid>
      <h1>Controles</h1>

      <div className="mx-4 mt-5">
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formGridType">
              <Form.Label>Projeto:</Form.Label>
              <SelectItem
                options={new DataFunctions().getResume(projects)}
                onChange={(e) => {
                  setSelectedProject(
                    projects.find(
                      (item) => new DataFunctions().getId(item) === e[0].key
                    )
                  );
                }}
                values={
                  selectedProject
                    ? new DataFunctions().getResume([selectedProject])
                    : []
                }
                type="projeto"
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="formGridSort">
              <Form.Label>Ordernar por:</Form.Label>
              <SelectItem
                options={new DataFunctions().getResume(SORT)}
                onChange={(e) => {
                  const sort = SORT.find((item) => item._id === e[0].key);

                  setOptions({
                    ...options,
                    sort: sort ? sort : SORT[0],
                  });
                }}
                values={new DataFunctions().getResume([options.sort])}
                type="projeto"
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="formGridView">
              <Form.Label>Visualizar como:</Form.Label>
              <SelectItem
                options={new DataFunctions().getResume(VIEW)}
                onChange={(e) => {
                  const view = VIEW.find((item) => item._id === e[0].key);

                  setOptions({
                    ...options,
                    view: view ? view : VIEW[0],
                  });
                }}
                values={new DataFunctions().getResume([options.view])}
                type="projeto"
              />
            </Form.Group>
          </Col>
        </Row>

        {selectedProject ? (
          options.view._id === "0" ? (
            <div>
              <ControlsTable
                data={variablesSensors.sort((a, b) => sortData(a, b))}
                update={update}
                title="Variáveis / Sensores"
              />

              <ControlsTable
                data={variablesCogifs.sort((a, b) => sortData(a, b))}
                update={update}
                title="Variáveis de Configuração"
              />
            </div>
          ) : (
            <div>
              <ControlsTable
                data={variablesSensors.sort((a, b) => sortData(a, b))}
                update={update}
                title="Variáveis / Sensores"
              />

              <ControlsTable
                data={variablesCogifs.sort((a, b) => sortData(a, b))}
                update={update}
                title="Variáveis de Configuração"
              />
            </div>
          )
        ) : null}
      </div>

      {errors && (
        <div className="mt-5">
          <AlertMessage messages={errors} setMessages={(e) => setErrors(e)} />
        </div>
      )}
    </Container>
  );
};

export default ControlsPage;
