import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { LoginProvider } from "./context";
import "./index.css";
import Router from "./routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <LoginProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </LoginProvider>
  </React.StrictMode>
);
