export type IKeys = "user";
//export type IKeys = "user" | "uid";

class Storage {
  get(key: IKeys) {
    if (typeof window !== "undefined") {
      return localStorage.getItem(key);
    }
  }

  set(key: IKeys, data: any) {
    if (typeof window !== "undefined") {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  remove(key: IKeys) {
    if (typeof window !== "undefined") {
      localStorage.removeItem(key);
    }
  }

  clear() {
    if (typeof window !== "undefined") {
      localStorage.clear();
    }
  }
}

export default Storage;
