import { useEffect, useState } from "react";
import { Container, Form, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IBoard } from "../../../../../../../libs/types/boards";
import { IAlertMessage } from "../../../../../../../libs/types/messages";
import { IProject } from "../../../../../../../libs/types/projects";
import { IVariables } from "../../../../../../../libs/types/variables";
import AlertMessage from "../../../../components/alertMessage";
import DataFunctions from "../../../../functions/database";
import SortFunctions from "../../../../functions/sorts";
import StringFunctions from "../../../../functions/strings";
import BoardsAPI from "../../../../services/api/boards";
import ProjectsAPI from "../../../../services/api/projects";
import VariablesAPI from "../../../../services/api/variables";
import moment from "../../../../utils/moment";
import GenerateError from "../../../../validations/errors";

const VariableListPage = () => {
  let { idBoard, idProject } = useParams();

  const [project, setProject] = useState<IProject>();
  const [board, setBoard] = useState<IBoard>();
  const [data, setData] = useState<IVariables[]>([]);

  const [errors, setErrors] = useState<IAlertMessage[]>([]);

  useEffect(() => {
    const loadProject = async () => {
      if (idProject) {
        const result = await new ProjectsAPI().get(idProject);
        if (result.success) setProject(result.data);
      }
    };

    loadProject();
  }, []);

  useEffect(() => {
    const loadBoard = async () => {
      if (idBoard) {
        const result = await new BoardsAPI().get(idBoard);
        if (result.success) setBoard(result.data);
      }
    };

    loadBoard();
  }, []);

  useEffect(() => {
    loadSensor();
  }, []);

  const loadSensor = async () => {
    if (idBoard) {
      const result = await new VariablesAPI().listByBoard(idBoard);
      if (result.success) setData(result.data);
    }
  };

  const update = async (item: IVariables) => {
    try {
      if (!data) {
        throw new GenerateError("Preencha os dados.");
      }

      const result = await new VariablesAPI().update(
        new DataFunctions().getId(item),
        item
      );

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      loadSensor();
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    }
  };

  return (
    <div>
      {project && board && (
        <>
          <h1>
            Listagem de Variáveis - {project.name} / {board.name}
          </h1>

          <Container fluid>
            <div className="mx-4 mt-5">
              <Table
                className="mt-3"
                striped
                bordered
                hover
                size="sm"
                variant="dark"
              >
                <thead>
                  <tr className="text-center align-middle">
                    <th>Nome</th>
                    <th>Tipo</th>
                    <th>Modificador do Estado do Sensor</th>
                    <th>Editável pelo Usuário</th>
                    <th>Sensor</th>
                    <th>Valor</th>
                    <th>Descrição</th>
                    <th>Última Atualização</th>
                  </tr>
                </thead>
                <tbody>
                  {data
                    .sort((a, b) =>
                      new SortFunctions(a.name, b.name).sortStrAsc()
                    )
                    .map((item) => {
                      return (
                        <tr
                          className="text-center align-middle"
                          key={new DataFunctions().getId(item)}
                        >
                          <td className="col-md-1">{item.name}</td>

                          <td className="col-md-1">
                            {new StringFunctions(item.type).capitalLetter()}
                          </td>

                          <td className="col-md-1">
                            <Form.Check
                              type="checkbox"
                              label={item.sensorStateModifier ? "Sim" : "Não"}
                              checked={item.sensorStateModifier}
                              onChange={() =>
                                update({
                                  ...item,
                                  sensorStateModifier:
                                    !item.sensorStateModifier,
                                })
                              }
                            />
                          </td>

                          <td className="col-md-1">
                            <Form.Check
                              type="checkbox"
                              label={item.userEditable ? "Sim" : "Não"}
                              checked={item.userEditable}
                              onChange={() =>
                                update({
                                  ...item,
                                  userEditable: !item.userEditable,
                                })
                              }
                            />
                          </td>

                          <td className="col-md-1">
                            {item.sensor &&
                              `${item.sensor.name} [${
                                item.sensor.type.name
                              }] - Pino: ${item.sensor.pin} [${
                                item.sensor.type.digitalSignal
                                  ? "Digital"
                                  : "Analógico"
                              }]`}
                          </td>

                          <td className="col-md-1">
                            {item.type === "boolean" ? (
                              <div>
                                <Form.Check
                                  type="switch"
                                  label={
                                    Boolean(item.value) ? "Ligado" : "Desligado"
                                  }
                                  checked={Boolean(item.value)}
                                  onChange={() =>
                                    update({
                                      ...item,
                                      value: !item.value,
                                    })
                                  }
                                />
                              </div>
                            ) : item.type === "string" ? (
                              <Form.Control
                                type={"text"}
                                value={item.value.toString()}
                                onChange={(e) =>
                                  update({
                                    ...item,
                                    value: e.target.value.trim(),
                                  })
                                }
                              />
                            ) : (
                              <Form.Control
                                type={"number"}
                                value={parseFloat(item.value.toString())}
                                onChange={(e) =>
                                  update({
                                    ...item,
                                    value: parseFloat(e.target.value.trim()),
                                  })
                                }
                              />
                            )}
                          </td>

                          <td className="col-md-1">{item.descr}</td>

                          <td className="col-md-1">
                            {moment(item.modifiedAt).format(
                              "DD/MM/YYYY [às] HH:mm:ss"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>

            {errors && (
              <AlertMessage
                messages={errors}
                setMessages={(e) => setErrors(e)}
              />
            )}
          </Container>
        </>
      )}
    </div>
  );
};

export default VariableListPage;
