import { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { IBoard } from "../../../../../../../libs/types/boards";
import { IAlertMessage } from "../../../../../../../libs/types/messages";
import { IProject } from "../../../../../../../libs/types/projects";
import {
  ISensor,
  ISensorValues,
} from "../../../../../../../libs/types/sensors";
import { ISettingsSensorReturns } from "../../../../../../../libs/types/settings";
import AlertMessage from "../../../../components/alertMessage";
import SelectItem from "../../../../components/dataView/selectItem";
import DataFunctions from "../../../../functions/database";
import SortFunctions from "../../../../functions/sorts";
import StringFunctions from "../../../../functions/strings";
import useBoardsSocket from "../../../../hooks/useBoardsSocket";
import useProjectsSocket from "../../../../hooks/useProjectsSocket";
import useSensorsSocket from "../../../../hooks/useSensorsSocket";
import SensorValuesAPI from "../../../../services/api/sensorValues";
import moment from "../../../../utils/moment";
import numeral from "../../../../utils/numeral";
import GenerateError from "../../../../validations/errors";

interface IGenerator {
  strikes: number;
  minVal: number;
  maxVal: number;
  dateFrom: Date;
  dateEnd: Date;
  delay: number;
}

const MIN_INTERVAL = 1;
const MAX_INTERVAL = 60;
const STP_INTERVAL = 3;

const MIN_STRIKES = 5;
const MAX_STRIKES = 100;

const DEFAULT_VALUE: IGenerator = {
  delay: MIN_INTERVAL,
  dateFrom: new Date(moment().add(-6, "months").format()),
  dateEnd: new Date(moment().format()),
  maxVal: 1023,
  minVal: 0,
  strikes: 5,
};

const ValuesGeneratorPage = () => {
  const projects = useProjectsSocket();
  const boards = useBoardsSocket();
  const sensors = useSensorsSocket();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IAlertMessage[]>([]);

  const [data, setData] = useState<{
    project: IProject | undefined;
    board: IBoard | undefined;
    sensor: ISensor | undefined;
    unit: ISettingsSensorReturns | undefined;
  }>();
  const [currentSensorValue, setCurrentSensorValue] = useState<ISensorValues>();
  const [generatorSettings, setGeneratorSettings] =
    useState<IGenerator>(DEFAULT_VALUE);
  const [strikes, setStrikes] = useState(0);
  const [idInterval, setIdInterval] = useState<NodeJS.Timer>();

  const randomDate = (
    start: Date,
    end: Date,
    startHour: number,
    endHour: number
  ) => {
    var date = new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
    var hour = (startHour + Math.random() * (endHour - startHour)) | 0;
    date.setHours(hour);
    return date;
  };

  const generateValues = async () => {
    setLoading(true);

    try {
      const dates: Date[] = [];
      const dateFrom: Date = new Date(
        moment(generatorSettings.dateFrom).format()
      );
      const dateEnd: Date = new Date(
        moment(generatorSettings.dateEnd).format()
      );

      if (!data || !data.sensor) throw new GenerateError("Preencha os dados.");

      const digitalSignal = data.sensor.type.digitalSignal;
      const sensor = data.sensor;

      if (dateFrom > dateEnd)
        throw new GenerateError(
          "A data de início não pode ser maior que a data final."
        );
      if (generatorSettings.minVal > generatorSettings.maxVal)
        throw new GenerateError(
          "O valor mínimo não pode ser maior que o valor máximo."
        );

      for (let index = 0; index < generatorSettings.strikes; index++) {
        const rawHours = [
          Math.floor(Math.random() * 24),
          Math.floor(Math.random() * 24),
        ];
        const sortedHours = rawHours.sort((a, b) =>
          new SortFunctions(a, b).sortNumberAsc()
        );

        const newDate = randomDate(
          dateFrom,
          dateEnd,
          sortedHours[0],
          sortedHours[1]
        );

        dates.push(newDate);
      }

      setStrikes(0);
      let counter = 0;
      const timmer = generatorSettings.delay * 1000;
      const alerts: IAlertMessage[] = [];

      const i = setInterval(async () => {
        if (counter < generatorSettings.strikes) {
          var val = 0;

          if (digitalSignal) {
            val = Math.floor(Math.random() * 2);
          } else {
            val =
              Math.floor(Math.random() * generatorSettings.maxVal) +
              generatorSettings.minVal;
          }

          var newValue: ISensorValues = {
            createdAt: dates.sort((a, b) =>
              new SortFunctions(a, b).sortDateAsc()
            )[counter],
            sensor,
            val,
          };

          if (data.unit) newValue.unit = data.unit.shortUnitMeasure.trim();

          const result = await new SensorValuesAPI().create([newValue]);

          if (result.success) {
            alerts.push({
              key: uuidv4().toString(),
              header: "Valor inserido",
              message: `Data: ${moment(result.data[0].createdAt).format(
                "DD/MM/YYYY [às] HH:mm:ss"
              )}   |   Pino: ${sensor.pin}   |   Valor: ${result.data[0].val}`,
              variant: "success",
            });
          } else {
            alerts.push({
              key: uuidv4().toString(),
              header: "Erro ao inserir valor",
              message: result.message,
              variant: "danger",
            });
          }

          setCurrentSensorValue(newValue);
        }

        counter++;
        setStrikes(counter);

        if (counter === generatorSettings.strikes + 1) {
          setLoading(false);
          clearInterval(i);
          setLoading(false);

          setErrors([
            {
              key: uuidv4().toString(),
              header: "Finalizado",
              message: `Realizado o total de ${
                alerts.length
              } inserções no sensor ${sensor.name.trim().toUpperCase()}.`,
              variant: "warning",
            },
            ...alerts,
          ]);
        }
      }, timmer);

      setIdInterval(i);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    }
  };

  return (
    <div>
      <h1>Gerador de Valores (Sensores)</h1>

      <Container fluid>
        <div className="mx-4 mt-5">
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextProject"
            >
              <Form.Label column lg={2}>
                Projeto:
              </Form.Label>
              <Col>
                <SelectItem
                  onChange={(e) => {
                    setData({
                      project: projects.find(
                        (item) => new DataFunctions().getId(item) === e[0].key
                      ),
                      board: undefined,
                      sensor: undefined,
                      unit: undefined,
                    });
                  }}
                  options={new DataFunctions().getResume(projects)}
                  values={
                    data && data.project
                      ? new DataFunctions().getResume([data.project])
                      : []
                  }
                  type="projeto"
                  isMulti={false}
                  disable={loading}
                />
              </Col>
            </Form.Group>

            {data && data.project && (
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextBoard"
              >
                <Form.Label column lg={2}>
                  Placa:
                </Form.Label>
                <Col>
                  <SelectItem
                    onChange={(e) => {
                      setData({
                        project: data.project,
                        board: boards.find(
                          (item) => new DataFunctions().getId(item) === e[0].key
                        ),
                        sensor: undefined,
                        unit: undefined,
                      });
                    }}
                    options={new DataFunctions().getResume(boards)}
                    values={
                      data && data.board
                        ? new DataFunctions().getResume([data.board])
                        : []
                    }
                    type="placa"
                    isMulti={false}
                    disable={loading}
                  />
                </Col>
              </Form.Group>
            )}

            {data && data.board && (
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextSensor"
              >
                <Form.Label column lg={2}>
                  Sensor:
                </Form.Label>
                <Col>
                  <SelectItem
                    onChange={(e) => {
                      setData({
                        project: data.project,
                        board: data.board,
                        sensor: sensors.find(
                          (item) => new DataFunctions().getId(item) === e[0].key
                        ),
                        unit: undefined,
                      });
                    }}
                    options={new DataFunctions().getResume(sensors)}
                    values={
                      data && data.sensor
                        ? new DataFunctions().getResume([data.sensor])
                        : []
                    }
                    type="sensor"
                    isMulti={false}
                    disable={loading}
                  />
                </Col>
              </Form.Group>
            )}

            {data && data.sensor && (
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextSensor"
              >
                <Form.Label column lg={2}>
                  Retorno:
                </Form.Label>
                <Col>
                  <SelectItem
                    isClearable={true}
                    onChange={(e) => {
                      if (data.sensor) {
                        setData({
                          project: data.project,
                          board: data.board,
                          sensor: data.sensor,
                          unit: data.sensor.type.returns.find(
                            (item) =>
                              new DataFunctions().getId(item) === e[0].key
                          ),
                        });
                      }
                    }}
                    options={new DataFunctions().getResume(
                      data.sensor.type.returns
                    )}
                    type="tipo de unidade de medida (retorno)"
                    values={
                      data.unit
                        ? new DataFunctions().getResume([data.unit])
                        : []
                    }
                    isMulti={false}
                  />
                </Col>
              </Form.Group>
            )}

            {data && data.unit && (
              <Row>
                <Col className="mb-3">
                  <Form.Group controlId="formGridDateFrom">
                    <Form.Label>Unidade de Medida:</Form.Label>

                    <Form.Control
                      type="text"
                      value={new StringFunctions(
                        data.unit.fullUnitMeasure
                      ).cammelCase()}
                      readOnly
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3">
                  <Form.Group controlId="formGridDateFrom">
                    <Form.Label>Unidade de Medida (Reduzida):</Form.Label>

                    <Form.Control
                      type="text"
                      value={data.unit.shortUnitMeasure}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            {data && data.sensor && (
              <>
                <Row>
                  <Col className="mb-3" lg={3}>
                    <Form.Group controlId="formGridSignal">
                      <Form.Label>Tipo de Sinal:</Form.Label>
                      <Form.Check
                        label={
                          data.sensor.type.digitalSignal
                            ? "Digital"
                            : "Analógico"
                        }
                        type="switch"
                        placeholder="formGridSignal"
                        checked={data.sensor.type.digitalSignal}
                        disabled={loading}
                        readOnly
                      />
                    </Form.Group>
                  </Col>

                  <Col className="mb-3">
                    <Form.Group controlId="formGridDateFrom">
                      <Form.Label>Data de Início:</Form.Label>

                      <Form.Control
                        type="datetime-local"
                        placeholder="Enter begin date"
                        value={moment(generatorSettings.dateFrom).format(
                          "YYYY-MM-DDTHH:mm"
                        )}
                        onChange={(e) => {
                          setGeneratorSettings({
                            ...generatorSettings,
                            dateFrom: new Date(e.target.value),
                          });
                        }}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="mb-3">
                    <Form.Group controlId="formGridDateEnd">
                      <Form.Label>Data de Fim:</Form.Label>

                      <Form.Control
                        type="datetime-local"
                        placeholder="Enter end date"
                        value={moment(generatorSettings.dateEnd).format(
                          "YYYY-MM-DDTHH:mm"
                        )}
                        onChange={(e) => {
                          setGeneratorSettings({
                            ...generatorSettings,
                            dateEnd: new Date(e.target.value),
                          });
                        }}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  {!data.sensor.type.digitalSignal && (
                    <Col className="mb-3">
                      <Form.Group controlId="formGridMinValue">
                        <Form.Label>Valor Mínimo:</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter minimum value"
                          step={0.01}
                          value={generatorSettings.minVal}
                          onChange={(e) =>
                            setGeneratorSettings({
                              ...generatorSettings,
                              minVal: parseFloat(e.target.value),
                            })
                          }
                          disabled={loading}
                        />
                      </Form.Group>
                    </Col>
                  )}

                  {!data.sensor.type.digitalSignal && (
                    <Col className="mb-3">
                      <Form.Group controlId="formGridMaxValue">
                        <Form.Label>Valor Máximo:</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter maximum value"
                          step={0.01}
                          value={generatorSettings.maxVal}
                          onChange={(e) =>
                            setGeneratorSettings({
                              ...generatorSettings,
                              maxVal: parseFloat(e.target.value),
                            })
                          }
                          disabled={loading}
                        />
                      </Form.Group>
                    </Col>
                  )}

                  <Col className="mb-3">
                    <Form.Group controlId="formGridStrikes">
                      <Form.Label>Inserções:</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={MIN_STRIKES}
                        max={MAX_STRIKES}
                        value={generatorSettings.strikes}
                        onChange={(e) => {
                          var value = Number(e.target.value);
                          if (value > 100) value = 100;

                          setGeneratorSettings({
                            ...generatorSettings,
                            strikes: value,
                          });
                        }}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-3">
                    <Form.Group controlId="formGridSignal">
                      <Form.Label>
                        <b>
                          Intervalo de Envio {`(${generatorSettings.delay}s)`}:
                        </b>
                      </Form.Label>
                      <Form.Range
                        value={generatorSettings.delay}
                        min={MIN_INTERVAL}
                        max={MAX_INTERVAL}
                        step={STP_INTERVAL}
                        onChange={(e) => {
                          setGeneratorSettings({
                            ...generatorSettings,
                            delay: Number(e.target.value),
                          });
                        }}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="my-3">
                  <Button
                    variant="outline-dark"
                    className="mb-3"
                    onClick={generateValues}
                    disabled={loading}
                  >
                    Gerar Valores para {data.sensor.name.trim().toUpperCase()}!
                  </Button>
                </Row>
              </>
            )}
          </Form>
        </div>

        {loading && (
          <div className="my-3 mx-5">
            <Row>
              <Col className="text-start">
                <span>
                  Inserções: {`${strikes}/${generatorSettings.strikes}`}
                </span>
              </Col>

              <Col className="text-end">
                <span>{`${numeral(
                  (strikes / generatorSettings.strikes) * 100
                ).format("0.00")} %`}</span>
              </Col>
            </Row>

            <ProgressBar
              animated
              now={strikes}
              min={0}
              max={generatorSettings.strikes}
            />

            {currentSensorValue && (
              <div className="mt-3">
                <h4>Valor gerado (#{strikes}):</h4>
                <Row className="my-3 mx-5">
                  <Form.Group
                    as={Row}
                    className="my-1 mx-5"
                    controlId="formPlaintextValueDate"
                  >
                    <Form.Label column lg={1}>
                      Data:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        value={moment(currentSensorValue.createdAt).format(
                          "DD/MM/YYYY [às] HH:mm:ss"
                        )}
                        readOnly
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="my-1 mx-5"
                    controlId="formPlaintextValuePin"
                  >
                    <Form.Label column lg={1}>
                      Pino:
                    </Form.Label>
                    <Col>
                      <Form.Control type="number" value={11} readOnly />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="my-1 mx-5"
                    controlId="formPlaintextValueValue"
                  >
                    <Form.Label column lg={1}>
                      Valor:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="number"
                        value={currentSensorValue.val}
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Row>
              </div>
            )}

            <Row className="my-3">
              <Button
                variant="outline-danger"
                className="mb-3"
                onClick={() => {
                  if (idInterval) {
                    setLoading(false);
                    clearInterval(idInterval);
                  }
                }}
              >
                Parar
              </Button>
            </Row>
          </div>
        )}

        {errors && (
          <AlertMessage messages={errors} setMessages={(e) => setErrors(e)} />
        )}
      </Container>
    </div>
  );
};

export default ValuesGeneratorPage;
