import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { IAlertMessage } from "../../../../../../libs/types/messages";
import { IProject } from "../../../../../../libs/types/projects";
import { IReturnHTTP } from "../../../../../../libs/types/returns";
import { ISensor } from "../../../../../../libs/types/sensors";
import { ISettingsSensorTypes } from "../../../../../../libs/types/settings";
import { IUser } from "../../../../../../libs/types/users";
import AlertMessage from "../../../components/alertMessage";
import SelectItem from "../../../components/dataView/selectItem";
import TableItem from "../../../components/dataView/table";
import Loading from "../../../components/loading";
import ModalConfirm from "../../../components/modalConfirm";
import { useLoginContext } from "../../../context";
import DataFunctions from "../../../functions/database";
import useUsersSocket from "../../../hooks/useUsersSocket";
import BoardsAPI from "../../../services/api/boards";
import ProjectsAPI from "../../../services/api/projects";
import SensorsAPI from "../../../services/api/sensors";
import SettingsSensorsTypesAPI from "../../../services/api/settings/sensors/types";
import moment from "../../../utils/moment";
import GenerateError from "../../../validations/errors";

const SensorPage = () => {
  const navigate = useNavigate();
  let auth = useLoginContext();
  let { idSensor, idBoard, idProject } = useParams();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IAlertMessage[]>([]);
  const [data, setData] = useState<ISensor>();

  const users = useUsersSocket();
  const [project, setProject] = useState<IProject>();
  const [sensorsTypes, setSensorsTypes] = useState<ISettingsSensorTypes[]>([]);

  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  useEffect(() => {
    const loadSensorTypes = async () => {
      const result = await new SettingsSensorsTypesAPI().list();
      if (result.success) setSensorsTypes(result.data);
    };

    loadSensorTypes();

    const loadProject = async () => {
      const result = await new ProjectsAPI().get(idProject ? idProject : "");
      if (result.success) setProject(result.data);
    };

    loadProject();
  }, []);

  useEffect(() => {
    setErrors([]);

    const loadData = async () => {
      const result = await new BoardsAPI().get(idBoard ? idBoard : "");

      if (result.success) {
        if (idSensor === "|new|") {
          setData({
            name: "",
            active: true,
            board: result.data,
            pin: 0,
            place: "",
            type: {
              createdAt: new Date(),
              digitalSignal: true,
              name: "",
              returns: [],
            },
            users: [],
            descr: "",
            messageAlert: "",
            createdAt: new Date(),
          });
        } else {
          if (idSensor) {
            const result = await new SensorsAPI().get(idSensor);
            if (result.success) setData(result.data);
          }
        }
      }
    };

    loadData();
  }, [idSensor]);

  const save = async () => {
    setLoading(true);

    try {
      if (!data) {
        throw new GenerateError("Preencha os dados.");
      }

      var result: IReturnHTTP = { data: null, message: "", success: false };

      if (idSensor === "|new|") {
        result = await new SensorsAPI().create([data]);
      } else {
        result = await new SensorsAPI().update(idSensor ? idSensor : "", data);
      }

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          variant: "success",
          header: "Concluído",
          message:
            idSensor === "|new|"
              ? "Sensor criado com sucesso!"
              : "Aletarações realizadas com sucesso!",
        },
      ]);

      setData({
        name: "",
        active: true,
        board: data.board,
        pin: 0,
        place: "",
        type: {
          createdAt: new Date(),
          digitalSignal: true,
          name: "",
          returns: [],
        },
        users: [],
        descr: "",
        createdAt: new Date(),
      });
      navigate(`/auth/project/${idProject}/board/${idBoard}/sensor/|new|`);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      setLoading(false);
    }
  };

  const del = async () => {
    setLoading(true);

    try {
      const result = await new SensorsAPI().delete(idSensor ? idSensor : "");

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      navigate(`/auth`);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {data ? (
        <Modal
          size="lg"
          show={showModal}
          backdrop="static"
          keyboard={false}
          onHide={() => setShowModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Selecione o(s) usuário(s) para adicionar à este projeto:
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SelectItem
              options={new DataFunctions().getResume(users)}
              values={data.users.map((item) => {
                return {
                  key: new DataFunctions().getId(item),
                  name: item.user,
                };
              })}
              onChange={(e) => {
                const current_users: IUser[] = [];

                e.map((item) => {
                  const user = users.find(
                    (usr) => new DataFunctions().getId(usr) === item.key
                  );
                  if (user) current_users.push(user);
                });

                setData({
                  ...data,
                  users: current_users,
                });
              }}
              type="usuários"
              isMulti={true}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}

      <ModalConfirm
        result={(e) => {
          if (!e.result) {
            setShowModalDelete(false);
          } else {
            del();
          }
        }}
        showModal={showModalDelete}
        type="Delete"
        continueQuestion="deste sensor"
        secondInformation="Ao excluir este sensor, demais dados como valores gerados por este também serão excluídos."
        centered={true}
        size="lg"
      />

      <h1>
        {idSensor === "|new|"
          ? "Novo Sensor"
          : `Sensor${!data ? "" : ` - ${data.name}`}`}
      </h1>

      {idSensor !== "|new|" && data && (
        <div className="date">
          <span>
            <b>Criação:</b>{" "}
            {moment(data.createdAt).format("DD/MM/YYYY [às] HH:mm:ss")}
          </span>
          {data.modifiedAt && (
            <>
              <br />
              <span>
                <b>Modificação:</b>{" "}
                {moment(data.modifiedAt).format("DD/MM/YYYY [às] HH:mm:ss")}
              </span>
            </>
          )}
        </div>
      )}

      {!data ? (
        errors.length > 0 ? (
          <div className="center__box">
            <AlertMessage
              messages={errors}
              setMessages={(e) => setErrors(e)}
              dismissible={false}
            />
          </div>
        ) : (
          <Loading />
        )
      ) : loading ? (
        <Loading />
      ) : (
        <Container fluid>
          <div className="mx-4 mt-5">
            <Form>
              {/* Ativo + Projeto + ID  */}
              <Row>
                <Col className="mb-3" lg={2} sm={6}>
                  <Form.Group controlId="formGridActive">
                    <Form.Label>Ativo:</Form.Label>
                    <Form.Check
                      label={data.active ? "Ativo" : "Inativo"}
                      type="switch"
                      checked={data.active}
                      disabled={!auth.loginDataContext.master}
                      onChange={() =>
                        setData({ ...data, active: !data.active })
                      }
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={idSensor !== "|new|" ? 5 : 10}>
                  <Form.Group controlId="formGridIdBoard">
                    <Form.Label>Placa ID:</Form.Label>
                    <Form.Control type="text" value={idBoard} disabled />
                  </Form.Group>
                </Col>

                {idSensor !== "|new|" && (
                  <Col className="mb-3" lg={5}>
                    <Form.Group controlId="formGridId">
                      <Form.Label>ID:</Form.Label>
                      <Form.Control
                        type="text"
                        value={new DataFunctions().getId(data)}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>

              {/* Nome + Tipo Sensor */}
              <Row>
                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridName">
                    <Form.Label>Nome do Sensor:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.name}
                      onChange={(e) => {
                        setData({
                          ...data,
                          name: e.target.value.toUpperCase(),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridType">
                    <Form.Label>Tipo de Sensor:</Form.Label>
                    <SelectItem
                      onChange={(e) => {
                        const index = sensorsTypes.findIndex(
                          (item) => new DataFunctions().getId(item) === e[0].key
                        );

                        if (index > -1)
                          setData({
                            ...data,
                            type: sensorsTypes[index],
                          });
                      }}
                      options={new DataFunctions().getResume(sensorsTypes)}
                      type="tipo de sensor"
                      values={new DataFunctions().getResume([data.type])}
                      isMulti={false}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Pino + Local */}
              <Row>
                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridPin">
                    <Form.Label>Pino:</Form.Label>
                    <Form.Control
                      type="number"
                      value={data.pin}
                      onChange={(e) => {
                        setData({
                          ...data,
                          pin: parseInt(e.currentTarget.value),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                {project && (
                  <Col className="mb-3" lg={6}>
                    <Form.Group controlId="formGridPlace">
                      <Form.Label>Local Instalado:</Form.Label>
                      <SelectItem
                        onChange={(e) => {
                          setData({
                            ...data,
                            place: e[0].name.toUpperCase().trim(),
                          });
                        }}
                        options={project.places.map((item) => {
                          return { key: item, name: item.toUpperCase().trim() };
                        })}
                        type="local"
                        values={[
                          {
                            key: data.place,
                            name: data.place.toUpperCase().trim(),
                          },
                        ]}
                        isMulti={false}
                      />

                      {/* <Form.Control
                      type="text"
                      value={data.place}
                      onChange={(e) => {
                        setData({
                          ...data,
                          place: e.currentTarget.value,
                        });
                      }}
                    /> */}
                    </Form.Group>
                  </Col>
                )}
              </Row>

              {/* Descrição */}
              <Row>
                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridDescr">
                    <Form.Label>Descrição:</Form.Label>
                    <Form.Control
                      type="text"
                      value={!data.descr ? "" : data.descr.toUpperCase()}
                      onChange={(e) => {
                        setData({ ...data, descr: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridAlert">
                    <Form.Label>Mensagem de Alerta:</Form.Label>
                    <Form.Control
                      type="text"
                      value={
                        !data.messageAlert
                          ? ""
                          : data.messageAlert.toUpperCase()
                      }
                      onChange={(e) => {
                        setData({ ...data, messageAlert: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Usuários */}
              <Row className="mt-3 mb-5">
                <TableItem
                  typeSort="String"
                  bordered={true}
                  hover={true}
                  responsive={true}
                  striped={true}
                  dataView={data.users.map((item) => {
                    return {
                      key: new DataFunctions().getId(item),
                      name: item.name,
                    };
                  })}
                  onEdit={() => {}}
                  onRemove={() => {}}
                  title="Usuário(s)"
                  onAdd={() => setShowModal(true)}
                  showAdd={auth.loginDataContext.master}
                />
              </Row>

              {/* Botões */}
              {auth.loginDataContext.master && (
                <Row className="mb-3">
                  {idSensor !== "|new|" && (
                    <Col className="text-start">
                      <Button
                        variant="danger"
                        onClick={(e) => {
                          setShowModalDelete(true);
                        }}
                      >
                        Excluir
                      </Button>
                    </Col>
                  )}
                  <Col className="text-end">
                    <Button
                      variant="success"
                      onClick={(e) => {
                        save();
                      }}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </div>

          {errors && (
            <AlertMessage messages={errors} setMessages={(e) => setErrors(e)} />
          )}
        </Container>
      )}
    </div>
  );
};

export default SensorPage;
