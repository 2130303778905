import API from "..";
import { IReturnHTTP } from "../../../../../../libs/types/returns";
import { ISensorValues } from "../../../../../../libs/types/sensors";

export default class SensorValuesAPI extends API {
  private path: string = "sensor-values";

  create = async (data: ISensorValues[]): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}`;

    try {
      this.result = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
        body: JSON.stringify(data),
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  list = async (): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  listByProject = async (id: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/project/${id}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  listByBoard = async (id: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/board/${id}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  listBySensor = async (id: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/sensor/${id}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  listByUser = async (id: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/user/${id}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  find = async (name?: string, descr?: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/find?name=${name}&descr=${descr}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  get = async (id: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/${id}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  update = async (id: string, data: ISensorValues): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/${id}`;

    try {
      this.result = await fetch(url, {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
        body: JSON.stringify(data),
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  delete = async (id: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/${id}`;

    try {
      this.result = await fetch(url, {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };
}
