import { IAddress } from "../../../../libs/types/address";
import { IDataView } from "../../../../libs/types/dataView";
import moment from "../utils/moment";
import MaskFunctions from "./masks";
import SortFunctions from "./sorts";
import StringFunctions from "./strings";

export default class DataFunctions {
  getId = (object: Object): string => {
    const keys = Object.keys(object);
    const values = Object.values(object);
    const index = keys.findIndex((item) => item === "_id" || item === "id");
    if (index === -1) return "undefined";

    return values[index];
  };

  getResume = (object: Object[]): IDataView[] => {
    const dataView: IDataView[] = [];

    if (object && object.length > 0) {
      object.map((item: any) => {
        dataView.push({
          key: this.getId(item),
          name: new StringFunctions(item.name).cammelCase(),
        });
        return true;
      });
    }

    return dataView;
  };

  getLastUpdate = (e: any[]): string => {
    const date = e.sort((a: any, b: any) =>
      new SortFunctions(a.modifiedAt, b.modifiedAt).sortDateDes()
    )[0];

    if (date) return moment(date.modifiedAt).format("DD/MM/YYYY [às] HH:mm:ss");

    return "-";
  };

  getAddress = (item: IAddress): string => {
    return `${new StringFunctions(item.street).cammelCase()}, ${
      item.number
    } - ${new StringFunctions(
      item.district
    ).cammelCase()} - ${new MaskFunctions().zipcodeMask(
      item.zipcode
    )} - ${new StringFunctions(
      item.city
    ).cammelCase()}/${item.state.toUpperCase()} `;
  };
}
