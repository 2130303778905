import hexRgb from "hex-rgb";
import rgbHex from "rgb-hex";

import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { v4 as uuidv4 } from "uuid";
import { IDataView } from "../../../../../../libs/types/dataView";
import { IAlertMessage } from "../../../../../../libs/types/messages";
import { IReturnHTTP } from "../../../../../../libs/types/returns";
import { IVariables } from "../../../../../../libs/types/variables";
import AlertMessage from "../../../components/alertMessage";
import SelectItem from "../../../components/dataView/selectItem";
import Loading from "../../../components/loading";
import ModalConfirm from "../../../components/modalConfirm";
import { useLoginContext } from "../../../context";
import DataFunctions from "../../../functions/database";
import useSensorsSocket from "../../../hooks/useSensorsSocket";
import BoardsAPI from "../../../services/api/boards";
import VariablesAPI from "../../../services/api/variables";
import moment from "../../../utils/moment";
import GenerateError from "../../../validations/errors";

const TYPES: IDataView[] = [
  { key: "0", name: "string" },
  { key: "1", name: "boolean" },
  { key: "2", name: "number" },
  { key: "3", name: "rgb" },
  { key: "4", name: "array" },
];

const VarialePage = () => {
  const navigate = useNavigate();
  let auth = useLoginContext();
  let { idVariable, idBoard, idProject } = useParams();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IAlertMessage[]>([]);
  const [data, setData] = useState<IVariables>();

  const sensors = useSensorsSocket();

  const [showModalDelete, setShowModalDelete] = useState(false);

  useEffect(() => {
    setErrors([]);

    const loadData = async () => {
      const result = await new BoardsAPI().get(idBoard ? idBoard : "");

      if (result.success) {
        if (idVariable === "|new|") {
          setData({
            name: "",
            sensorStateModifier: false,
            userEditable: false,
            value: 0,
            default: 0,
            board: result.data,
            type: "",
            descr: "",
            createdAt: new Date(),
          });
        } else {
          if (idVariable) {
            const result = await new VariablesAPI().get(idVariable);
            if (result.success) setData(result.data);
          }
        }
      }
    };

    loadData();
  }, [idVariable]);

  const save = async () => {
    setLoading(true);

    try {
      if (!data) {
        throw new GenerateError("Preencha os dados.");
      }

      var result: IReturnHTTP = { data: null, message: "", success: false };

      const variable = data;
      variable.default = variable.value;

      if (idVariable === "|new|") {
        result = await new VariablesAPI().create([variable]);
      } else {
        result = await new VariablesAPI().update(
          idVariable ? idVariable : "",
          variable
        );
      }

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          variant: "success",
          header: "Concluído",
          message:
            idVariable === "|new|"
              ? `Variável ${data.name} criada com sucesso!`
              : `Aletarações da variável ${data.name} realizadas com sucesso!`,
        },
      ]);

      setData({
        name: "",
        sensorStateModifier: false,
        userEditable: false,
        value: 0,
        default: 0,
        board: data.board,
        type: "",
        descr: "",
        createdAt: new Date(),
      });
      navigate(`/auth/project/${idProject}/board/${idBoard}/variable/|new|`);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      setLoading(false);
    }
  };

  const del = async () => {
    setLoading(true);

    try {
      const result = await new VariablesAPI().delete(
        idVariable ? idVariable : ""
      );

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      navigate(`/auth`);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      setLoading(false);
    }
  };

  const getColor = () => {
    var result = "";

    if (data) {
      result =
        data.value === ""
          ? "#000"
          : `#${rgbHex(data.value[0], data.value[1], data.value[2])}`;
    }

    return result;
  };

  return (
    <div>
      <ModalConfirm
        result={(e) => {
          if (!e.result) {
            setShowModalDelete(false);
          } else {
            del();
          }
        }}
        showModal={showModalDelete}
        type="Delete"
        continueQuestion="desta variável"
        centered={true}
        size="lg"
      />

      <h1>
        {idVariable === "|new|"
          ? "Nova Variável"
          : `Variável${!data ? "" : ` - ${data.name}`}`}
      </h1>

      {idVariable !== "|new|" && data && (
        <div className="date">
          <span>
            <b>Criação:</b>{" "}
            {moment(data.createdAt).format("DD/MM/YYYY [às] HH:mm:ss")}
          </span>
          {data.modifiedAt && (
            <>
              <br />
              <span>
                <b>Modificação:</b>{" "}
                {moment(data.modifiedAt).format("DD/MM/YYYY [às] HH:mm:ss")}
              </span>
            </>
          )}
        </div>
      )}

      {!data ? (
        errors.length > 0 ? (
          <div className="center__box">
            <AlertMessage
              messages={errors}
              setMessages={(e) => setErrors(e)}
              dismissible={false}
            />
          </div>
        ) : (
          <Loading />
        )
      ) : loading ? (
        <Loading />
      ) : (
        <Container fluid>
          <div className="mx-4 mt-5">
            <Form>
              {/*  Escrevivel + BoardID + ID */}
              <Row>
                <Col className="mb-3" lg={3} sm={6}>
                  <Form.Group controlId="formGridSensorStateModifier">
                    <Form.Label>Modificador do Estado do Sensor?</Form.Label>
                    <Form.Check
                      label={data.sensorStateModifier ? "Sim" : "Não"}
                      type="switch"
                      checked={data.sensorStateModifier}
                      disabled={!auth.loginDataContext.master}
                      onChange={() =>
                        setData({
                          ...data,
                          sensorStateModifier: !data.sensorStateModifier,
                        })
                      }
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={3} sm={6}>
                  <Form.Group controlId="formGridUserEditable">
                    <Form.Label>Editável pelo Usuário?</Form.Label>
                    <Form.Check
                      label={data.userEditable ? "Sim" : "Não"}
                      type="switch"
                      checked={data.userEditable}
                      disabled={!auth.loginDataContext.master}
                      onChange={() =>
                        setData({
                          ...data,
                          userEditable: !data.userEditable,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className="mb-3" lg={idVariable !== "|new|" ? 6 : 12}>
                  <Form.Group controlId="formGridIdBoard">
                    <Form.Label>Placa ID:</Form.Label>
                    <Form.Control type="text" value={idBoard} disabled />
                  </Form.Group>
                </Col>

                {idVariable !== "|new|" && (
                  <Col className="mb-3" lg={6}>
                    <Form.Group controlId="formGridId">
                      <Form.Label>ID:</Form.Label>
                      <Form.Control
                        type="text"
                        value={new DataFunctions().getId(data)}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>

              {/* Nome + Tipo */}
              <Row>
                <Col className="mb-3">
                  <Form.Group controlId="formGridName">
                    <Form.Label>Nome da Variável:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.name}
                      onChange={(e) => {
                        setData({
                          ...data,
                          name: e.target.value.toUpperCase(),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3">
                  <Form.Group controlId="formGridType">
                    <Form.Label>Tipo:</Form.Label>
                    <SelectItem
                      options={TYPES}
                      values={TYPES.filter((item) => data.type === item.name)}
                      isMulti={false}
                      onChange={(e) => {
                        var value: any = "";
                        if (e[0].name.toLowerCase() === "rgb") {
                          value = [0, 0, 0, 100];
                        }

                        setData({ ...data, type: e[0].name, value });
                      }}
                      type="tipo"
                    />
                  </Form.Group>
                </Col>

                {data.type !== "" && (
                  <>
                    <Col className="mb-3">
                      <Form.Group controlId="formGridValue">
                        <Form.Label>
                          Valor{data.type === "array" ? "es" : ""}:
                        </Form.Label>
                        {data.type.toLowerCase() === "boolean" ? (
                          <Form.Check
                            label={data.value ? "Ligado" : "Desligado"}
                            type="switch"
                            checked={Boolean(data.value)}
                            onChange={() =>
                              setData({ ...data, value: !data.value })
                            }
                          />
                        ) : data.type === "string" ? (
                          <Form.Control
                            type={"text"}
                            value={data.value.toString()}
                            onChange={(e) => {
                              setData({
                                ...data,
                                value: e.target.value,
                              });
                            }}
                          />
                        ) : data.type === "number" ? (
                          <Form.Control
                            type="number"
                            value={parseFloat(data.value.toString())}
                            onChange={(e) => {
                              setData({
                                ...data,
                                value: e.target.value,
                              });
                            }}
                          />
                        ) : data.type === "rgb" ? (
                          <Form.Control
                            style={{
                              backgroundColor:
                                data.value === ""
                                  ? "#000"
                                  : `#${rgbHex(
                                      data.value[0],
                                      data.value[1],
                                      data.value[2]
                                    )}`,
                            }}
                            type="color"
                            value={getColor()}
                            onChange={(e) => {
                              var value = data.value;
                              value[0] = hexRgb(e.target.value).red;
                              value[1] = hexRgb(e.target.value).green;
                              value[2] = hexRgb(e.target.value).blue;

                              setData({
                                ...data,
                                value,
                              });
                            }}
                          />
                        ) : (
                          <CreatableSelect
                            isMulti
                            placeholder="Valores do array..."
                            options={
                              data.value === ""
                                ? []
                                : new DataFunctions().getResume(data.value)
                            }
                            onChange={(e) => {
                              const value = e.map((item) => {
                                const i: any = item;
                                return i.value;
                              });
                              setData({ ...data, value });
                            }}
                          />
                        )}
                      </Form.Group>
                    </Col>

                    {data.type === "rgb" && (
                      <>
                        <Col>
                          <Form.Label>Red:</Form.Label>
                          <Form.Control
                            type="number"
                            min={0}
                            max={255}
                            value={
                              data.value === "" ? 0 : parseInt(data.value[0])
                            }
                            onChange={(e) => {
                              const value = data.value;
                              var color = parseInt(e.target.value);
                              if (color > 255) color = 255;
                              if (color < 0) color = 0;
                              value[0] = color;

                              setData({
                                ...data,
                                value,
                              });
                            }}
                          />
                        </Col>

                        <Col>
                          <Form.Label>Green:</Form.Label>
                          <Form.Control
                            type="number"
                            min={0}
                            max={255}
                            value={
                              data.value === "" ? 0 : parseInt(data.value[1])
                            }
                            onChange={(e) => {
                              const value = data.value;
                              var color = parseInt(e.target.value);
                              if (color > 255) color = 255;
                              if (color < 0) color = 0;
                              value[1] = color;

                              setData({
                                ...data,
                                value,
                              });
                            }}
                          />
                        </Col>

                        <Col>
                          <Form.Label>Blue:</Form.Label>
                          <Form.Control
                            type="number"
                            min={0}
                            max={255}
                            value={
                              data.value === "" ? 0 : parseInt(data.value[2])
                            }
                            onChange={(e) => {
                              const value = data.value;
                              var color = parseInt(e.target.value);
                              if (color > 255) color = 255;
                              if (color < 0) color = 0;
                              value[2] = color;

                              setData({
                                ...data,
                                value,
                              });
                            }}
                          />
                        </Col>

                        <Col>
                          <Form.Label>Brilho ({data.value[3]}):</Form.Label>
                          <Form.Range
                            value={data.value === "" ? 100 : data.value[3]}
                            min={1}
                            max={100}
                            onChange={(e) => {
                              const value = data.value;
                              var bright = parseInt(e.target.value);
                              if (bright > 100) bright = 100;
                              if (bright < 1) bright = 1;
                              value[3] = bright;

                              setData({
                                ...data,
                                value,
                              });
                            }}
                          />
                        </Col>
                      </>
                    )}
                  </>
                )}
              </Row>

              {/* Sensor */}
              <Row>
                <Col className="mb-3">
                  <Form.Group controlId="formGridSensor">
                    <Form.Label>Sensor Relacionado:</Form.Label>
                    <SelectItem
                      isClearable={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          sensor: sensors.find(
                            (item) =>
                              new DataFunctions().getId(item) === e[0].key
                          ),
                        });
                      }}
                      options={new DataFunctions().getResume(sensors)}
                      type="tipo de sensor"
                      values={
                        data.sensor
                          ? new DataFunctions().getResume([data.sensor])
                          : []
                      }
                      isMulti={false}
                    />
                  </Form.Group>
                </Col>

                {data.sensor && (
                  <>
                    <Col className="mb-3">
                      <Form.Group controlId="formGridPin">
                        <Form.Label>Pino:</Form.Label>
                        <Form.Control
                          type="text"
                          value={data.sensor.pin}
                          disabled
                        />
                      </Form.Group>
                    </Col>

                    <Col className="mb-3">
                      <Form.Group controlId="formGridPlace">
                        <Form.Label>Local de Instalação:</Form.Label>
                        <Form.Control
                          type="text"
                          value={data.sensor.place}
                          disabled
                        />
                      </Form.Group>
                    </Col>

                    <Col className="mb-3">
                      <Form.Group controlId="formGridSensorDescr">
                        <Form.Label>Descrição:</Form.Label>
                        <Form.Control
                          type="text"
                          value={data.sensor.descr}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>

              {/* Descrição */}
              <Row>
                <Col className="mb-3" lg={12}>
                  <Form.Group controlId="formGridDescr">
                    <Form.Label>Descrição:</Form.Label>
                    <Form.Control
                      type="text"
                      value={!data.descr ? "" : data.descr.toUpperCase()}
                      onChange={(e) => {
                        setData({ ...data, descr: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Botões */}
              {auth.loginDataContext.master && (
                <Row className="mb-3">
                  {idVariable !== "|new|" && (
                    <Col className="text-start">
                      <Button
                        variant="danger"
                        onClick={(e) => {
                          setShowModalDelete(true);
                        }}
                      >
                        Excluir
                      </Button>
                    </Col>
                  )}
                  <Col className="text-end">
                    <Button
                      variant="success"
                      onClick={(e) => {
                        save();
                      }}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </div>

          {errors && (
            <AlertMessage messages={errors} setMessages={(e) => setErrors(e)} />
          )}
        </Container>
      )}
    </div>
  );
};

export default VarialePage;
