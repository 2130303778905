import { TbFileOff } from "react-icons/tb";

interface IProps {
  centeredOnComponent?: boolean;
  color?: string;
  size?: number;
}

const NoData: React.FC<IProps> = ({
  centeredOnComponent,
  color = "#68b19c",
  size,
}) => {
  return (
    <div className="center__box__component no__data">
      <TbFileOff size={size} />
      Sem Dados
    </div>
  );
};

export default NoData;
