import {
  ClipLoader,
  FadeLoader,
  GridLoader,
  PuffLoader,
  PulseLoader,
  ScaleLoader,
  SyncLoader,
} from "react-spinners";

type TypeLoader =
  | "PuffLoader"
  | "GridLoader"
  | "ClipLoader"
  | "PulseLoader"
  | "SyncLoader"
  | "ScaleLoader"
  | "FadeLoader";
interface IProps {
  typeLoader?: TypeLoader;
  centeredOnComponent?: boolean;
  heightComponent?: number;
  color?: string;
  size?: number;
}

const Loading: React.FC<IProps> = ({
  centeredOnComponent,
  heightComponent,
  typeLoader,
  color = "#68b19c",
  size,
}) => {
  const SIZE = size ? size : 30;

  const getLoader = () => {
    switch (typeLoader) {
      case "ClipLoader":
        return (
          <ClipLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );

      case "FadeLoader":
        return (
          <FadeLoader
            color={color}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );

      case "GridLoader":
        return (
          <GridLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );

      case "PuffLoader":
        return (
          <PuffLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );

      case "PulseLoader":
        return (
          <PulseLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );

      case "ScaleLoader":
        return (
          <ScaleLoader
            color={color}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );

      case "SyncLoader":
        return (
          <SyncLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );

      default:
        return (
          <GridLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
    }
  };

  return (
    <div
      style={{ height: heightComponent ? heightComponent * 0.8 : undefined }}
      className={`${
        centeredOnComponent ? "center__box__component" : "center__box"
      }`}
    >
      {getLoader()}
    </div>
  );
};

export default Loading;
