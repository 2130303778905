import pt from "apexcharts/dist/locales/pt-br.json";
import { unitOfTime } from "moment";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Col, Form, FormLabel, Row } from "react-bootstrap";
import { IBoard } from "../../../../../../../libs/types/boards";
import {
  IDashboardView,
  IDashboardViewData,
  IRangeDates,
} from "../../../../../../../libs/types/dashboards";
import { IProject } from "../../../../../../../libs/types/projects";
import {
  ISensor,
  ISensorValues,
} from "../../../../../../../libs/types/sensors";
import { ISettingsSensorTypes } from "../../../../../../../libs/types/settings";
import DataFunctions from "../../../../functions/database";
import SettingsSensorsTypesAPI from "../../../../services/api/settings/sensors/types";
import moment from "../../../../utils/moment";
import SelectItem from "../../../dataView/selectItem";
import NoData from "../../../noData";
import "../../styles.css";

//TODO - rever código, atualização de dados, range de datas, filtros... etc

const RANGE_DATES: IRangeDates[] = [
  {
    index: 0,
    label: "5 MINUTOS",
    unit: "minutes",
    value: 5,
  },
  {
    index: 1,
    label: "10 MINUTOS",
    unit: "minutes",
    value: 10,
  },
  {
    index: 2,
    label: "30 MINUTOS",
    unit: "minutes",
    value: 30,
  },
  {
    index: 3,
    label: "1 HORA",
    unit: "hours",
    value: 1,
  },
  {
    index: 4,
    label: "6 HORAS",
    unit: "hours",
    value: 6,
  },
  {
    index: 5,
    label: "12 HORAS",
    unit: "hours",
    value: 12,
  },
  {
    index: 6,
    label: "1 DIA",
    unit: "day",
    value: 1,
  },
  {
    index: 7,
    label: "5 DIAS",
    unit: "days",
    value: 5,
  },
  {
    index: 8,
    label: "1 MÊS",
    unit: "month",
    value: 1,
  },
  {
    index: 9,
    label: "6 MESES",
    unit: "months",
    value: 6,
  },
  {
    index: 10,
    label: "1 ANO",
    unit: "year",
    value: 1,
  },
  {
    index: 11,
    label: "2 ANOS",
    unit: "years",
    value: 2,
  },

  {
    index: 12,
    label: "3 ANOS",
    unit: "years",
    value: 3,
  },
  {
    index: 13,
    label: "4 ANOS",
    unit: "years",
    value: 4,
  },
  {
    index: 14,
    label: "5 ANOS",
    unit: "years",
    value: 5,
  },
];
interface IProps {
  viewMaster: boolean;
  idUser: string;
  item: IDashboardView;
  onUpdateItem: (e: IDashboardView) => void;
  useProjects: IProject[];
  useBoards: IBoard[];
  useSensors: ISensor[];
  useSensorValues: ISensorValues[];
}

const INIT_VALUES = {
  projects: [],
  boards: [],
  sensors: [],
  sensorTypes: undefined,
  showLabels: false,
  showSmall: false,
  range: RANGE_DATES[0],
  settings: {
    backgroundColor: "#ffffff",
    textColor: "#000000",
    linesCharColor: [],
  },
};

const DashValues: React.FC<IProps> = ({
  viewMaster,
  idUser,
  item,
  onUpdateItem,
  useProjects,
  useBoards,
  useSensors,
  useSensorValues,
}) => {
  const [data, setData] = useState<IDashboardViewData>(
    item.data ? item.data : INIT_VALUES
  );

  const [sensorTypes, setSensorTypes] = useState<ISettingsSensorTypes[]>([]);

  const [projects, setProjects] = useState<IProject[]>([]);
  const [boards, setBoards] = useState<IBoard[]>([]);
  const [sensors, setSensors] = useState<ISensor[]>([]);
  const [values, setValues] = useState<ISensorValues[]>([]);

  useEffect(() => {
    const loadSensorTypes = async () => {
      const result = await new SettingsSensorsTypesAPI().list();
      if (result.success) setSensorTypes(result.data);
    };
    loadSensorTypes();
  }, []);

  useEffect(() => {
    onUpdateItem({ ...item, data: data });
  }, [data]);

  useEffect(() => {
    if (viewMaster) {
      setProjects(useProjects);
    } else {
      setProjects(
        useProjects.filter((val) => new DataFunctions().getId(val) === idUser)
      );
    }
  }, [useProjects, viewMaster]);

  useEffect(() => {
    const selected = data.projects.map((item) =>
      new DataFunctions().getId(item)
    );
    setBoards(
      useBoards.filter((item) =>
        selected.includes(new DataFunctions().getId(item.project))
      )
    );
  }, [data.projects, useBoards]);

  useEffect(() => {
    const selectedBoards = data.boards.map((item) =>
      new DataFunctions().getId(item)
    );

    const current_sensor = data.sensorTypes;
    if (current_sensor) {
      const sensorFilter = useSensors
        .filter((item) =>
          selectedBoards.includes(new DataFunctions().getId(item.board))
        )
        .filter(
          (item) =>
            new DataFunctions().getId(item.type) ===
            new DataFunctions().getId(current_sensor)
        );

      setSensors(sensorFilter);
    }
  }, [data.boards, useSensors]);

  useEffect(() => {
    var newValues = useSensorValues;
    var unitMoment: unitOfTime.DurationConstructor = "minutes";

    const selected = data.sensors.map((item) =>
      new DataFunctions().getId(item)
    );

    newValues = newValues.filter((item) =>
      selected.includes(new DataFunctions().getId(item.sensor))
    );

    switch (data.range.unit) {
      case "minutes":
        unitMoment = "minutes";
        break;

      case "hours":
        unitMoment = "hours";
        break;

      case "day":
        unitMoment = "day";
        break;

      case "days":
        unitMoment = "days";
        break;

      case "month":
        unitMoment = "month";
        break;

      case "months":
        unitMoment = "months";
        break;

      case "year":
        unitMoment = "year";
        break;

      case "years":
        unitMoment = "years";
        break;
    }

    newValues = newValues.filter((item) => {
      const limitDate = moment().subtract(data.range.value, unitMoment);
      return moment(item.createdAt).isAfter(limitDate);
    });

    if (data.sensorReturn) {
      const returnType = data.sensorReturn;
      if (returnType.shortUnitMeasure === "") {
        newValues = newValues.filter(
          (item) => item.unit === "" || item.unit === undefined
        );
      } else {
        newValues = newValues.filter(
          (item) => item.unit === returnType.shortUnitMeasure
        );
      }
    }

    setValues(newValues);
  }, [data.sensors, useSensorValues, data.sensorReturn, data.range]);

  const getCurve = () => {
    if (data.sensorReturn) {
      if (data.sensorReturn.shortUnitMeasure === "") {
        return "stepline";
      } else {
        return "smooth";
      }
    }

    return undefined;
  };

  const loadSeries = (): ApexAxisChartSeries | ApexNonAxisChartSeries => {
    const newSerie: ApexAxisChartSeries = [];

    data.sensors.map((sensor) => {
      const valFilter = values.filter(
        (val) =>
          new DataFunctions().getId(val.sensor) ===
          new DataFunctions().getId(sensor)
      );

      newSerie.push({
        name: `${sensor.name} - ${sensor.place.toUpperCase()}`,
        data: valFilter.map((val) => {
          return { x: val.createdAt, y: val.val };
        }),
      });
    });

    return newSerie;
  };

  return (
    <div>
      <Row>
        <Col>
          <Form>
            <Row>
              <Col
                lg={5}
                md={5}
                className={data.showSmall ? "small__text" : ""}
              >
                <FormLabel>
                  Período <strong>({data.range.label})</strong>:
                </FormLabel>
                <Form.Range
                  min={0}
                  max={RANGE_DATES.length - 1}
                  step={1}
                  value={data.range.index}
                  onChange={(e) =>
                    setData({
                      ...data,
                      range: RANGE_DATES[parseInt(e.target.value)],
                    })
                  }
                />
              </Col>

              <Col className={data.showSmall ? "small__text" : ""}>
                <FormLabel>Opções:</FormLabel>

                <Row>
                  <Col>
                    <Form.Check
                      inline
                      label="Mostrar valores"
                      name={`show-values`}
                      id={`show-values`}
                      type="checkbox"
                      checked={data.showLabels}
                      onChange={(e) => {
                        setData({
                          ...data,
                          showLabels: !data.showLabels,
                        });
                      }}
                    />
                  </Col>

                  <Col>
                    <Form.Check
                      inline
                      label="Reduzir Controles"
                      name={`small-controls`}
                      id={`small-controls`}
                      type="checkbox"
                      checked={data.showSmall}
                      onChange={(e) => {
                        setData({
                          ...data,
                          showSmall: !data.showSmall,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Row>
        <Col className={data.showSmall ? "small__text" : ""}>
          <span>Tipos de Sensores:</span>
          <SelectItem
            smallSize={data.showSmall}
            onChange={(e) => {
              setData({
                ...data,
                sensorTypes: sensorTypes.find(
                  (i) => new DataFunctions().getId(i) === e[0].key
                ),
                sensorReturn: undefined,
                projects: [],
                boards: [],
                sensors: [],
              });
            }}
            options={new DataFunctions().getResume(sensorTypes)}
            type="retorno de sensores"
            values={
              data.sensorTypes
                ? new DataFunctions().getResume([data.sensorTypes])
                : []
            }
            isMulti={false}
          />
        </Col>

        <Col className={data.showSmall ? "small__text" : ""}>
          <span>Filtro de Retornos:</span>
          <SelectItem
            smallSize={data.showSmall}
            onChange={(e) => {
              const sensorReturn = data.sensorTypes?.returns.find(
                (item) => new DataFunctions().getId(item) === e[0].key
              );
              setData({
                ...data,
                sensorReturn: sensorReturn,
              });
            }}
            options={
              data.sensorTypes
                ? data.sensorTypes.returns.map((types) => {
                    return {
                      key: new DataFunctions().getId(types),
                      name: `${types.name} [${types.shortUnitMeasure}]`,
                    };
                  })
                : []
            }
            type="retorno de sensores"
            values={
              data.sensorReturn
                ? [
                    {
                      key: new DataFunctions().getId(data.sensorReturn),
                      name: `${data.sensorReturn.name} [${data.sensorReturn.shortUnitMeasure}]`,
                    },
                  ]
                : []
            }
            isMulti={false}
            isClearable={true}
          />
        </Col>
      </Row>

      {data.sensorTypes && (
        <Row>
          <Col className={data.showSmall ? "small__text" : ""}>
            <span>Projetos:</span>
            <SelectItem
              smallSize={data.showSmall}
              onChange={(e) => {
                const current: IProject[] = [];

                e.map((item) => {
                  const new_data = projects.find(
                    (i) => new DataFunctions().getId(i) === item.key
                  );
                  if (new_data) current.push(new_data);
                });

                setData({
                  ...data,
                  projects: current,
                  boards: [],
                  sensors: [],
                });
              }}
              options={new DataFunctions().getResume(projects)}
              type="projeto(s)"
              values={new DataFunctions().getResume(data.projects)}
              isMulti={true}
            />
          </Col>

          <Col className={data.showSmall ? "small__text" : ""}>
            <span>Placas:</span>
            <SelectItem
              smallSize={data.showSmall}
              disable={data.projects.length === 0 ? true : false}
              onChange={(e) => {
                const current: IBoard[] = [];

                e.map((item) => {
                  const new_data = boards.find(
                    (i) => new DataFunctions().getId(i) === item.key
                  );
                  if (new_data) current.push(new_data);
                });

                setData({
                  ...data,
                  boards: current,
                  sensors: [],
                });
              }}
              options={new DataFunctions().getResume(boards)}
              type="placa(s)"
              femalePronoun={true}
              values={new DataFunctions().getResume(data.boards)}
              isMulti={true}
            />
          </Col>

          <Col className={data.showSmall ? "small__text" : ""}>
            <span>Sensores:</span>
            <SelectItem
              smallSize={data.showSmall}
              disable={data.boards.length === 0 ? true : false}
              onChange={(e) => {
                const current: ISensor[] = [];

                e.map((item) => {
                  const new_data = sensors.find(
                    (i) => new DataFunctions().getId(i) === item.key
                  );
                  if (new_data) current.push(new_data);
                });

                setData({
                  ...data,
                  sensors: current,
                });
              }}
              options={new DataFunctions().getResume(sensors)}
              type="sensor(es)"
              values={new DataFunctions().getResume(data.sensors)}
              isMulti={true}
            />
          </Col>
        </Row>
      )}

      <Row className="my-3">
        {data.sensors.length === 0 ||
        data.sensorReturn === undefined ? null : values.length > 0 ? (
          <Chart
            height={250}
            options={{
              chart: {
                type: "line",
                locales: [pt],
                defaultLocale: "pt-br",
              },
              tooltip: {
                x: {
                  format: "dd/MMM HH:mm:ss",
                },
              },
              legend: {
                labels: {
                  colors: item.settings.textColor,
                },
              },
              theme: {
                mode: item.settings.chartDarkTheme ? "dark" : "light",
              },
              stroke: {
                curve: getCurve(),
                width: 3,
              },
              dataLabels: {
                enabled: data.showLabels,
              },
              xaxis: {
                type: "datetime",
                labels: {
                  datetimeUTC: false,
                  datetimeFormatter: {
                    year: "yyyy",
                    month: "MMM/yy",
                    day: "dd MMM",
                    hour: "HH:mm",
                  },
                },
              },
              yaxis: {
                labels: {
                  formatter: (value) => {
                    return `${value} ${data.sensorReturn?.shortUnitMeasure}`;
                  },
                },
              },
              colors: item.settings.linesCharColor
                ? item.settings.linesCharColor
                : undefined,
              subtitle: {
                style: {
                  color: "blue",
                },
              },
            }}
            series={loadSeries()}
          />
        ) : (
          <NoData size={50} />
        )}
      </Row>
    </div>
  );
};

export default DashValues;
