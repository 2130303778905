import API from "..";
import { IMQTTPublish } from "../../../../../../libs/types/mqtt";
import { IReturnHTTP } from "../../../../../../libs/types/returns";

export default class MqttAPI extends API {
  private path: string = "mqtt";

  publish = async (id: string, data: IMQTTPublish): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/publish/${id}`;

    try {
      this.result = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
        body: JSON.stringify(data),
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };
}
