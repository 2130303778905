import { FaRandom } from "react-icons/fa";
import { MdDashboardCustomize } from "react-icons/md";
import { TbTool } from "react-icons/tb";
import { MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";

const ToolsValuesGeneratorMenu = () => {
  return (
    <SubMenu label="Ferramentas" icon={<TbTool />}>
      <Link to={`/auth/tools/values-generator`}>
        <MenuItem icon={<FaRandom />}>Gerador de Valroes</MenuItem>
      </Link>

      <Link to={`/auth/tools/create-dashboard`}>
        <MenuItem icon={<MdDashboardCustomize />}>Criar Dashboard</MenuItem>
      </Link>
    </SubMenu>
  );
};

export default ToolsValuesGeneratorMenu;
