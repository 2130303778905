import { useEffect, useState } from "react";
import { ISensorValues } from "../../../../libs/types/sensors";
import {
  ISocketReturn,
  TypeSockectEvent,
} from "../../../../libs/types/sockets";
import { useLoginContext } from "../context";
import DataFunctions from "../functions/database";
import SensorValuesAPI from "../services/api/sensorValues";
import { socket } from "../services/socket";

const room: TypeSockectEvent = "sensor_values_update";

const useSensorValuesSocket = () => {
  const [data, setData] = useState<ISensorValues[]>([]);
  let auth = useLoginContext();

  useEffect(() => {
    getBase();
  }, []);

  useEffect(() => {
    if (socket && socket.connected) {
      socket.on(room, (io: ISocketReturn) => {
        //console.log(room, io);

        const ioData: ISensorValues = io.data;
        const index = data.findIndex(
          (item) =>
            new DataFunctions().getId(item) ===
            new DataFunctions().getId(ioData)
        );

        var current_data: ISensorValues[] = [...data];

        switch (io.socketReturn) {
          case "add":
            current_data.push(ioData);
            break;

          case "update":
            if (index > -1) current_data[index] = ioData;
            break;

          case "delete":
            current_data = data.filter(
              (item) =>
                new DataFunctions().getId(item) !==
                new DataFunctions().getId(ioData)
            );
            break;
        }

        setData(current_data);
      });
    }
  }, [socket, data]);

  const getBase = async () => {
    if (auth.loginDataContext.master) {
      new SensorValuesAPI().list().then(async (res) => {
        if (res.success) await setData(res.data);
      });
    } else {
      new SensorValuesAPI()
        .listByUser(auth.loginDataContext.id ? auth.loginDataContext.id : "")
        .then(async (res) => {
          if (res.success) await setData(res.data);
        });
    }
  };

  return data;
};

export default useSensorValuesSocket;
