import { useState } from "react";
import {
  FaEdit,
  FaPlusCircle,
  FaRegEye,
  FaRegFolder,
  FaRegFolderOpen,
} from "react-icons/fa";
import { MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useLoginContext } from "../../../../context";
import DataFunctions from "../../../../functions/database";
import StringFunctions from "../../../../functions/strings";
import useProjectsSocket from "../../../../hooks/useProjectsSocket";
import BoardsMenu from "../boards";

const ProjectsMenu = () => {
  const auth = useLoginContext();
  const projects = useProjectsSocket();

  const [open, setOpen] = useState(false);

  return (
    <div>
      {auth.loginDataContext.master && (
        <Link to={`/auth/project/|new|`}>
          <MenuItem icon={<FaPlusCircle />}>Adicionar Projeto</MenuItem>
        </Link>
      )}

      {projects.map((item) => {
        return (
          <SubMenu
            key={new DataFunctions().getId(item)}
            label={new StringFunctions(item.name).cammelCase()}
            icon={open ? <FaRegFolderOpen /> : <FaRegFolder />}
            onOpenChange={setOpen}
            open={open}
          >
            <Link to={`/auth/project/${new DataFunctions().getId(item)}`}>
              <MenuItem
                icon={auth.loginDataContext.master ? <FaEdit /> : <FaRegEye />}
              >
                {auth.loginDataContext.master ? "Editar" : "Visualizar"} Projeto
              </MenuItem>
            </Link>

            <BoardsMenu idProject={new DataFunctions().getId(item)} />
          </SubMenu>
        );
      })}
    </div>
  );
};

export default ProjectsMenu;
