import { AiOutlineUnorderedList } from "react-icons/ai";
import { FaEdit, FaPlusCircle, FaRegEye } from "react-icons/fa";
import { TbVariable } from "react-icons/tb";
import { MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useLoginContext } from "../../../../context";
import DataFunctions from "../../../../functions/database";
import useVariables from "../../../../hooks/useVariablesSocket";

interface IProps {
  idProject: string;
  idBoard: string;
}

const VariablesMenu: React.FC<IProps> = ({ idProject, idBoard }) => {
  const auth = useLoginContext();
  const variables = useVariables();

  return (
    <div>
      {auth.loginDataContext.master && (
        <Link to={`/auth/project/${idProject}/board/${idBoard}/variable/|new|`}>
          <MenuItem icon={<FaPlusCircle />}>Adicionar Variável</MenuItem>
        </Link>
      )}

      {variables.length > 0 && (
        <SubMenu label="Variáveis" icon={<TbVariable />}>
          <Link
            to={`/auth/project/${idProject}/board/${idBoard}/variable/list`}
          >
            <MenuItem icon={<AiOutlineUnorderedList />}>
              Visualizar Sensores
            </MenuItem>
          </Link>

          {variables.map((item) => {
            if (new DataFunctions().getId(item.board) === idBoard) {
              return (
                <Link
                  key={new DataFunctions().getId(item)}
                  to={`/auth/project/${idProject}/board/${idBoard}/variable/${new DataFunctions().getId(
                    item
                  )}`}
                >
                  <MenuItem
                    icon={
                      auth.loginDataContext.master ? <FaEdit /> : <FaRegEye />
                    }
                  >
                    {item.name}
                  </MenuItem>
                </Link>
              );
            }
          })}
        </SubMenu>
      )}
    </div>
  );
};

export default VariablesMenu;
