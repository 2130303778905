import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { IDashboard } from "../../../../../../../libs/types/dashboards";
import { IAlertMessage } from "../../../../../../../libs/types/messages";
import { IUser } from "../../../../../../../libs/types/users";
import AlertMessage from "../../../../components/alertMessage";
import SelectItem from "../../../../components/dataView/selectItem";
import Loading from "../../../../components/loading";
import DataFunctions from "../../../../functions/database";
import useUsersSocket from "../../../../hooks/useUsersSocket";
import DashboardsAPI from "../../../../services/api/dashboards";
import moment from "../../../../utils/moment";
import GenerateError from "../../../../validations/errors";

const CreateDashboardPage = () => {
  const users = useUsersSocket();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IAlertMessage[]>([]);

  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [dashboard, setDashboard] = useState<IDashboard>();

  useEffect(() => {
    loadDashboard();
  }, [selectedUser]);

  const loadDashboard = async () => {
    if (selectedUser) {
      const result = await new DashboardsAPI().listByUser(
        new DataFunctions().getId(selectedUser)
      );
      if (result.success) setDashboard(result.data);
    }
  };

  const add = async () => {
    setLoading(true);

    try {
      if (!selectedUser) {
        throw new GenerateError("Selecione o usuário.");
      }

      const data: IDashboard = {
        createdAt: new Date(),
        dashboardView: [],
        user: selectedUser,
        viewList: true,
      };

      const result = await new DashboardsAPI().create([data]);

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          variant: "success",
          header: "Concluído",
          message: "Dashboard criado com sucesso!",
        },
      ]);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      setLoading(false);
      loadDashboard();
    }
  };

  const del = async () => {
    setLoading(true);

    try {
      if (!dashboard) {
        throw new GenerateError("Usuário sem dashboard.");
      }
      const result = await new DashboardsAPI().delete(
        new DataFunctions().getId(dashboard)
      );

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          variant: "success",
          header: "Concluído",
          message: "Dashboard excluído com sucesso!",
        },
      ]);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      setLoading(false);
      loadDashboard();
    }
  };

  return (
    <div>
      <h1>Criar Dashboard</h1>

      <Container fluid>
        <div className="mx-4 mt-5">
          <div>
            <Form>
              <div className="mt-3">
                <Row>
                  <Form.Label lg={3}>Selecione um Usuário:</Form.Label>
                  <Col>
                    <SelectItem
                      onChange={(e) => {
                        setSelectedUser(
                          users.find(
                            (item) =>
                              new DataFunctions().getId(item) === e[0].key
                          )
                        );
                      }}
                      options={new DataFunctions().getResume(users)}
                      values={
                        selectedUser
                          ? new DataFunctions().getResume([selectedUser])
                          : []
                      }
                      type="usuário"
                      isMulti={false}
                      disable={loading}
                    />
                  </Col>
                </Row>
              </div>

              {selectedUser && (
                <div className="mt-4">
                  {dashboard && (
                    <div>
                      <h4>{dashboard ? "Existente" : "Novo"}:</h4>
                      <Row className="my-3 mx-5">
                        <Form.Group
                          as={Row}
                          className="my-1"
                          controlId="formPlaintextValueDate"
                        >
                          <Form.Label column lg={3}>
                            Id:
                          </Form.Label>
                          <Col>
                            <Form.Control
                              type="text"
                              value={new DataFunctions().getId(dashboard)}
                              readOnly
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="my-1"
                          controlId="formPlaintextValueDate"
                        >
                          <Form.Label column lg={3}>
                            ViewList:
                          </Form.Label>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              checked={dashboard.viewList}
                              readOnly
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="my-1"
                          controlId="formPlaintextValueDate"
                        >
                          <Form.Label column lg={3}>
                            Dashviews:
                          </Form.Label>
                          <Col>
                            <Form.Control
                              type="text"
                              value={dashboard.dashboardView.length}
                              readOnly
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="my-1"
                          controlId="formPlaintextValueDate"
                        >
                          <Form.Label column lg={3}>
                            Data:
                          </Form.Label>
                          <Col>
                            <Form.Control
                              type="text"
                              value={moment().format(
                                "DD/MM/YYYY [às] HH:mm:ss"
                              )}
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Row>
                    </div>
                  )}

                  {dashboard ? (
                    <Row className="my-3">
                      <Button
                        variant="outline-danger"
                        className="mb-3"
                        onClick={del}
                      >
                        Excluir Dashboard de{" "}
                        <strong>{selectedUser.name}</strong>
                      </Button>
                    </Row>
                  ) : (
                    <Row className="my-3">
                      <Button
                        variant="outline-dark"
                        className="mb-3"
                        onClick={add}
                        disabled={loading}
                      >
                        Criar Dashboard para{" "}
                        <strong>{selectedUser.name}</strong>
                      </Button>
                    </Row>
                  )}
                </div>
              )}
            </Form>
          </div>

          {loading && <Loading />}

          {errors && (
            <AlertMessage messages={errors} setMessages={(e) => setErrors(e)} />
          )}
        </div>
      </Container>
    </div>
  );
};

export default CreateDashboardPage;
