import { FaEdit, FaPlusCircle, FaRegEye } from "react-icons/fa";
import { VscCircuitBoard } from "react-icons/vsc";
import { MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useLoginContext } from "../../../../context";
import DataFunctions from "../../../../functions/database";
import StringFunctions from "../../../../functions/strings";
import useBoardsSocket from "../../../../hooks/useBoardsSocket";
import SensorsMenu from "../sensors";
import VariablesMenu from "../variables";

interface IProps {
  idProject: string;
}

const BoardsMenu: React.FC<IProps> = ({ idProject }) => {
  const auth = useLoginContext();
  const boards = useBoardsSocket();

  return (
    <div>
      {auth.loginDataContext.master && (
        <Link to={`/auth/project/${idProject}/board/|new|`}>
          <MenuItem icon={<FaPlusCircle />}>Adicionar Placa</MenuItem>
        </Link>
      )}

      {boards.map((item) => {
        if (new DataFunctions().getId(item.project) === idProject) {
          return (
            <SubMenu
              key={new DataFunctions().getId(item)}
              label={new StringFunctions(item.name).cammelCase()}
              icon={<VscCircuitBoard />}
            >
              <Link
                to={`/auth/project/${idProject}/board/${new DataFunctions().getId(
                  item
                )}`}
              >
                <MenuItem
                  icon={
                    auth.loginDataContext.master ? <FaEdit /> : <FaRegEye />
                  }
                >
                  {auth.loginDataContext.master ? "Editar" : "Visualizar"} Placa
                </MenuItem>
              </Link>

              <SensorsMenu
                idProject={idProject}
                idBoard={new DataFunctions().getId(item)}
              />

              <VariablesMenu
                idProject={idProject}
                idBoard={new DataFunctions().getId(item)}
              />
            </SubMenu>
          );
        }
      })}
    </div>
  );
};

export default BoardsMenu;
