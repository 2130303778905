import { useEffect } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { FaEdit, FaPlusCircle, FaRegEye } from "react-icons/fa";
import { TbComponents } from "react-icons/tb";
import { MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useLoginContext } from "../../../../context";
import DataFunctions from "../../../../functions/database";
import useSensorsSocket from "../../../../hooks/useSensorsSocket";

interface IProps {
  idProject: string;
  idBoard: string;
}

const SensorsMenu: React.FC<IProps> = ({ idProject, idBoard }) => {
  const auth = useLoginContext();
  const sensors = useSensorsSocket();

  useEffect(() => {}, [sensors]);

  return (
    <div>
      {auth.loginDataContext.master && (
        <Link to={`/auth/project/${idProject}/board/${idBoard}/sensor/|new|`}>
          <MenuItem icon={<FaPlusCircle />}>Adicionar Sensor</MenuItem>
        </Link>
      )}

      {sensors.length > 0 && (
        <SubMenu label="Sensores" icon={<TbComponents />}>
          <Link to={`/auth/project/${idProject}/board/${idBoard}/sensor/list`}>
            <MenuItem icon={<AiOutlineUnorderedList />}>
              Visualizar Sensores
            </MenuItem>
          </Link>

          {sensors.map((item) => {
            if (new DataFunctions().getId(item.board) === idBoard) {
              return (
                <Link
                  key={new DataFunctions().getId(item)}
                  to={`/auth/project/${idProject}/board/${idBoard}/sensor/${new DataFunctions().getId(
                    item
                  )}`}
                >
                  <MenuItem
                    icon={
                      auth.loginDataContext.master ? <FaEdit /> : <FaRegEye />
                    }
                  >
                    {item.name}
                  </MenuItem>
                </Link>
              );
            }
          })}
        </SubMenu>
      )}
    </div>
  );
};

export default SensorsMenu;
