import { IAlertMessage } from "../../../../libs/types/messages";
import StringFunctions from "../functions/strings";

class GenerateError extends Error {
  key: string;
  header: string;
  variant: string;

  constructor(
    message: string,
    header: string = "Erro",
    variant: string = "danger",
    key: string = new StringFunctions("").generateCode()
  ) {
    super();
    this.message = message;
    this.header = header;
    this.key = key;
    this.variant = variant;
  }

  returnAlert(): IAlertMessage {
    return {
      message: this.message,
      header: this.header,
      variant: this.variant,
      key: this.key,
    };
  }
}
export default GenerateError;
