import API from "..";
import { IReturnHTTP } from "../../../../../../libs/types/returns";
import { IUser } from "../../../../../../libs/types/users";

export default class UsersAPI extends API {
  private path: string = "users";

  create = async (data: IUser): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/create`;

    try {
      this.result = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ ...data }),
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            console.log("fail", fail);
            return fail.response.data;
          }
        )
        .catch((error) => {
          console.log("error", error);

          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  active = async (user: string, code: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/active?user=${user}&code=${code}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  resendConfirmation = async (email: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/confirmation/resend/${email}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  recoverPassword = async (email: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/password/recover/${email}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  resetPassword = async (
    email: string,
    code: string,
    password: string,
    confirmPassword: string
  ): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/password/reset?email=${email}&code=${code}`;

    try {
      this.result = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ password, confirmPassword }),
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  login = async (user: string, password: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/login`;

    try {
      this.result = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ user, password }),
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  list = async (): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  find = async (
    name?: string,
    email?: string,
    user?: string
  ): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/find?name=${name}&email=${email}&user=${user}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  listByOwner = async (id: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/owner/${id}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  get = async (id: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/${id}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  update = async (id: string, data: IUser): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/${id}`;

    try {
      this.result = await fetch(url, {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
        body: JSON.stringify({ ...data }),
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  delete = async (id: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/${id}`;

    try {
      this.result = await fetch(url, {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  uploadFile = async (id: string, data: FormData): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/upload/${id}`;

    try {
      this.result = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
        body: data,
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  downloadFile = async (id: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/download/${id}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
          "Content-type": "application/json",
        },
      })
        .then(
          async (res) => {
            if (res.ok) {
              const imageBlob = await res.blob();
              this.result.success = true;
              this.result.message = "";
              this.result.data = imageBlob;
              return this.result;
            }
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };
}
