import { v4 as uuid } from "uuid";

export default class StringFunctions {
  val: string;
  newVal: string;

  constructor(val: string) {
    this.val = val;
    this.newVal = "";
  }

  cammelCase = (): string => {
    if (this.val !== null && this.val !== undefined && this.val !== "") {
      var list = this.val.split(/(\s+)/);

      list.map((e) => {
        if (e.trim() !== "") {
          if (this.newVal === "") {
            this.newVal = e.charAt(0).toUpperCase() + e.slice(1);
          } else {
            this.newVal = this.newVal.concat(
              " " + e.charAt(0).toUpperCase() + e.slice(1)
            );
          }
        }

        return true;
      });
    }

    return this.newVal;
  };

  capitalLetter = (): string => {
    if (this.val !== null && this.val !== undefined && this.val !== "") {
      this.newVal = this.val.toLowerCase();
      const arr = this.newVal.split(" ");

      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }

      this.newVal = arr.join(" ");
    }
    return this.newVal;
  };

  firstLetters = (): string => {
    this.val.split(" ").map((w: string) => {
      this.newVal = this.newVal.concat(w.substring(0, 1).toUpperCase());
      return true;
    });

    return this.newVal;
  };

  generateCode = (): string => {
    return uuid().toString();
  };
}
