import { useEffect, useState } from "react";
import { Badge, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { GoDotFill } from "react-icons/go";
import { IUser } from "../../../../../../../libs/types/users";
import DataFunctions from "../../../../functions/database";
import MaskFunctions from "../../../../functions/masks";
import useProjectsSocket from "../../../../hooks/useProjectsSocket";
import useSensorsSocket from "../../../../hooks/useSensorsSocket";
import useUsersSocket from "../../../../hooks/useUsersSocket";
import moment from "../../../../utils/moment";
import NoData from "../../../noData";
import "../../styles.css";

interface IProps {
  viewMaster: boolean;
  idUser: string;
}

const DashUser: React.FC<IProps> = ({ viewMaster, idUser }) => {
  const useProjects = useProjectsSocket();
  const useSensors = useSensorsSocket();
  const useUsers = useUsersSocket();

  const [users, setUsers] = useState<IUser[]>([]);

  useEffect(() => {
    if (viewMaster) {
      setUsers(useUsers);
    } else {
      setUsers(
        useUsers.filter((val) =>
          val.userOwnerId
            ? new DataFunctions().getId(val.userOwnerId) === idUser
            : null
        )
      );
    }
  }, [useUsers, viewMaster]);

  return (
    <div>
      {users.length > 0 ? (
        <Table className="mt-3" striped bordered hover size="sm" variant="dark">
          <thead>
            <tr>
              <th className="text-center ">Nome</th>
              <th className="text-center">Usuário</th>
              <th className="text-center">E-mail</th>
              <th className="text-center">Telefone</th>
              <th className="text-center">Projetos Vinculados</th>
              <th className="text-center">Sensores Vinculados</th>
              <th className="text-center">Última Atualização</th>
            </tr>
          </thead>
          <tbody>
            {users.map((item) => {
              return (
                <tr key={new DataFunctions().getId(item)}>
                  <td className="text-center col-md-1 align-middle">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Usuário{" "}
                          <strong>{item.active ? "Ativo" : "Inativo"}</strong>{" "}
                          {item.owner ? " - (Proprietário)" : ""}
                        </Tooltip>
                      }
                    >
                      <div>
                        {item.owner ? (
                          <>
                            <Badge bg="primary">P</Badge>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {item.name.toUpperCase()}
                        <GoDotFill
                          size={20}
                          color={item.active ? "green" : "red"}
                        />
                      </div>
                    </OverlayTrigger>
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {item.user.toLocaleLowerCase()}
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {item.email.toLocaleLowerCase()}
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {new MaskFunctions().mobileMask(item.phone)}
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {
                      useProjects.filter(
                        (val) =>
                          new DataFunctions().getId(val.users) ===
                          new DataFunctions().getId(item)
                      ).length
                    }
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {
                      useSensors.filter(
                        (val) =>
                          new DataFunctions().getId(val.users) ===
                          new DataFunctions().getId(item)
                      ).length
                    }
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {moment(item.modifiedAt).format("DD/MM/YYYY [às] HH:mm:ss")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <NoData size={50} />
      )}
    </div>
  );
};

export default DashUser;
