import { AiFillSetting } from "react-icons/ai";
import { SubMenu } from "react-pro-sidebar";
import SettingsSensorsMenu from "./sensors";

const SettingsMenu = () => {
  return (
    <SubMenu label="Configurações" icon={<AiFillSetting />}>
      <SettingsSensorsMenu />
    </SubMenu>
  );
};

export default SettingsMenu;
