import { Alert } from "react-bootstrap";
import { IAlertMessage } from "../../../../../libs/types/messages";

interface IProps {
  dismissible?: boolean;
  messages: IAlertMessage[];
  setMessages: (e: IAlertMessage[]) => void;
}

export const AlertMessage: React.FC<IProps> = ({
  messages,
  setMessages,
  dismissible = true,
}) => {
  const removeMessage = (key: string) => {
    const index = messages.findIndex((item) => item.key === key);

    setMessages([
      ...messages.slice(0, index),
      ...messages.slice(index + 1, messages.length),
    ]);
  };

  return (
    <>
      {messages.reverse().map((item) => (
        <div className="ms-5 me-5" key={item.key}>
          <Alert
            variant={item.variant}
            onClick={() => removeMessage(item.key)}
            dismissible={dismissible}
          >
            <Alert.Heading>{item.header}</Alert.Heading>
            <p>{item.message}</p>
          </Alert>
        </div>
      ))}
    </>
  );
};

export default AlertMessage;
