import React, { createContext, useContext, useState } from "react";
import { ILogin } from "../../../../libs/types/users";

type PropsLoginContext = {
  loginDataContext: ILogin;
  setLoginDataContext: React.Dispatch<React.SetStateAction<ILogin>>;
};

interface Props {
  children: React.ReactNode;
}

export const DEFAULT_VALUE_LOGIN = {
  loginDataContext: {
    id: "",
    user: "",
    name: "",
    owner: false,
    email: "",
    master: false,
    logged: false,
    staterAt: "",
    authToken: "",
  },
  setLoginDataContext: () => {},
};

const LoginContext = createContext<PropsLoginContext>(DEFAULT_VALUE_LOGIN);

export const LoginProvider: React.FC<Props> = ({ children }) => {
  const [loginDataContext, setLoginDataContext] = useState<ILogin>(
    DEFAULT_VALUE_LOGIN.loginDataContext
  );

  return (
    <LoginContext.Provider value={{ loginDataContext, setLoginDataContext }}>
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginContext = () => {
  const context = useContext(LoginContext);
  return context;
};
