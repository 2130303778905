import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { IAlertMessage } from "../../../../../../libs/types/messages";
import AlertMessage from "../../../components/alertMessage";
import Loading from "../../../components/loading";
import StringFunctions from "../../../functions/strings";
import UsersAPI from "../../../services/api/users";
import GenerateError from "../../../validations/errors";

const RecoverPage = () => {
  const navigate = useNavigate();
  let { confirmation } = useParams();

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<IAlertMessage[]>([]);
  const [confirmationData, setConfirmationData] = useState<{
    name: string;
    email: string;
    code: string;
  }>({
    code: "",
    email: "",
    name: "",
  });
  const [pwd, setPwd] = useState<{ password: string; confirmPassword: string }>(
    { password: "", confirmPassword: "" }
  );

  useEffect(() => {
    if (confirmation) {
      const [name, email, code] = confirmation.split(";");

      if (!name || !email || !code) {
        setErrors([
          {
            header: "Erro",
            key: uuidv4().toString(),
            variant: "danger",
            message:
              "O link para sua recuperação de conta parece estar corrompido. Tente novamente.",
          },
        ]);
      } else {
        setConfirmationData({ name, email, code });
      }
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reset = async () => {
    setLoading(true);

    try {
      const result = await new UsersAPI().resetPassword(
        confirmationData.email,
        confirmationData.code,
        pwd.password,
        pwd.confirmPassword
      );

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      navigate(
        `/?header=${encodeURIComponent(
          `Senha alterada`
        )}&variant=${encodeURIComponent(
          `success`
        )}&message=${encodeURIComponent(
          `${new StringFunctions(
            confirmationData!.name
          ).capitalLetter()}, agora você já pode se logar com sua nova senha.`
        )}`
      );
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      } else {
        alert(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1 className="text-center">Recuperação de Senha</h1>

      {loading ? (
        <Loading />
      ) : confirmationData ? (
        <div>
          <div className="center__box">
            <Form className="p-4 border border-2 rounded border-white">
              <p>
                Olá, <b>{confirmationData.name.toUpperCase()}</b>, entre com sua
                nova senha.
              </p>

              <Row className="mb-3">
                <Form.Group controlId="formGridPassword">
                  <Form.Label>Nova Senha:</Form.Label>
                  <Form.Control
                    type="password"
                    value={pwd.password}
                    onChange={(e) => {
                      setPwd({ ...pwd, password: e.target.value });
                    }}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group controlId="formGridConfirm">
                  <Form.Label>Confirmar Senha:</Form.Label>
                  <Form.Control
                    type="password"
                    value={pwd.confirmPassword}
                    onChange={(e) => {
                      setPwd({ ...pwd, confirmPassword: e.target.value });
                    }}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Col className="text-start">
                  <Button
                    variant="outline-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/");
                    }}
                  >
                    Voltar
                  </Button>
                </Col>

                <Col className="text-end">
                  <Button
                    variant="outline-success"
                    onClick={(e) => {
                      e.preventDefault();
                      reset();
                    }}
                  >
                    Enviar
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>

          {errors && (
            <AlertMessage messages={errors} setMessages={(e) => setErrors(e)} />
          )}
        </div>
      ) : (
        <div className="center__box">
          <AlertMessage
            messages={errors}
            setMessages={(e) => setErrors(e)}
            dismissible={false}
          />
        </div>
      )}
    </div>
  );
};

export default RecoverPage;
