import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { IDataView } from "../../../../../../libs/types/dataView";

const animatedComponents = makeAnimated();

interface IProps {
  options: IDataView[];
  values: IDataView[];
  onChange: (e: IDataView[]) => void;
  type: string;
  isMulti?: boolean;
  disable?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  femalePronoun?: boolean;
  smallSize?: boolean;
}

const SelectItem: React.FC<IProps> = ({
  options,
  values,
  onChange,
  type,
  isMulti,
  disable,
  isClearable,
  isSearchable = true,
  femalePronoun = false,
  smallSize = false,
}) => {
  return (
    <Select
      styles={
        !smallSize
          ? {
              container: (provided) => ({
                ...provided,
                color: "black",
              }),
            }
          : {
              container: (provided) => ({
                ...provided,
                color: "black",
              }),
              control: (provided) => ({
                ...provided,
                minHeight: "1px",
                fontSize: 10,
              }),
              input: (provided) => ({
                ...provided,
                minHeight: "1px",
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                minHeight: "1px",
                paddingTop: "0",
                paddingBottom: "0",
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                minHeight: "1px",
              }),
              clearIndicator: (provided) => ({
                ...provided,
                minHeight: "1px",
                paddingTop: "0",
                paddingBottom: "0",
              }),
              valueContainer: (provided) => ({
                ...provided,
              }),
              singleValue: (provided) => ({
                ...provided,
              }),
            }
      }
      isClearable={isClearable}
      isSearchable={isSearchable}
      isDisabled={disable}
      placeholder={`Selecione ${femalePronoun ? "a" : "o"}${
        isMulti ? "(s)" : ""
      } ${type}...`}
      components={animatedComponents}
      options={options.map((item) => {
        return { value: item.key, label: item.name };
      })}
      value={values.map((item) => {
        return { value: item.key, label: item.name };
      })}
      isMulti={isMulti}
      onChange={(e, action) => {
        var result: IDataView[] = [];

        if (e !== null) {
          if (isMulti) {
            const items: { value: string; label: string }[] = e as any;
            items.map((item) => {
              result.push({ key: item.value, name: item.label });
              return true;
            });
          } else {
            const items: { value: string; label: string } = e as any;
            result = [{ key: items.value, name: items.label }];
          }
        } else {
          result = [{ key: "", name: "" }];
        }
        onChange(result);
      }}
    />
  );
};

export default SelectItem;
