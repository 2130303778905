import { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { BsFillGridFill, BsList } from "react-icons/bs";
import { IoIosAddCircle, IoIosAddCircleOutline } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";
import {
  IDashboard,
  IDashboardView,
} from "../../../../../../libs/types/dashboards";
import { IDataView } from "../../../../../../libs/types/dataView";
import { IAlertMessage } from "../../../../../../libs/types/messages";
import { IReturnHTTP } from "../../../../../../libs/types/returns";
import AlertMessage from "../../../components/alertMessage";
import DashView from "../../../components/dashboard/dashview";
import SelectItem from "../../../components/dataView/selectItem";
import Loading from "../../../components/loading";
import { useLoginContext } from "../../../context";
import DataFunctions from "../../../functions/database";
import useBoardsSocket from "../../../hooks/useBoardsSocket";
import useDashboardsSocket from "../../../hooks/useDashboardSocket";
import useProjectsSocket from "../../../hooks/useProjectsSocket";
import useSensorValuesSocket from "../../../hooks/useSensorValuesSocket";
import useSensorsSocket from "../../../hooks/useSensorsSocket";
import DashboardsAPI from "../../../services/api/dashboards";
import GenerateError from "../../../validations/errors";

const TYPES: IDataView[] = [
  {
    key: "project",
    name: "Projetos",
  },
  {
    key: "board",
    name: "Placas",
  },
  {
    key: "sensor",
    name: "Sensores",
  },

  {
    key: "values",
    name: "Valores",
  },
  {
    key: "user",
    name: "Usuários",
  },
];

const DEFAULT_DASHVIEW: IDashboardView = {
  index: 0,
  type: "",
  settings: {
    backgroundColor: "#ffffff",
    textColor: "#000000",
    linesCharColor: [],
    chartDarkTheme: false,
  },
};

const DashboardPage = () => {
  let auth = useLoginContext();

  const useProjects = useProjectsSocket();
  const useBoards = useBoardsSocket();
  const useSensors = useSensorsSocket();
  const useSensorValues = useSensorValuesSocket();
  const useDashboards = useDashboardsSocket();

  const idUser = auth.loginDataContext.id ? auth.loginDataContext.id : "";

  const [errors, setErrors] = useState<IAlertMessage[]>([]);
  const [viewMaster, setViewMaster] = useState(auth.loginDataContext.master);
  const [data, setData] = useState<IDashboard>();
  const [dataDashview, setDataDashview] = useState<IDashboardView>();

  const [showModalDashview, setShowModalDashview] = useState(false);

  const [addBtn, setAddBtn] = useState(
    <IoIosAddCircleOutline
      size={40}
      className="icon__action border__eff cursor__pointer"
    />
  );
  const [viewBtn, setViewBtn] = useState(
    <BsFillGridFill
      className="icon__action border__eff cursor__pointer"
      size={40}
    />
  );

  useEffect(() => {
    const result = useDashboards.find(
      (item) =>
        new DataFunctions().getId(item.user) === auth.loginDataContext.id
    );

    if (result) setData(result);
  }, [useDashboards]);

  useEffect(() => {
    if (data) {
      if (data.viewList) {
        setViewBtn(
          <BsFillGridFill
            onClick={async () => await update({ ...data, viewList: false })}
            className="icon__action border__eff cursor__pointer"
            size={40}
          />
        );
      } else {
        setViewBtn(
          <BsList
            className="icon__action border__eff cursor__pointer"
            size={40}
          />
        );
      }
    }
  }, [data]);

  const update = async (dashboard: IDashboard): Promise<IReturnHTTP> => {
    var result: IReturnHTTP = { data: null, message: "", success: false };

    if (data)
      result = await new DashboardsAPI().update(
        new DataFunctions().getId(data),
        dashboard
      );

    return result;
  };

  const saveDashview = async () => {
    try {
      if (!data) {
        throw new GenerateError("Preencha os dados.");
      }

      if (!dataDashview) {
        throw new GenerateError("Preencha os dados.");
      }

      const newDataDashview = dataDashview;
      newDataDashview.data = {
        projects: [],
        boards: [],
        sensors: [],
        sensorTypes: undefined,
        showLabels: false,
        showSmall: false,
        range: {
          index: 1,
          label: "10 MINUTOS",
          unit: "minutes",
          value: 10,
        },
      };

      const result = await update({
        ...data,
        dashboardView: [...data.dashboardView, newDataDashview],
      });

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          variant: "success",
          header: "Concluído",
          message: "DashView criado com sucesso!",
        },
      ]);

      setDataDashview(DEFAULT_DASHVIEW);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      setShowModalDashview(false);
    }
  };

  const removeItem = async (e: IDashboardView) => {
    try {
      if (!data) {
        throw new GenerateError("Preencha os dados.");
      }

      const dataDashview = data.dashboardView.filter(
        (item) =>
          new DataFunctions().getId(item) !== new DataFunctions().getId(e)
      );

      const result = await update({
        ...data,
        dashboardView: dataDashview,
      });

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          variant: "success",
          header: "Concluído",
          message: "DashView excluído com sucesso!",
        },
      ]);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    }
  };

  const updateItem = async (e: IDashboardView) => {
    try {
      if (!data) {
        throw new GenerateError("Preencha os dados.");
      }

      var current_data: IDashboardView[] = [...data.dashboardView];
      const index = data.dashboardView.findIndex(
        (item) =>
          new DataFunctions().getId(item) === new DataFunctions().getId(e)
      );

      if (index > -1) current_data[index] = e;

      const result = await update({
        ...data,
        dashboardView: current_data,
      });

      if (!result.success) {
        throw new GenerateError(result.message);
      }
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    }
  };

  return (
    <div>
      <Modal
        show={showModalDashview}
        backdrop="static"
        keyboard={false}
        onHide={() => setShowModalDashview(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Adicionar DashView</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formGridType">
            <Form.Label>Tipo:</Form.Label>
            <SelectItem
              options={TYPES}
              values={
                dataDashview
                  ? TYPES.filter((item) => dataDashview.type === item.name)
                  : []
              }
              isMulti={false}
              onChange={(e) =>
                setDataDashview({
                  index: 0,
                  type: e[0].name,
                  settings: {
                    backgroundColor: "#ffffff",
                    textColor: "#000000",
                    linesCharColor: [],
                    chartDarkTheme: false,
                  },
                })
              }
              type="tipo"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDataDashview(DEFAULT_DASHVIEW);
              setShowModalDashview(false);
            }}
          >
            Fechar
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              saveDashview();
            }}
          >
            Adicionar
          </Button>
        </Modal.Footer>
      </Modal>

      {!data ? (
        <Loading />
      ) : (
        <Container fluid>
          <h1>Dashboard</h1>

          <div className="header__action">
            {auth.loginDataContext.master && (
              <div key="master_view">
                <Form.Check
                  type="checkbox"
                  id={`master_view`}
                  label="Master View"
                  checked={viewMaster}
                  onChange={() => setViewMaster(!viewMaster)}
                />
              </div>
            )}

            <div className="buttons__action">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Adicionar <strong>DashView</strong>
                  </Tooltip>
                }
              >
                <div
                  onMouseOver={() =>
                    setAddBtn(
                      <IoIosAddCircle
                        size={40}
                        className="icon__action border__eff cursor__pointer"
                      />
                    )
                  }
                  onMouseLeave={() =>
                    setAddBtn(
                      <IoIosAddCircleOutline
                        size={40}
                        className="icon__action border__eff cursor__pointer"
                      />
                    )
                  }
                  onClick={() => setShowModalDashview(true)}
                >
                  {addBtn}
                </div>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Visualizar em{" "}
                    <strong>{data.viewList ? "blocos" : "lista"}</strong>
                  </Tooltip>
                }
              >
                <div
                  onClick={async () =>
                    await update({ ...data, viewList: !data.viewList })
                  }
                >
                  {viewBtn}
                </div>
              </OverlayTrigger>
            </div>
          </div>

          <DashView
            dashview={data.dashboardView}
            viewList={data.viewList}
            viewMaster={viewMaster}
            idUser={idUser}
            onRemoveItem={removeItem}
            onUpdateItem={updateItem}
            useProjects={useProjects}
            useBoards={useBoards}
            useSensors={useSensors}
            useSensorValues={useSensorValues}
          />

          {errors && (
            <div className="mt-5">
              <AlertMessage
                messages={errors}
                setMessages={(e) => setErrors(e)}
              />
            </div>
          )}
        </Container>
      )}
    </div>
  );
};

export default DashboardPage;
