import { useEffect, useState } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { GoDotFill } from "react-icons/go";
import { IBoard } from "../../../../../../../libs/types/boards";
import DataFunctions from "../../../../functions/database";
import StringFunctions from "../../../../functions/strings";
import useBoardsSocket from "../../../../hooks/useBoardsSocket";
import useSensorValuesSocket from "../../../../hooks/useSensorValuesSocket";
import useSensorsSocket from "../../../../hooks/useSensorsSocket";
import moment from "../../../../utils/moment";
import NoData from "../../../noData";
import "../../styles.css";

interface IProps {
  viewMaster: boolean;
  idUser: string;
}

const DashBoard: React.FC<IProps> = ({ viewMaster, idUser }) => {
  const useBoards = useBoardsSocket();
  const useSensors = useSensorsSocket();
  const useValues = useSensorValuesSocket();

  const [boards, setBoards] = useState<IBoard[]>([]);

  useEffect(() => {
    if (viewMaster) {
      setBoards(useBoards);
    } else {
      setBoards(
        useBoards.filter(
          (val) => new DataFunctions().getId(val.project.users) === idUser
        )
      );
    }
  }, [useBoards, viewMaster]);

  const getShields = (items: string[] | undefined) => {
    if (!items || items.length === 0) {
      return "-";
    } else {
      return items.join(", ");
    }
  };

  return (
    <div>
      {boards.length > 0 ? (
        <Table className="mt-3" striped bordered hover size="sm" variant="dark">
          <thead>
            <tr>
              <th className="text-center ">Nome</th>
              <th className="text-center">Projeto</th>
              <th className="text-center">Modelo</th>
              <th className="text-center">Shields</th>
              <th className="text-center">Sensores</th>
              <th className="text-center">Dados Coletados</th>
              <th className="text-center">Última Atualização</th>
            </tr>
          </thead>
          <tbody>
            {boards.map((item) => {
              return (
                <tr key={new DataFunctions().getId(item)}>
                  <td className="text-center col-md-1 align-middle">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Placa{" "}
                          <strong>{item.active ? "Ativa" : "Inativa"}</strong>
                        </Tooltip>
                      }
                    >
                      <div>
                        {item.name.toUpperCase()}{" "}
                        <GoDotFill
                          size={20}
                          color={item.active ? "green" : "red"}
                        />
                      </div>
                    </OverlayTrigger>
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {new StringFunctions(item.project.name)
                      .firstLetters()
                      .toUpperCase()}
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {item.model.toUpperCase()}
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {getShields(item.shields)}
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {
                      useSensors.filter(
                        (val) =>
                          new DataFunctions().getId(val.board) ===
                          new DataFunctions().getId(item)
                      ).length
                    }
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {
                      useValues.filter(
                        (val) =>
                          new DataFunctions().getId(val.sensor.board) ===
                          new DataFunctions().getId(item)
                      ).length
                    }
                  </td>

                  <td className="text-center col-md-1 align-middle">
                    {moment(item.modifiedAt).format("DD/MM/YYYY [às] HH:mm:ss")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <NoData size={50} />
      )}
    </div>
  );
};

export default DashBoard;
