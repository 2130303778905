import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { MdDelete, MdDeleteOutline } from "react-icons/md";
import { RiPencilFill, RiPencilLine } from "react-icons/ri";
import { v4 as uuidv4 } from "uuid";
import { IAlertMessage } from "../../../../../../../../libs/types/messages";
import { IReturnHTTP } from "../../../../../../../../libs/types/returns";
import { ISettingsSensorReturns } from "../../../../../../../../libs/types/settings";
import AlertMessage from "../../../../../components/alertMessage";
import Loading from "../../../../../components/loading";
import ModalConfirm from "../../../../../components/modalConfirm";
import DataFunctions from "../../../../../functions/database";
import SortFunctions from "../../../../../functions/sorts";
import SettingsSensorsReturnsAPI from "../../../../../services/api/settings/sensors/returns";
import SettingsSensorsTypesAPI from "../../../../../services/api/settings/sensors/types";
import GenerateError from "../../../../../validations/errors";

const DEFAULT_RETURN: ISettingsSensorReturns = {
  fullUnitMeasure: "",
  name: "",
  createdAt: new Date(),
  shortUnitMeasure: "",
};

const SettingsSensorsReturnsPage = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [errors, setErrors] = useState<IAlertMessage[]>([]);
  const [data, setData] = useState<ISettingsSensorReturns[]>();
  const [selectedData, setSelectedData] =
    useState<ISettingsSensorReturns>(DEFAULT_RETURN);

  useEffect(() => {
    setErrors([]);
    loadData();
  }, []);

  const loadData = async () => {
    const result = await new SettingsSensorsReturnsAPI().list();

    if (result.success) {
      setData(result.data);
      setSelectedData(DEFAULT_RETURN);
      setShowModal(false);
      setLoading(false);
      setShowModalDelete(false);
    }
  };

  const save = async () => {
    setLoading(true);

    try {
      if (!selectedData) {
        throw new GenerateError("Preencha os dados.");
      }

      var result: IReturnHTTP = { data: null, message: "", success: false };

      const id = new DataFunctions().getId(selectedData);

      if (id === "undefined") {
        result = await new SettingsSensorsReturnsAPI().create([selectedData]);
      } else {
        result = await new SettingsSensorsReturnsAPI().update(id, selectedData);
      }

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          variant: "success",
          header: "Concluído",
          message: !selectedData.id
            ? "Sensor criado com sucesso!"
            : "Aletarações realizadas com sucesso!",
        },
      ]);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      loadData();
    }
  };

  const del = async () => {
    setLoading(true);

    try {
      const result = await new SettingsSensorsTypesAPI().delete(
        new DataFunctions().getId(selectedData)
      );

      if (!result.success) {
        throw new GenerateError(result.message);
      }
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      loadData();
    }
  };

  return (
    <div>
      <Modal
        size="lg"
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={() => setShowModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedData.id
              ? `Alterar Retorno de Sensor - ${selectedData.name}`
              : "Adicionar Retorno de Sensor"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Nome + Modelo */}
            <Row>
              <Col className="mb-3">
                <Form.Group controlId="formGridNameMeasure">
                  <Form.Label>Nome da Unidade de Medida:</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedData.name}
                    onChange={(e) => {
                      setSelectedData({
                        ...selectedData,
                        name: e.target.value.toUpperCase(),
                      });
                    }}
                  />
                </Form.Group>
              </Col>

              <Col className="mb-3">
                <Form.Group controlId="formGridUnitFullMeasure">
                  <Form.Label>Unidade de Medida (Completo):</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedData.fullUnitMeasure}
                    onChange={(e) => {
                      setSelectedData({
                        ...selectedData,
                        fullUnitMeasure: e.target.value.toUpperCase(),
                      });
                    }}
                  />
                </Form.Group>
              </Col>

              <Col className="mb-3">
                <Form.Group controlId="formGridUnitMeasure">
                  <Form.Label>Unidade de Medida (Abreviado):</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedData.shortUnitMeasure}
                    onChange={(e) => {
                      setSelectedData({
                        ...selectedData,
                        shortUnitMeasure: e.target.value.trim(),
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Fechar
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              save();
            }}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalConfirm
        result={(e) => {
          if (!e.result) {
            setShowModalDelete(false);
          } else {
            del();
          }
        }}
        showModal={showModalDelete}
        type="Delete"
        continueQuestion={`do sensor ${selectedData.name}`}
        centered={true}
        size="lg"
      />

      {!data ? (
        errors.length > 0 ? (
          <div className="center__box">
            <AlertMessage
              messages={errors}
              setMessages={(e) => setErrors(e)}
              dismissible={false}
            />
          </div>
        ) : (
          <Loading />
        )
      ) : loading ? (
        <Loading />
      ) : (
        <Container fluid>
          <div className="mx-4 mt-5">
            <Row>
              <Col className="text-start">
                <h4>Retornos de Sensores</h4>
              </Col>

              <Col className="text-end">
                <Button
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Adicionar Tipo de Sensor
                </Button>
              </Col>
            </Row>

            <Table
              className="mt-3"
              responsive
              striped
              bordered
              hover
              size="sm"
              variant="dark"
            >
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center">Nome</th>
                  <th className="text-center">Unidade de Medida</th>
                  <th className="text-center">Unidade de Medida Abreviada</th>
                  <th className="text-center">Ação</th>
                </tr>
              </thead>
              <tbody>
                {data
                  .sort((a, b) =>
                    new SortFunctions(a.name, b.name).sortStrAsc()
                  )
                  .map((item, index) => {
                    return (
                      <tr key={`tb-data-${index}`}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{item.name}</td>
                        <td className="text-center">{item.fullUnitMeasure}</td>
                        <td className="text-center">{item.shortUnitMeasure}</td>

                        <td className="text-center col-md-2">
                          <div className="icons__container ">
                            <div className="icons cursor__pointer">
                              <div className="icons__out">
                                <RiPencilLine size={25} color="white" />
                              </div>
                              <div
                                className="icons__fill"
                                onClick={() => {
                                  setSelectedData(item);
                                  setShowModal(true);
                                }}
                              >
                                <RiPencilFill size={25} color="white" />
                              </div>
                            </div>
                            <div className="icons cursor__pointer">
                              <div className="icons__out">
                                <MdDeleteOutline size={25} color="white" />
                              </div>
                              <div
                                className="icons__fill"
                                onClick={() => {
                                  setSelectedData(item);
                                  setShowModalDelete(true);
                                }}
                              >
                                <MdDelete size={25} color="white" />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          {errors && (
            <AlertMessage messages={errors} setMessages={(e) => setErrors(e)} />
          )}
        </Container>
      )}
    </div>
  );
};

export default SettingsSensorsReturnsPage;
