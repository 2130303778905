import API from "../../../";
import { IReturnHTTP } from "../../../../../../../../libs/types/returns";
import { ISettingsSensorReturns } from "../../../../../../../../libs/types/settings";

export default class SettingsSensorsReturnsAPI extends API {
  private path: string = "settings/sensors/returns";

  create = async (data: ISettingsSensorReturns[]): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}`;

    try {
      this.result = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
        body: JSON.stringify(data),
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  list = async (): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  find = async (name?: string, descr?: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/find?name=${name}&descr=${descr}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  get = async (id: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/${id}`;

    try {
      this.result = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  update = async (
    id: string,
    data: ISettingsSensorReturns
  ): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/${id}`;

    try {
      this.result = await fetch(url, {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
        body: JSON.stringify(data),
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };

  delete = async (id: string): Promise<IReturnHTTP> => {
    const url = `${this.base_url}/${this.path}/${id}`;

    try {
      this.result = await fetch(url, {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      })
        .then(
          async (res) => {
            return await res.json();
          },
          (fail) => {
            return fail.response.data;
          }
        )
        .catch((error) => {
          return error.response.data;
        });

      return this.result;
    } catch (error: any) {
      this.result.message = error.message;
      return this.result;
    }
  };
}
