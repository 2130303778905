import { useState } from "react";
import { AiFillCloseCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { BsArrowRightCircle, BsArrowRightCircleFill } from "react-icons/bs";
import "./styles.css";

interface IProps {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const CollapsibleButton: React.FC<IProps> = ({ collapsed, setCollapsed }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="icon__div">
      <div
        className="cursor__pointer"
        onMouseOverCapture={(e) => {
          setIsHovered(true);
        }}
        onMouseOutCapture={(e) => {
          setIsHovered(false);
        }}
        onClick={() => setCollapsed(!collapsed)}
      >
        {!collapsed ? (
          isHovered ? (
            <AiFillCloseCircle size={24} />
          ) : (
            <AiOutlineCloseCircle size={24} />
          )
        ) : isHovered ? (
          <BsArrowRightCircleFill size={24} />
        ) : (
          <BsArrowRightCircle size={24} />
        )}
      </div>
    </div>
  );
};

export default CollapsibleButton;
