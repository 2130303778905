import { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { IAlertMessage } from "../../../../../../libs/types/messages";
import { ILogin, ILoginRequest } from "../../../../../../libs/types/users";
import AlertMessage from "../../../components/alertMessage";
import Loading from "../../../components/loading";
import { DEFAULT_VALUE_LOGIN, useLoginContext } from "../../../context";
import UsersAPI from "../../../services/api/users";
import Storage from "../../../storage";
import GenerateError from "../../../validations/errors";
import GetParamsURL from "../../../validations/params";

const LoginPage = () => {
  const navigate = useNavigate();

  const params = useLocation().search;
  const auth = useLoginContext();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IAlertMessage[]>([]);
  const [user, setUser] = useState<ILoginRequest>({
    user: "",
    password: "",
  });

  const [email, setEmail] = useState("");

  type ModalType = "" | "resend" | "forget";

  const [showModal, setShowModal] = useState<{
    show: boolean;
    type: ModalType;
  }>({ show: false, type: "" });

  useEffect(() => {
    const storage = new Storage().get("user");
    if (storage !== undefined && storage !== null) {
      try {
        const userAuth = JSON.parse(storage);
        auth.setLoginDataContext(userAuth);

        navigate("/auth");
      } catch (error) {
        auth.setLoginDataContext(DEFAULT_VALUE_LOGIN.loginDataContext);
        new Storage().clear();
      }
    }

    const alert = new GetParamsURL(params).getParamsMessage();
    if (alert) {
      setErrors([alert]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async () => {
    setLoading(true);

    try {
      const result = await new UsersAPI().login(user.user, user.password);

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      const userLogin: ILogin = result.data;
      new Storage().set("user", userLogin);
      auth.setLoginDataContext(userLogin);

      navigate("/auth");
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      } else {
        alert(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const forgot = async () => {
    setLoading(true);

    try {
      const result = await new UsersAPI().recoverPassword(email);

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      setErrors([
        {
          key: uuidv4(),
          header: "Link reenviado",
          message: `Enviamos um e-mail para ${email} com o link de recuperação de sua senha.`,
          variant: "success",
        },
      ]);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      } else {
        alert(error.message);
      }
    } finally {
      setEmail("");
      setLoading(false);
    }
  };

  const resend = async () => {
    setLoading(true);

    try {
      const result = await new UsersAPI().recoverPassword(email);

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      setErrors([
        {
          key: uuidv4(),
          header: "Link reenviado",
          message: `Reenviamos um e-mail para ${email} com o link de ativação de sua conta.`,
          variant: "success",
        },
      ]);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      } else {
        alert(error.message);
      }
    } finally {
      setEmail("");
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        show={showModal.show}
        centered
        backdrop="static"
        keyboard={false}
        onHide={() => setShowModal({ show: false, type: "" })}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {showModal.type === "forget"
              ? "Recuperação de Senha"
              : "Ativação de Conta"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Informe seu e-mail de cadastro:</Form.Label>
          <Form.Control
            type="email"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal({ show: false, type: "" });
              setEmail("");
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (email.trim() === "") {
                setShowModal({ show: false, type: "" });
                setErrors([
                  {
                    key: uuidv4(),
                    header: `Erro ao enviar o link de ${
                      showModal.type === "forget"
                        ? "recuperação de senha"
                        : "ativação de conta"
                    }`,
                    message: "Favor informar o seu e-mail.",
                    variant: "danger",
                  },
                ]);
                return;
              }

              setLoading(true);
              if (showModal.type === "forget") {
                forgot();
              } else {
                resend();
              }

              setShowModal({ show: false, type: "" });
            }}
          >
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>

      <h1 className="text-center">Área de Login</h1>

      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="center__box">
            <Form className="p-4 border border-2 rounded border-white">
              <Form.Group className="mb-2" controlId="formBasicUser">
                <Form.Label>Usuário</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Usuário"
                  value={user.user}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      user: e.target.value,
                    })
                  }
                />
                <Form.Text className="text-muted">
                  Nunca compartilhe seus dados de acesso.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-2" controlId="formBasicPassword">
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Senha"
                  value={user.password}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      password: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <Row className="ms-2 me-2 mt-4">
                <Button
                  variant="outline-dark"
                  className="mb-2"
                  onClick={(e) => {
                    login();
                    e.preventDefault();
                  }}
                >
                  Entrar
                </Button>
                <Button
                  variant="outline-dark"
                  className="mb-2"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/register-user");
                  }}
                >
                  Cadastrar
                </Button>
                <Button
                  variant="outline-dark"
                  className="mb-2"
                  onClick={() => {
                    setErrors([]);
                    setShowModal({ show: true, type: "forget" });
                  }}
                >
                  Recuperar Senha
                </Button>
                <Button
                  variant="outline-dark"
                  className="mb-2"
                  onClick={() => {
                    setErrors([]);
                    setShowModal({ show: true, type: "resend" });
                  }}
                >
                  Reenviar Ativação de Conta
                </Button>
              </Row>
            </Form>
          </div>

          {errors && (
            <AlertMessage messages={errors} setMessages={(e) => setErrors(e)} />
          )}
        </>
      )}
    </div>
  );
};

export default LoginPage;
