import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormCheck,
  FormLabel,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { IoIosCloseCircle, IoIosCloseCircleOutline } from "react-icons/io";
import { IoColorPaletteOutline, IoColorPaletteSharp } from "react-icons/io5";
import { MdDeleteForever } from "react-icons/md";
import { RiDragMove2Fill } from "react-icons/ri";
import { RxMove } from "react-icons/rx";
import { IBoard } from "../../../../../../libs/types/boards";
import { IDashboardView } from "../../../../../../libs/types/dashboards";
import { IProject } from "../../../../../../libs/types/projects";
import { ISensor, ISensorValues } from "../../../../../../libs/types/sensors";
import StringFunctions from "../../../functions/strings";
import DashBoard from "../dashtypes/boards";
import DashProject from "../dashtypes/projects";
import DashSensor from "../dashtypes/sensors";
import DashUser from "../dashtypes/users";
import DashValues from "../dashtypes/values";
import "../styles.css";

const ICON_SIZE = 30;

interface IProps {
  item: IDashboardView;
  viewList: boolean;
  viewMaster: boolean;
  idUser: string;
  onRemoveItem: (e: IDashboardView) => void;
  onUpdateItem: (e: IDashboardView) => void;
  useProjects: IProject[];
  useBoards: IBoard[];
  useSensors: ISensor[];
  useSensorValues: ISensorValues[];
}

const DashItem: React.FC<IProps> = ({
  item,
  viewList,
  viewMaster,
  idUser,
  onRemoveItem,
  onUpdateItem,
  useProjects,
  useBoards,
  useSensors,
  useSensorValues,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [settings, setSettings] = useState(item.settings);
  const [lineColor, setLineColor] = useState("#FFDD00");

  const [moveBtn, setMoveBtn] = useState(<RiDragMove2Fill size={ICON_SIZE} />);
  const [delBtn, setDelBtn] = useState(<IoIosCloseCircle size={ICON_SIZE} />);
  const [colorBtn, setColorBtn] = useState(
    <IoColorPaletteSharp size={ICON_SIZE} />
  );

  return (
    <div
      className="view__container"
      style={{
        backgroundColor: item.settings.backgroundColor,
        color: item.settings.textColor,
      }}
    >
      <Modal
        show={showModal}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={() => setShowModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Definição de Cores</Modal.Title>
        </Modal.Header>

        <Modal.Body className="mx-3">
          <Form>
            <Row className="mt-3">
              <Col lg={2}>
                <Form.Label>Cor de Fundo</Form.Label>
                <Form.Control
                  type="color"
                  value={settings ? settings.backgroundColor : "#000000"}
                  onChange={(e) => {
                    if (settings)
                      setSettings({
                        ...settings,
                        backgroundColor: e.target.value.toLowerCase().trim(),
                      });
                  }}
                />
              </Col>

              <Col>
                <Form.Label>Código HEX</Form.Label>
                <Form.Control
                  type="text"
                  value={settings ? settings.backgroundColor : "#000000"}
                  disabled
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg={2}>
                <Form.Label>Cor do Texto</Form.Label>
                <Form.Control
                  type="color"
                  value={settings ? settings.textColor : "#ffffff"}
                  onChange={(e) => {
                    if (settings)
                      setSettings({
                        ...settings,
                        textColor: e.target.value.toLowerCase().trim(),
                      });
                  }}
                />
              </Col>

              <Col>
                <Form.Label>Código HEX</Form.Label>
                <Form.Control
                  type="text"
                  value={settings ? settings.textColor : "#ffffff"}
                  disabled
                />
              </Col>
            </Row>

            <Row className="mt-5">
              <h4>Cores de linhas - Gráfico</h4>

              <Row className="ms-1 my-3">
                <FormLabel>Tema do Gráfico</FormLabel>
                <FormCheck
                  type="switch"
                  checked={settings.chartDarkTheme}
                  label={settings.chartDarkTheme ? "Escuro" : "Claro"}
                  onChange={() =>
                    setSettings({
                      ...settings,
                      chartDarkTheme: !settings.chartDarkTheme,
                    })
                  }
                />
              </Row>

              <Row>
                <Col lg={2}>
                  <Form.Label>Cor de Linha</Form.Label>
                  <Form.Control
                    type="color"
                    value={lineColor}
                    onChange={(e) => {
                      setLineColor(e.target.value.toLowerCase().trim());
                    }}
                  />
                </Col>

                <Col>
                  <Form.Label>Código HEX</Form.Label>
                  <Form.Control type="text" value={lineColor} disabled />
                </Col>

                <Col>
                  <br />
                  <div>
                    <Button
                      variant="primary"
                      onClick={() => {
                        if (item.data && settings) {
                          if (settings.linesCharColor) {
                            setSettings({
                              ...settings,
                              linesCharColor: [
                                ...settings.linesCharColor,
                                lineColor,
                              ],
                            });
                          } else {
                            setSettings({
                              ...settings,
                              linesCharColor: [lineColor],
                            });
                          }

                          setLineColor("#FFDD00");
                        }
                      }}
                    >
                      Adicionar
                    </Button>
                  </div>
                </Col>
              </Row>

              {settings &&
                settings.linesCharColor &&
                settings.linesCharColor.length > 0 && (
                  <Row>
                    <Table
                      className="mt-3"
                      striped
                      bordered
                      hover
                      size="sm"
                      variant="dark"
                    >
                      <thead>
                        <tr className="text-center align-middle">
                          <th>#</th>
                          <th>Cor</th>
                          <th>Remover</th>
                        </tr>
                      </thead>
                      <tbody>
                        {settings.linesCharColor.map((item, index) => {
                          return (
                            <tr
                              key={`color_${index.toString()}`}
                              className="text-center align-middle"
                            >
                              <td>{index + 1}</td>

                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: item,
                                      width: "20px",
                                      height: "20px",
                                      marginRight: "5px",
                                    }}
                                  ></div>
                                  {item.toUpperCase()}
                                </div>
                              </td>

                              <td>
                                <MdDeleteForever
                                  className="cursor__pointer"
                                  size={25}
                                  onClick={() => {
                                    if (settings && settings.linesCharColor) {
                                      var items = settings.linesCharColor;
                                      items = items.filter(
                                        (it) =>
                                          it.toLocaleLowerCase() !==
                                          item.toLocaleLowerCase()
                                      );

                                      setSettings({
                                        ...settings,
                                        linesCharColor: items,
                                      });
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Row>
                )}
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setSettings(item.settings);
              setShowModal(false);
            }}
          >
            Fechar
          </Button>
          <Button
            variant="success"
            onClick={() => {
              onUpdateItem({
                ...item,
                settings,
              });
              setShowModal(false);
            }}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="header__action">
        <h4 className="ms-2">{new StringFunctions(item.type).cammelCase()}</h4>

        <div className="buttons__action">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Cores <strong>DashView</strong>
              </Tooltip>
            }
          >
            <div
              className="cursor__pointer"
              onMouseOver={() =>
                setColorBtn(<IoColorPaletteOutline size={ICON_SIZE} />)
              }
              onMouseLeave={() =>
                setColorBtn(<IoColorPaletteSharp size={ICON_SIZE} />)
              }
              onClick={() => {
                setShowModal(true);
              }}
            >
              {colorBtn}
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Mover <strong>DashView</strong>
              </Tooltip>
            }
          >
            <div
              className="cursor__move"
              onMouseOver={() => setMoveBtn(<RxMove size={ICON_SIZE} />)}
              onMouseLeave={() =>
                setMoveBtn(<RiDragMove2Fill size={ICON_SIZE} />)
              }
              onClick={() => {}}
            >
              {moveBtn}
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Exlcuir <strong>DashView</strong>
              </Tooltip>
            }
          >
            <div
              className="cursor__pointer"
              onMouseOver={() =>
                setDelBtn(<IoIosCloseCircleOutline size={ICON_SIZE} />)
              }
              onMouseLeave={() =>
                setDelBtn(<IoIosCloseCircle size={ICON_SIZE} />)
              }
              onClick={() => {
                onRemoveItem(item);
              }}
            >
              {delBtn}
            </div>
          </OverlayTrigger>
        </div>
      </div>

      <div className="dash__item">
        {item.type.toLocaleLowerCase() === "projetos" ? (
          <DashProject idUser={idUser} viewMaster={viewMaster} />
        ) : item.type.toLocaleLowerCase() === "placas" ? (
          <DashBoard idUser={idUser} viewMaster={viewMaster} />
        ) : item.type.toLocaleLowerCase() === "sensores" ? (
          <DashSensor idUser={idUser} viewMaster={viewMaster} />
        ) : item.type.toLocaleLowerCase() === "valores" ? (
          <DashValues
            idUser={idUser}
            viewMaster={viewMaster}
            item={item}
            onUpdateItem={onUpdateItem}
            useProjects={useProjects}
            useBoards={useBoards}
            useSensors={useSensors}
            useSensorValues={useSensorValues}
          />
        ) : (
          <DashUser idUser={idUser} viewMaster={viewMaster} />
        )}
      </div>
    </div>
  );
};

export default DashItem;
