import React from "react";
import { Button, Modal } from "react-bootstrap";
import { IResult } from "../../../../../libs/types/returns";

type ModalType = "Delete" | "Update" | "Add";
type ModalSyze = "sm" | "lg" | "xl";
interface IProps {
  showModal: boolean;
  result: (e: IResult) => void;
  id?: string;
  centered?: boolean;
  type: ModalType;
  continueQuestion?: string;
  question?: string;
  secondInformation?: string;
  size?: ModalSyze;
}

const ModalConfirm: React.FC<IProps> = ({
  showModal,
  result,
  id,
  centered,
  type,
  question,
  size,
  continueQuestion,
  secondInformation,
}) => {
  return (
    <Modal
      size={size}
      show={showModal}
      backdrop="static"
      keyboard={false}
      onHide={() =>
        result({
          key: id,
          result: false,
        })
      }
      centered={centered}
    >
      <Modal.Header closeButton>
        <Modal.Title>Atenção!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {type === "Add"
          ? `Tem certeza que deseja prosseguir com a adição ${continueQuestion}?`
          : type === "Delete"
          ? `Tem certeza que deseja prosseguir com a exclusão ${continueQuestion}?`
          : type === "Update"
          ? `Tem certeza que deseja prosseguir com a alteração ${continueQuestion}?`
          : question}
        <div>
          {secondInformation && (
            <>
              <br />
              <b>{secondInformation}</b>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            result({
              key: id,
              result: false,
            });
          }}
        >
          Cancelar
        </Button>

        <Button
          variant="danger"
          onClick={() => {
            result({
              key: id,
              result: true,
            });
          }}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirm;
