import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { IBoard } from "../../../../../../libs/types/boards";
import { IAlertMessage } from "../../../../../../libs/types/messages";
import { IReturnHTTP } from "../../../../../../libs/types/returns";
import AlertMessage from "../../../components/alertMessage";
import TableItem from "../../../components/dataView/table";
import Loading from "../../../components/loading";
import ModalConfirm from "../../../components/modalConfirm";
import { useLoginContext } from "../../../context";
import DataFunctions from "../../../functions/database";
import StringFunctions from "../../../functions/strings";
import useBoardsSocket from "../../../hooks/useBoardsSocket";
import BoardsAPI from "../../../services/api/boards";
import ProjectsAPI from "../../../services/api/projects";
import moment from "../../../utils/moment";
import GenerateError from "../../../validations/errors";

const BoardPage = () => {
  const navigate = useNavigate();
  let auth = useLoginContext();
  let { idBoard, idProject } = useParams();

  const boards = useBoardsSocket();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IAlertMessage[]>([]);
  const [data, setData] = useState<IBoard>();
  const [shield, setShield] = useState<{
    key: string;
    new: boolean;
    name: string;
  }>({
    key: "",
    name: "",
    new: true,
  });

  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  useEffect(() => {
    setErrors([]);

    const loadData = async () => {
      const result = await new ProjectsAPI().get(idProject ? idProject : "");

      if (result.success) {
        if (idBoard === "|new|") {
          setData({
            project: result.data,
            name: "",
            active: true,
            macAddress: "",
            model: "",
            ip: "",
            createdAt: new Date(),
          });
        } else {
          if (idBoard) {
            const result = await new BoardsAPI().get(idBoard);
            if (result.success) setData(result.data);
          }
        }
      }
    };

    loadData();
  }, [idBoard]);

  const save = async () => {
    setLoading(true);

    try {
      if (!data) {
        throw new GenerateError("Preencha os dados.");
      }

      var result: IReturnHTTP = { data: null, message: "", success: false };

      if (idBoard === "|new|") {
        result = await new BoardsAPI().create([data]);
      } else {
        result = await new BoardsAPI().update(idBoard ? idBoard : "", data);
      }

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          variant: "success",
          header: "Concluído",
          message:
            idBoard === "|new|"
              ? "Placa criada com sucesso!"
              : "Aletarações realizadas com sucesso!",
        },
      ]);

      setData({
        project: data.project,
        name: "",
        active: true,
        macAddress: "",
        model: "",
        ip: "",
        createdAt: new Date(),
      });
      navigate(`/auth/project/${idProject}/board/|new|`);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      setLoading(false);
    }
  };

  const del = async () => {
    setLoading(true);

    try {
      const result = await new BoardsAPI().delete(idBoard ? idBoard : "");

      if (!result.success) {
        throw new GenerateError(result.message);
      }

      navigate(`/auth`);
    } catch (error: any) {
      if (error instanceof GenerateError) {
        setErrors([error.returnAlert()]);
      }
    } finally {
      setLoading(false);
    }
  };

  const updateShields = () => {
    if (data) {
      if (shield.new) {
        if (!data.shields?.find((s) => s === shield.name)) {
          setData({
            ...data,
            shields: data.shields
              ? [...data.shields, shield.name]
              : [shield.name],
          });
        } else {
          setErrors([
            {
              key: uuidv4().toString(),
              header: "Shield já existente",
              message:
                "O shield que você está tentando adicionar já existe para esta placa.",
              variant: "danger",
            },
          ]);
        }
      } else {
        if (data.shields) {
          var current_shield = data.shields;
          current_shield[Number(shield.key)] = shield.name;
          setData({
            ...data,
            shields: current_shield,
          });
        }
      }
    }
    setShowModal(false);
  };

  return (
    <div>
      {showModalDelete && (
        <ModalConfirm
          result={(e) => {
            if (!e.result) {
              setShowModalDelete(false);
            } else {
              del();
            }
          }}
          showModal={showModalDelete}
          type="Delete"
          continueQuestion="desta placa"
          secondInformation="Ao excluir esta placa, demais dados como sensores e valores também serão excluídos."
          centered={true}
          size="lg"
        />
      )}

      {data && (
        <Modal
          size="lg"
          show={showModal}
          backdrop="static"
          keyboard={false}
          onHide={() => setShowModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {shield.new
                ? "Adicione uma Shield para esta placa"
                : "Altere a Shield desta placa"}
              :
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="mb-3">
                <Form.Group controlId="formGridShield">
                  <Form.Label>Nome da Shield:</Form.Label>
                  <Form.Control
                    type="text"
                    value={shield.name}
                    onChange={(e) => {
                      setShield({
                        ...shield,
                        name: e.target.value.toUpperCase(),
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                updateShields();
              }}
            >
              {shield.new ? "Adicionar" : "Alterar"}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <h1>
        {idBoard === "|new|"
          ? "Nova Placa"
          : `Placa${
              !data
                ? ""
                : ` - ${new StringFunctions(data.name).capitalLetter()}`
            }`}
      </h1>

      {idBoard !== "|new|" && data && (
        <div className="date">
          <span>
            <b>Criação:</b>{" "}
            {moment(data.createdAt).format("DD/MM/YYYY [às] HH:mm:ss")}
          </span>
          {data.modifiedAt && (
            <>
              <br />
              <span>
                <b>Modificação:</b>{" "}
                {moment(data.modifiedAt).format("DD/MM/YYYY [às] HH:mm:ss")}
              </span>
            </>
          )}
        </div>
      )}

      {!data ? (
        errors.length > 0 ? (
          <div className="center__box">
            <AlertMessage
              messages={errors}
              setMessages={(e) => setErrors(e)}
              dismissible={false}
            />
          </div>
        ) : (
          <Loading />
        )
      ) : loading ? (
        <Loading />
      ) : (
        <Container fluid>
          <div className="mx-4 mt-5">
            <Form>
              {/* Ativo + Projeto + ID */}
              <Row>
                <Col className="mb-3" lg={2} sm={6}>
                  <Form.Group controlId="formGridActive">
                    <Form.Label>Ativo:</Form.Label>
                    <Form.Check
                      label={data.active ? "Ativo" : "Inativo"}
                      type="switch"
                      checked={data.active}
                      disabled={!auth.loginDataContext.master}
                      onChange={() =>
                        setData({ ...data, active: !data.active })
                      }
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={idBoard !== "|new|" ? 5 : 10}>
                  <Form.Group controlId="formGridIdProject">
                    <Form.Label>Projeto ID:</Form.Label>
                    <Form.Control type="text" value={idProject} disabled />
                  </Form.Group>
                </Col>

                {idBoard !== "|new|" && (
                  <Col className="mb-3" lg={5}>
                    <Form.Group controlId="formGridId">
                      <Form.Label>ID:</Form.Label>
                      <Form.Control
                        type="text"
                        value={new DataFunctions().getId(data)}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>

              {/* Nome + Modelo */}
              <Row>
                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridName">
                    <Form.Label>Nome da Placa:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.name}
                      onChange={(e) => {
                        setData({
                          ...data,
                          name: e.target.value.toUpperCase(),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={6}>
                  <Form.Group controlId="formGridModel">
                    <Form.Label>Modelo:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.model}
                      onChange={(e) => {
                        setData({ ...data, model: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Token + MacAdress */}
              <Row>
                {idBoard !== "|new|" && (
                  <Col className="mb-3" lg={4}>
                    <Form.Group controlId="formGridToken">
                      <Form.Label>Token:</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={data.token}
                        onChange={(e) => {
                          setData({
                            ...data,
                            token: e.currentTarget.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                )}

                <Col className="mb-3" lg={idBoard !== "|new|" ? 4 : 6}>
                  <Form.Group controlId="formGridMacAddress">
                    <Form.Label>MacAddress:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.macAddress}
                      onChange={(e) => {
                        setData({
                          ...data,
                          macAddress: e.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3" lg={idBoard !== "|new|" ? 4 : 6}>
                  <Form.Group controlId="formGridIP">
                    <Form.Label>IP:</Form.Label>
                    <Form.Control
                      type="text"
                      value={data.ip}
                      onChange={(e) => {
                        setData({
                          ...data,
                          ip: e.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Shields */}
              <Row className="mt-3 mb-5">
                <TableItem
                  typeSort="String"
                  bordered={true}
                  hover={true}
                  responsive={true}
                  striped={true}
                  dataView={
                    data.shields
                      ? data.shields.map((item, index) => {
                          return { key: index.toString(), name: item };
                        })
                      : []
                  }
                  onEdit={(item) => {
                    setShield({ new: false, name: item.name, key: item.key });
                    setShowModal(true);
                  }}
                  onRemove={(item) => {
                    if (data.shields) {
                      var current_shield = data.shields;
                      current_shield = data.shields.filter(
                        (s) => s !== item.name
                      );
                      setData({ ...data, shields: current_shield });
                    }
                  }}
                  title="Shields"
                  hiddenColumnOne={true}
                  edit={true}
                  remove={true}
                  onAdd={() => {
                    setShield({ name: "", new: true, key: "" });
                    setShowModal(true);
                  }}
                  showAdd={auth.loginDataContext.master}
                />
              </Row>

              {auth.loginDataContext.master && (
                <Row className="mb-3">
                  {idBoard !== "|new|" && (
                    <Col className="text-start">
                      <Button
                        variant="danger"
                        onClick={(e) => {
                          setShowModalDelete(true);
                        }}
                      >
                        Excluir
                      </Button>
                    </Col>
                  )}
                  <Col className="text-end">
                    <Button
                      variant="success"
                      onClick={(e) => {
                        save();
                      }}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </div>

          {errors && (
            <AlertMessage messages={errors} setMessages={(e) => setErrors(e)} />
          )}
        </Container>
      )}
    </div>
  );
};

export default BoardPage;
